import {Component, Input, OnInit} from '@angular/core';

export interface OptionFields {
    star: boolean;
    chat: boolean;
    pdf: boolean;
    share: boolean;
}

@Component({
    selector: 'app-card-options',
    templateUrl: './card-options.component.html',
    styleUrls: ['./card-options.component.scss'],
})
export class CardOptionsComponent implements OnInit {
    @Input('fields') fields: OptionFields | undefined;

    constructor() {
    }

    ngOnInit() {
    }

}
