import { Component, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NotificationModel } from '@core/_interfaces/notification.model';

@Component({
    selector: 'app-notification-list-item',
    templateUrl: './notification-list-item.component.html',
    styleUrls: ['./notification-list-item.component.scss'],
    providers: [],
})
export class NotificationListItemComponent {
    @Input() item: { date: string; events: NotificationModel[] } | null = null;
    public today = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    constructor(private datePipe: DatePipe) {}
}
