import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegistrationPopupSecondComponent } from '../registration-popup-second/registration-popup-second.component';
import { ApiListService, AuthenticationService, passwordValidators, phoneMask, phoneValidators } from '@core/_services';
import { AgreementPopupComponent } from './agreement-popup/agreement-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { RegistrationCredential } from '@core/_interfaces';
import { AppConfig } from '@core/_services';
import { filterNumbersFromString } from '@core/_utils/numbers-from-string';
import { PlatformService } from '@core/_services/platform.service';

@Component({
    selector: 'app-registration-popup-first',
    templateUrl: './registration-popup-first.component.html',
    styleUrls: ['./registration-popup-first.component.scss'],
    providers: [],
    // InAppBrowser
})
export class RegistrationPopupFirstComponent implements OnInit {
    form: FormGroup | undefined;
    submitted = false;
    passwordsAreNotTheSame = false;
    phoneMask = phoneMask;
    redirectAfterLogin = false;
    error: string | undefined;
    visiblePassword = false;

    constructor(
        private platform: PlatformService,
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<RegistrationPopupFirstComponent>,
        public dialog: MatDialog,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        private apiListService: ApiListService, // private iab: InAppBrowser
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            login: ['', phoneValidators],
            password: ['', passwordValidators],
            passwordConfirm: ['', Validators.required],
            agree: [false, Validators.pattern('true')],
        });
        this.form.valueChanges.subscribe(val => {
            this.passwordsAreNotTheSame = val.password !== val.passwordConfirm;
        });
    }

    submit() {
        this.submitted = true;
        if (this.form?.valid && !this.passwordsAreNotTheSame) {
            const login = filterNumbersFromString(this.form.get('login')?.value);
            const password = this.form.get('password')?.value;
            const passwordConfirm = this.form.get('passwordConfirm')?.value;
            this.apiListService.isLoginAvailable(login).subscribe(
                val => {
                    if (!val) {
                        this.error = this.translate.instant('errors.phone-is-already-in-use');
                        return;
                    }
                    this.dialog
                        .open(RegistrationPopupSecondComponent, {
                            panelClass: 'no-padding-popup',
                            backdropClass: 'blur-bg',
                            width: '720px',
                            maxWidth: 'calc(100% - 10px)',
                            data: { login, password, passwordConfirm } as RegistrationCredential,
                            disableClose: true,
                        })
                        .afterClosed()
                        .subscribe(data => {
                            if (data?.result) {
                                this.dialogRef.close(data);
                            }
                        });
                },
                async err => {
                    this.error = await this.authenticationService.displayError(err, this.translate);
                    if (!this.error) {
                        this.error = this.translate.instant('errors.phone-is-already-in-use');
                    }
                },
            );
        }
    }

    agree(): void {
        if (this.platform.isAndroidPlatform$.value) {
            // this.iab.create(AppConfig?.agreement, '_system');
        } else {
            this.dialog.open(AgreementPopupComponent, {
                panelClass: 'no-padding-popup',
                backdropClass: 'blur-bg',
                width: '95vw',
            });
        }
    }
}
