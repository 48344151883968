import { NgModule } from '@angular/core';
import { AuthComponent } from './config/default/auth.component';
import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '../@shared/shared.module';
import { LoginPopupComponent } from './login-popup/login-popup.component';
import { RegistrationPopupFirstComponent } from './registration/registration-popup-first/registration-popup-first.component';
import { RegistrationPopupSecondComponent } from './registration/registration-popup-second/registration-popup-second.component';
import { NgxMaskModule } from 'ngx-mask';
import { TelemedicinePublicComponent } from './telemedicine-public/telemedicine-public.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { AgreementPopupComponent } from './registration/registration-popup-first/agreement-popup/agreement-popup.component';
import { OrganizationAuthComponent } from './organization-auth/organization-auth.component';
import { MatDividerModule } from '@angular/material/divider';
import { CountdownModule } from 'ngx-countdown';
import { AuthEnteryComponent } from './auth-entery.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { DeclarationComponent } from './declaration/declaration.component';
import { RegistrationLinkPopupComponent } from './registration-link-popup/registration-link-popup.component';
import { RegistrationModalFirstComponent } from './registration/registration-modal-first/registration-modal-first.component';
import { RegistrationModalSecondComponent } from './registration/registration-modal-second/registration-modal-second.component';
import { ResetPasswordModalComponent } from './reset-password-modal/reset-password-modal.component';

@NgModule({
    declarations: [
        OrganizationAuthComponent,
        AgreementPopupComponent,
        ResetPasswordComponent,
        TelemedicinePublicComponent,
        AuthComponent,
        LoginPopupComponent,
        RegistrationLinkPopupComponent,
        RegistrationPopupFirstComponent,
        RegistrationModalFirstComponent,
        RegistrationPopupSecondComponent,
        RegistrationModalSecondComponent,
        ResetPasswordModalComponent,
        AuthEnteryComponent,
        DeclarationComponent,
    ],
    imports: [
        AuthRoutingModule,
        SharedModule,
        NgxMaskModule.forRoot(),
        MatCheckboxModule,
        NgxDocViewerModule,
        MatDividerModule,
        CountdownModule,
        MatDialogModule,
    ],
    exports: [],
})
export class AuthModule {}
