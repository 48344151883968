import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, DatePipe } from '@angular/common';
import { PagesModule } from './pages/pages.module';
import { AuthModule } from './auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ErrorInterceptor, JwtInterceptor } from '@core/_helpers';
import { IonicStorageModule } from '@ionic/storage-angular';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { fullScreenState } from '@core/_store/video/video.reducer';
import { changePersonReducer } from '@core/_store/person/person.reducer';
import { callReducer } from '@core/_store/call/call.reducer';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PageReducer } from '@core/_effecs/reload/reload.reducer';
import { SharedModule } from './@shared/shared.module';
import { ThemeModule } from './@theme/theme.module';
import { defaultTheme } from './@theme/default-theme';
import { jeremiahTheme } from './@theme/jeremiah-theme';
import { SelectInstanceReducer } from '@core/_effecs/select-instance/select-instance.reducer';
import { metaReducers } from '@core/_store/state-reset';
import { waitMISCodeReducer } from '@core/_store/assign-person/assign-person.reducer';
import { RouteReuseService } from './route-reuse-strategy.service';
import { AddHeaderInterceptor } from '@core/_services/header.interceptor';
import { DeclarationComponent } from './declaration/declaration.component';
import { NotificationService } from '@core/_services/notification.service';
import { registerLocaleData } from '@angular/common';
import localeUk from '@angular/common/locales/uk';
import { Drivers } from '@ionic/storage';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { Market } from '@awesome-cordova-plugins/market/ngx';
registerLocaleData(localeUk);

@NgModule({
    declarations: [AppComponent, DeclarationComponent],
    imports: [
        BrowserModule,
        CommonModule,
        SharedModule,
        IonicModule.forRoot({
            innerHTMLTemplatesEnabled: true,
            hardwareBackButton: true,
            scrollPadding: true,
            scrollAssist: false,
        }),
        AppRoutingModule,
        PagesModule,
        AuthModule,
        BrowserAnimationsModule,
        IonicStorageModule.forRoot({
            name: '_medportaldb',
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
        }),
        LoadingBarHttpClientModule,
        ThemeModule.forRoot({
            themes: [defaultTheme, jeremiahTheme],
            active: 'default',
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            defaultLanguage: 'ua',
        }),
        StoreModule.forRoot(
            {
                call: callReducer,
                fullScreen: fullScreenState,
                changePerson: changePersonReducer,
                page: PageReducer,
                selectInstance: SelectInstanceReducer,
                receivedMISCode: waitMISCodeReducer,
            },
            {
                metaReducers,
                runtimeChecks: {
                    strictStateImmutability: true,
                    strictActionImmutability: true,
                },
            },
        ),
        StoreDevtoolsModule.instrument({ maxAge: 25 }),
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'uk' },
        { provide: MAT_DATE_LOCALE, useValue: 'uk-UK' },
        DatePipe,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AddHeaderInterceptor, multi: true },
        NotificationService,
        MatDatepickerModule,
        OpenNativeSettings,
        FileOpener,
        Market,
        {
            provide: RouteReuseStrategy,
            useClass: RouteReuseService,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
