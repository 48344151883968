import { Component, OnInit } from '@angular/core';
import { ApiListService, AuthenticationService } from '@core/_services';
import { Person } from '@core/_interfaces';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GUIDValidator, PagesService } from '../pages.service';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, throwError } from 'rxjs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Store } from '@ngrx/store';
import { wantMISCode } from '@core/_store/assign-person/assign-person.action';
import { catchError, filter, first } from 'rxjs/operators';

@Component({
    selector: 'app-access-guid-popup',
    templateUrl: './access-guid-popup.component.html',
    styleUrls: ['./access-guid-popup.component.scss'],
})
export class AccessGUIDPopupComponent implements OnInit {
    public loading$: Subject<boolean> = new Subject();
    public people: Person[] | undefined;
    public form: FormGroup | undefined;
    public submitted = false;
    public error = '';
    public mobilePreventClose = false;

    constructor(
        private authenticationService: AuthenticationService,
        private pagesService: PagesService,
        private fb: FormBuilder,
        private translate: TranslateService,
        public dialogRef: MatDialogRef<AccessGUIDPopupComponent>,
        private apiListService: ApiListService,
        private snackBar: MatSnackBar,
        private store: Store<{ receivedMISCode: any }>,
    ) {}

    public async ngOnInit() {
        this.form = this.fb.group({
            id: [''],
            accessGUID: ['', GUIDValidator],
        });
        this.authenticationService
            .getUser()
            .pipe(first())
            .subscribe(user => {
                this.people = user?.persons;
            });
        this.authenticationService
            .getPerson()
            .pipe(
                first(),
                filter(currentPerson => !!currentPerson?.personId),
            )
            .subscribe(currentPerson => {
                this.form?.controls.id.setValue(currentPerson?.personId);
            });

        this.form?.get('id')?.valueChanges.subscribe(async (id: number) => {
            const chosenPerson = this.people?.find((person: Person) => {
                return person.personId === id;
            });
        });
    }

    public async submit() {
        this.submitted = true;
        if (this.form?.valid) {
            this.loading$?.next(true);
            const { accessGUID, id } = this.form.value;
            this.apiListService
                .assignPersonByAccessGUID(accessGUID, id)
                .pipe(
                    first(),
                    catchError(err => {
                        this.loading$?.next(false);
                        this.authenticationService.displayError(err, this.translate).then(err => {
                            this.error = err;
                        });

                        return throwError(() => err);
                    }),
                )
                .subscribe(async () => {
                    this.store.dispatch(wantMISCode());
                    this.dialogRef.close(true);
                });
        }
    }

    public openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action);
    }
}
