export enum StoreKeysMainEnum {
    USER_DATA = 'user',
    INSTANCE_CODE = 'instanceCode',
    SETTINGS = 'settings',
    DEVICE_ID = 'deviceId',
    DEVICE_INFO = 'deviceInfo',
    NOTIFICATION_TOKEN = 'notificationToken',
}

export enum StoreKeysSubEnum {
    ACCESS_TOKEN = 'accessToken',
    REFRESH_TOKEN = 'refreshToken',
    BIOMENTRIC = 'isBiometric',
    BIOMENTRICS_TYPE = 'isBiometricsType',
    AUTO_REFRESH = 'isAutorefresh',
    HOUR_SEGMENTS = 'hourSegments',
    NOTIFICATIONS = 'notifications',
    AVAILABLE_NOTIFICATION_PERMISSION = 'isAvailableNotificationPermission',
    NEED_SEND_TOKEN = 'isNeedSendToken',
}
