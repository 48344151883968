import { NavInterface } from '../../pages/page.component';

export const navLinks: NavInterface[] = [
    {
        icon: 'appointment2',
        name: 'menu.appointment',
        link: 'appointment',
        linkTab: ['pages/appointment', 'pages/appointment/:any'],
        id: 'appointment',
    },
    {
        icon: 'referral',
        name: 'menu.my-doctor',
        link: 'my-doctor',
        linkTab: ['pages/my-doctor'],
        id: 'myDoctor',
    },
    {
        icon: 'calendar-check-regular',
        name: 'menu.my-appointments',
        link: 'my-appointments',
        linkTab: ['pages/my-appointments'],
        id: 'myAppointments',
    },
    {
        icon: 'consultation',
        name: 'visit',
        link: 'visits',
        linkTab: ['pages/visits', 'pages/visit/:any'],
        id: 'visits',
    },
    {
        icon: 'immunization',
        name: 'immunization',
        link: 'immunization',
        linkTab: ['pages/immunization', 'pages/immunization/:any'],
        id: 'immunization',
    },
    {
        icon: 'prescription',
        name: 'prescription',
        link: 'prescription',
        linkTab: ['pages/prescription', 'pages/prescription/:any'],
        id: 'prescription',
    },
    {
        icon: 'analyzes',
        name: 'analyze',
        link: 'analyzes',
        linkTab: ['pages/analyzes', 'pages/analyzes/:any'],
        id: 'analyzes',
    },
    {
        icon: 'studies',
        name: 'research',
        link: 'research',
        linkTab: ['pages/research', 'pages/research/:any'],
        id: 'instrumentalResearch',
    },
    {
        icon: 'referral',
        name: 'referral',
        link: 'referral',
        linkTab: ['pages/referral', 'pages/referral/:any'],
        id: 'referral',
    },
    {
        icon: 'telemedicine',
        name: 'telemedicine',
        link: 'telemedicine',
        linkTab: ['pages/telemedicine', 'pages/telemedicine/:any'],
        id: 'telemedicine',
    },
    {
        icon: 'consultation',
        name: 'events',
        link: 'events',
        linkTab: ['pages/events'],
        id: 'events',
    },
    {
        icon: 'chat',
        name: 'menu.chat',
        link: 'chat',
        linkTab: ['pages/chat'],
        id: 'chat',
    },
    {
        icon: 'notifications',
        name: 'menu.notifications',
        link: 'notifications',
        linkTab: ['pages/notifications'],
        id: 'notifications',
    },
];
