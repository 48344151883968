import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';

@Injectable({ providedIn: 'root' })
export class ModalService {
    public currentModal: HTMLIonModalElement | null = null;
    public currentModalDate: HTMLIonModalElement | null = null;
    constructor(private modalController: ModalController) {}

    public async dismissModal() {
        if (this.currentModal) {
            await this.currentModal.dismiss(null, 'cancel');
        }

        if (this.currentModalDate) {
            console.log('currentModalDate');
            await this.currentModalDate.dismiss(null, 'cancel');
        }
    }

    public openModalComponent(
        component: any,
        componentProps?: any,
        cssClass?: string,
        backdropDismiss?: boolean,
    ): Observable<OverlayEventDetail> {
        return from(this.modalController.create({ component, componentProps, cssClass, backdropDismiss })).pipe(
            switchMap(async modal => {
                this.dismissModal().then();
                this.currentModal = modal;
                await modal.present();

                return modal.onDidDismiss();
            }),
        );
    }
}
