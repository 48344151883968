import { Component, OnDestroy, OnInit } from '@angular/core';
import { PagesService } from '../pages.service';
import {
    Analyze,
    BodyType,
    currentPage,
    IonicRefresherType,
    itemsPerPage,
    PaginationConfig,
    Register,
} from '@core/_interfaces';
import { BehaviorSubject, Subject, Subscription, switchMap, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiListService } from '@core/_services';
import { catchError, first, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-analyzes',
    templateUrl: './analyzes.component.html',
    styleUrls: ['./analyzes.component.scss'],
})
export class AnalyzesComponent implements OnInit, OnDestroy {
    public config: PaginationConfig = {
        pageSize: itemsPerPage,
        page: currentPage,
    };
    public configEvent$ = new BehaviorSubject<PaginationConfig | null>({
        pageSize: itemsPerPage,
        currentPage,
    });
    public data: Register<Analyze> | undefined;
    public reload$: Subscription | undefined;
    public searchForm$ = new BehaviorSubject<any>(null);
    public loading$ = new BehaviorSubject<any>(true);
    private destroyed$ = new Subject<void>();

    constructor(
        private pagesService: PagesService,
        private store: Store<{ page: string }>,
        private apiListService: ApiListService,
    ) {}

    public ngOnInit(): void {
        // this.reload$ = this.store.select(store => store.page)
        //     .subscribe(() => {
        //     });
        this.getData();
        this.searchForm$.pipe(takeUntil(this.destroyed$)).subscribe(searchFormValue => {
            if (searchFormValue?.fromButton) {
                this.configEvent$.next({ currentPage });
                this.getData();
            }
            if (searchFormValue?.reset) {
                this.configEvent$.next({ currentPage });
                this.getData();
            }
        });
    }

    public doRefresh(event: IonicRefresherType) {
        this.getData(event);
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
        this.reload$?.unsubscribe();
        this.searchForm$?.unsubscribe();
        this.configEvent$.unsubscribe();
    }

    public async getData(event?: IonicRefresherType): Promise<void> {
        this.loading$.next(true);
        const searchPayload = this.searchForm$.getValue();

        this.pagesService
            .getSharedPayload()
            .pipe(
                first(),
                switchMap(sharedPayload => {
                    return this.apiListService.getMyAnalysis({
                        page: this.configEvent$.getValue()?.currentPage,
                        pageSize: itemsPerPage,
                        personId: sharedPayload?.personId,
                        ...searchPayload,
                    } as BodyType);
                }),
                catchError(err => {
                    this.loading$.next(false);

                    return throwError(err);
                }),
            )
            .subscribe((data: Register<Analyze>) => {
                this.data = data;
                this.config.totalItems = data?.totalCount;
                this.loading$.next(false);
                if (event) {
                    event?.target?.complete();
                }
            });
    }

    configEvent(ev: PaginationConfig) {
        this.configEvent$.next(ev);
        this.getData();
    }
}
