import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    ApiListService,
    AuthenticationService,
    datePreparation,
    dateToStr,
    emailValidators,
    minDateObject,
    newDate,
    strToDate,
    ValidateDateFromNow,
    variables,
} from '@core/_services';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, of, Subject, switchMap, throwError } from 'rxjs';
import { catchError, filter, first, map, takeUntil } from 'rxjs/operators';
import { RegistrationCredential } from '@core/_interfaces';
import { StorageService } from '@core/_services/store.service';

@Component({
    selector: 'app-registration-popup-second',
    templateUrl: './registration-popup-second.component.html',
    styleUrls: ['./registration-popup-second.component.scss'],
})
export class RegistrationPopupSecondComponent implements OnInit, OnDestroy {
    public option = 'full';
    public form: FormGroup | undefined;
    public formGUID: FormGroup | undefined;
    public submitted = false;
    public registration = true;
    public error: string | undefined;
    public errorEmail: string | undefined;
    public currentDate = newDate;
    public minDateObject = minDateObject;
    public dateSubscription$ = new Subject();
    public loading$ = new BehaviorSubject<boolean>(false);
    public isViewCall$ = new BehaviorSubject<boolean>(false);
    private destroyed$ = new Subject<void>();

    constructor(
        public dialogRef: MatDialogRef<RegistrationPopupSecondComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: RegistrationCredential,
        private fb: FormBuilder,
        private storageService: StorageService,
        private translate: TranslateService,
        private authenticationService: AuthenticationService,
        private apiListService: ApiListService,
        private cdr: ChangeDetectorRef,
    ) {}

    public ngOnInit() {
        this.form = this.fb.group(
            {
                lastName: ['', Validators.required],
                firstName: ['', Validators.required],
                middleName: ['', Validators.required],
                sex: [null, Validators.required],
                email: ['', emailValidators()],
            },
            { validator: ValidateDateFromNow },
        );
        this.formGUID = this.fb.group({
            accessGUID: [
                '',
                Validators.compose([
                    Validators.maxLength(variables.codeFromMisLength),
                    Validators.minLength(variables.codeFromMisLength),
                    Validators.required,
                ]),
            ],
            email: ['', emailValidators()],
        });
        this.form
            .get('birthdayHidden')
            ?.valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe(val => {
                this.form?.patchValue(
                    {
                        birthday: dateToStr(val),
                    },
                    { emitEvent: false },
                );
            });
        this.form
            .get('birthday')
            ?.valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe(val => {
                if (!this.form?.errors?.ValidateDate) {
                    this.form?.patchValue(
                        {
                            birthdayHidden: strToDate(val),
                        },
                        { emitEvent: false },
                    );
                }
            });
        this.formGUID.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.error = '';
            this.errorEmail = '';
        });
        this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.error = '';
            this.errorEmail = '';
        });
        this.dateSubscription$.pipe(filter(i => i instanceof FormGroup)).subscribe((f: any) => {
            if (f.valid && this.form?.valid) {
                this.submit(datePreparation(f.get('birthday')?.value));
            }
        });
    }

    public ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
    public preSubmit() {
        this.submitted = true;
        if (this.option === 'mis') {
            this.submit();
        } else {
            console.log(this.formGUID);
            console.log(this.form);
            console.log(this.submitted);
            this.dateSubscription$.next({ submitted: true });
        }
    }

    public submit(birthday?: string) {
        if (this.option === 'full' ? this.form?.valid : this.formGUID?.valid) {
            this.dialogRef.disableClose = true;
            this.option === 'full' ? (this.registration = false) : (this.registration = true);
            const payload =
                this.option === 'full'
                    ? {
                          ...this.form?.value,
                          birthday,
                      }
                    : { ...this.formGUID?.value };
            this.loading$.next(true);
            setTimeout(() => {
                this.isViewCall$.next(true);
            }, 500);
            this.apiListService
                .registerUser(this.option, {
                    ...this.data,
                    ...payload,
                })
                .pipe(
                    first(),
                    catchError(err => {
                        this.loading$.next(false);
                        setTimeout(() => {
                            this.isViewCall$.next(false);
                        }, 500);
                        if (err?.error?.errorText[0]?.includes('email')) {
                            err.error.errorCode[0] = 'UserExistsEmail';
                            this.authenticationService.displayError(err, this.translate).then(message => {
                                this.errorEmail = message;
                            });
                        } else if (err?.error?.errorText[0]?.includes('Patient not found')) {
                            err.error.errorCode[0] = 'UserNotFound';
                            this.authenticationService.displayError(err, this.translate).then(message => {
                                this.error = message;
                            });
                        } else {
                            this.authenticationService.displayError(err, this.translate).then(message => {
                                this.error = message;
                            });
                        }
                        this.dialogRef.disableClose = false;
                        this.registration = true;
                        this.cdr.detectChanges();
                        return throwError(err);
                    }),
                    map(() => true),
                    filter(result => !!result),
                    switchMap(() => this.storageService.set(variables.login, this.data.login)),
                    takeUntil(this.destroyed$),
                )
                .subscribe(result => {
                    this.loading$.next(false);
                    this.isViewCall$.next(false);
                    this.dialogRef.close({ result: true, option: this.option });
                    this.cdr.detectChanges();
                });
        }
    }
}
