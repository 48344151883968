import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { Device, DeviceId, DeviceInfo, GetLanguageCodeResult } from '@capacitor/device';

@Injectable({
    providedIn: 'root',
})
export class DeviceInfoService {
    public getDeviceId(): Observable<DeviceId> {
        return from(Device.getId());
    }

    public getDeviceInfo(): Observable<DeviceInfo> {
        return from(Device.getInfo());
    }

    public getDeviceLangCode(): Observable<GetLanguageCodeResult> {
        return from(Device.getLanguageCode());
    }
}
