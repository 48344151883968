import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// @ts-ignore
import { ConnectionStatus, Network } from '@capacitor/network';

@Injectable({
    providedIn: 'root',
})
export class NetworkService {
    public networkStatusChange$ = new BehaviorSubject<boolean>(true);
    constructor() {}

    public init() {
        console.log('NetworkService INIT');
        Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
            console.log('Network status changed', status);
            this.networkStatusChange$.next(status?.connected);
        });
    }

    public removeAllListeners() {
        Network.removeAllListeners().then();
    }
}
