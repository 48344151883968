import {Component, OnInit, ViewChild} from '@angular/core';
import {dateFormat, minDateObject, newDate} from '@core/_services';
import {MatDatepicker} from '@angular/material/datepicker';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PopoverController} from '@ionic/angular';
import { format, startOfISOWeek, endOfISOWeek, startOfMonth, endOfMonth, addMonths, startOfQuarter, endOfQuarter } from 'date-fns';

export interface OnDateSelect {
    target: any;
    targetElement: HTMLElement;
    value: Date | null;
}

@Component({
    selector: 'app-date-lookup',
    templateUrl: './date-lookup.component.html',
    styleUrls: ['./date-lookup.component.scss'],
})
export class DateLookupComponent implements OnInit {
    @ViewChild('picker') picker: MatDatepicker<DateLookupComponent> | undefined;
    dateLookupOptions = [
        {
            key: 'currentWeek',
            value: 'week'
        },
        {
            key: 'currentMonth',
            value: 'currentMonth'
        },
        {
            key: 'previousMonth',
            value: 'previousMonth'
        },
        {
            key: 'currentQuoter',
            value: 'currentQuarter'
        },
        {
            key: 'choosePeriod',
            value: 'choosePeriod'
        }
    ];
    form: FormGroup | undefined;
    openCalendar = false;
    currentDate = newDate;
    minDateObject = minDateObject;

    constructor(
        private fb: FormBuilder,
        public popoverController: PopoverController
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            date: [''],
            start: [''],
            end: ['']
        });
    }


    fillDate(type: string) {
        switch (type) {
            case 'week':
                this.setDate(format(startOfISOWeek(newDate), dateFormat)
                    + ' - ' + format(endOfISOWeek(newDate), dateFormat));
                break;
            case 'currentMonth':
                this.setDate(format(startOfMonth(newDate), dateFormat)
                    + ' - ' + format(endOfMonth(newDate), dateFormat));
                break;
            case 'previousMonth':
                this.setDate(format(startOfMonth(addMonths(newDate, -1)), dateFormat)
                    + ' - ' + format(endOfMonth(addMonths(newDate, -1)), dateFormat));
                break;
            case 'currentQuarter':
                this.setDate(format(startOfQuarter(newDate), dateFormat)
                    + ' - ' + format(endOfQuarter(newDate), dateFormat));
                break;
            case 'choosePeriod':
                this.picker?.open();
                break;
        }
    }

    setDate(val: string) {
        this.form?.get('date')?.setValue(val);
        this.popoverController.dismiss(this.form);
    }

    onDate(a: OnDateSelect) {
        if (a.value) {
            this.popoverController.dismiss(this.form);
        }
    }
}
