import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
@Component({
    selector: 'app-autocomplete-modal',
    templateUrl: './autocomplete-modal.component.html',
    styleUrls: ['./autocomplete-modal.component.scss'],
})
export class AutocompleteModalComponent implements OnInit, AfterViewInit {
    public form = this.fb.group({
        value: [''],
    });
    @Input() public formData: string = '';
    @Input() public list: string[] = [];

    constructor(private fb: FormBuilder, private modalCtrl: ModalController, private cdr: ChangeDetectorRef) {}

    public ngOnInit() {}
    public ngAfterViewInit() {
        if (this.formData) {
            this.form.controls.value.patchValue(this.formData, { emitEvent: false });
            this.cdr.detectChanges();
        }
    }

    public submit() {
        if (this.form?.valid) {
            const { value } = this.form?.value;
            this.modalCtrl.dismiss(value);
        }
    }

    onItemSelected(item: string) {
        this.form.controls.value.patchValue(item);
    }
}
