import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
    ApiListService,
    AppConfig,
    AuthenticationService,
    emailValidators,
    passwordValidators,
    phoneMask,
    phoneValidators,
    prefixPhone,
} from '@core/_services';
import { RegistrationPopupFirstComponent } from '../registration/registration-popup-first/registration-popup-first.component';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { AsyncPipe } from '@angular/common';
import { MaskPipe } from 'ngx-mask';
import { AgreementPopupComponent } from '../registration/registration-popup-first/agreement-popup/agreement-popup.component';
import { catchError, debounceTime, filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { filterNumbersFromString } from '@core/_utils/numbers-from-string';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { Platforms } from '@ionic/core';
import { PlatformService } from '@core/_services/platform.service';

export interface LinkData {
    login?: string;
    mail?: string;
    guid?: string;
}

@Component({
    selector: 'app-registration-link-popup',
    templateUrl: './registration-link-popup.component.html',
    styleUrls: ['./registration-link-popup.component.scss'],
    providers: [AsyncPipe, MaskPipe, TranslateService],
})
export class RegistrationLinkPopupComponent implements OnInit {
    public form: FormGroup = this.fb.group({
        login: ['', phoneValidators],
        mail: ['', emailValidators()],
        guid: [{ value: '', disabled: true }],
        password: ['', passwordValidators],
        passwordConfirm: ['', Validators.required],
        agree: [false, Validators.pattern('true')],
    });
    public submitted = false;
    public loading$ = new BehaviorSubject<boolean>(false);
    public passwordsAreNotTheSame = false;
    public phoneMask = phoneMask;
    public error: string | null = null;
    public visiblePassword = false;
    public isLoginAvailable = true;

    private destroyed: Subject<void> = new Subject<void>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: LinkData,
        private platform: PlatformService,
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<RegistrationPopupFirstComponent>,
        public dialog: MatDialog,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        private apiListService: ApiListService,
        // private iab: InAppBrowser,
        private mask: MaskPipe,
    ) {}

    public ngOnInit(): void {
        this.form.controls.login.valueChanges
            .pipe(
                tap(() => {
                    this.isLoginAvailable = true;
                }),
                debounceTime(500),
                map(login => filterNumbersFromString(login)),
                filter(login => !!login && login.length === 10),
                switchMap(login => this.apiListService.isLoginAvailable(login)),
            )
            .subscribe(val => {
                this.isLoginAvailable = val;
            });
        if (this.data) {
            this.form.patchValue({
                mail: this.data.mail || '',
                guid: `${this.translate.instant('registration-by-link.guid')}: ${this.data.guid}`,
            });
            if (this.data.login) {
                this.form.controls.login.setValue(
                    prefixPhone + this.mask.transform(this.data.login.slice(1), phoneMask),
                );
            }
        }
        this.form.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(val => {
            this.error = null;
            this.passwordsAreNotTheSame = val.password !== val.passwordConfirm;
        });
    }

    public submit(): void {
        this.submitted = true;
        if (this.form?.valid && !this.passwordsAreNotTheSame && this.isLoginAvailable) {
            const { login, mail, password } = this.form.getRawValue();
            this.loading$.next(true);
            this.apiListService
                .registerUser('', {
                    login: filterNumbersFromString(login),
                    password,
                    accessGUID: this.data.guid,
                    email: mail,
                })
                .pipe(
                    first(),
                    catchError(err => {
                        this.loading$.next(false);
                        this.error = err?.error.errorCode[0];

                        return of(null);
                    }),
                    filter(value => !!value),
                    takeUntil(this.destroyed),
                )
                .subscribe(v => {
                    this.loading$.next(false);
                    this.dialogRef.close({
                        callInRegisterLink: true,
                        successfulAuth: true,
                        login: filterNumbersFromString(login),
                    });
                });
        } else {
            this.markFormGroupTouched(this.form);
        }
    }

    public agree(): void {
        if (this.platform.isIosPlatform$.value) {
            // this.iab.create(AppConfig?.agreement, '_system');
        } else {
            this.dialog.open(AgreementPopupComponent, {
                panelClass: 'no-padding-popup',
                backdropClass: 'blur-bg',
                width: '95vw',
            });
        }
    }

    public goToLogin(): void {
        this.dialogRef.close({
            callInRegisterLink: true,
        });
    }

    private markFormGroupTouched(form: FormGroup) {
        Object.values(form.controls).forEach(control => {
            control.markAsTouched();

            if ((control as FormGroup).controls) {
                this.markFormGroupTouched(control as FormGroup);
            }
        });
    }
}
