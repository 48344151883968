import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonicRefresherType, Referral, Register } from '@core/_interfaces';
import { BehaviorSubject, Subscription, switchMap, throwError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PagesService } from '../../pages.service';
import { Store } from '@ngrx/store';
import { ApiListService } from '@core/_services';
import { catchError, first } from 'rxjs/operators';

@Component({
    selector: 'app-referral-details',
    templateUrl: './referral-details.component.html',
    styleUrls: ['./referral-details.component.scss'],
})
export class ReferralDetailsComponent implements OnInit, OnDestroy {
    @Input() public data: Register<Referral> | undefined;
    public reload$: Subscription | undefined;
    public id: number | undefined;
    public loading$ = new BehaviorSubject<boolean>(true);
    constructor(
        private route: ActivatedRoute,
        private pagesService: PagesService,
        private store: Store<{ page: string }>,
        private apiListService: ApiListService,
    ) {}

    public ngOnInit() {
        this.id = +this.route.snapshot.params.id;
        this.reload$ = this.store
            .select(store => store.page)
            .subscribe(reload => {
                this.getData();
            });
    }

    public doRefresh(event: IonicRefresherType) {
        this.getData(event);
    }

    public async getData(event?: IonicRefresherType) {
        this.pagesService
            .getSharedPayload()
            .pipe(
                first(),
                switchMap(sharedPayload => {
                    return this.apiListService.getMyDirections({ ...sharedPayload, id: this.id, page: 1, pageSize: 1 });
                }),
                catchError(err => {
                    if (event) {
                        event?.target?.complete();
                    } else {
                        this.loading$.next(false);
                    }

                    return throwError(err);
                }),
            )
            .subscribe((data: Register<Referral>) => {
                this.data = data;
                if (event) {
                    event?.target?.complete();
                } else {
                    this.loading$.next(false);
                }
            });
    }

    public ngOnDestroy() {
        this.reload$?.unsubscribe();
    }
}
