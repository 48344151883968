import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { from, Observable, ReplaySubject, Subject } from 'rxjs';
import { currentPage, itemsPerPage, PaginationConfig } from '@core/_interfaces';
import { dateFormat, variables } from '@core/_services';
import { DoctorInterface, RatingInterface, SearchDocListInterface } from '@core/_services';
import { SearchService } from '../../components/search/search.service';
import { PagesService } from '../../../pages/pages.service';
import { map } from 'rxjs/operators';
import { StorageService } from '@core/_services/store.service';

@Component({
    selector: 'app-specialist-card',
    templateUrl: './specialist-card.component.html',
    styleUrls: ['./specialist-card.component.scss'],
    providers: [SearchService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialistCardComponent implements OnDestroy {
    @Input() public set doctors(doctors: SearchDocListInterface | undefined) {
        if (!doctors) return;
        if (doctors.totalCount) {
            this.config.totalItems = doctors.totalCount;
        }
        this.doctors$.next(doctors);
    }
    @Input() public loading: Subject<boolean> | undefined;
    @Output() public configEvent: EventEmitter<PaginationConfig> = new EventEmitter<PaginationConfig>();

    public doctors$: Subject<SearchDocListInterface> = new ReplaySubject(1);
    public showPortalNumberReferral$: Observable<boolean> = this.storageService
        .get<any>(variables.instanceConfig)
        .pipe(map(config => config?.showPortalNumberReferral || false));
    public dateFormat = dateFormat;
    public config: PaginationConfig = {
        itemsPerPage,
        currentPage,
        page: currentPage,
        pageSize: itemsPerPage,
    };
    public ratingStrings: RatingInterface = {
        header: 'Ваша оцінка лікарю',
        makeRating: 'оцініть лікаря',
        placeholder: 'Залиште ваш відгук про прийом у лікаря',
    };

    private destroyed$: Subject<void> = new Subject<void>();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private pagesService: PagesService,
        private storageService: StorageService,
    ) {}

    public ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public changePage(): void {
        this.pagesService.scrollToTop('app-appointment');
        this.pagesService.scrollToTop('app-auth');
        this.pagesService.scrollToTop('app-organization-page');
        this.configEvent.emit({
            page: this.config.currentPage,
            pageSize: this.config.itemsPerPage,
        });
    }
}
