import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { currentPage, Immunization, itemsPerPage, PaginationConfig, Register } from '@core/_interfaces';
import { BehaviorSubject, Subject } from 'rxjs';
import { PagesService } from '../../pages.service';
import { dateFormat } from '@core/_services';

@Component({
    selector: 'app-immunization-card',
    templateUrl: './immunization-card.component.html',
    styleUrls: ['./immunization-card.component.scss'],
})
export class ImmunizationCardComponent implements OnChanges {
    @Input() public clickable: boolean | undefined;
    @Input() public cssClass: boolean | undefined;
    @Input() public data: Register<Immunization> | undefined;
    public dateFormat = dateFormat;
    public config: PaginationConfig = {
        itemsPerPage,
        currentPage,
    };
    @Output() public configEvent = new EventEmitter<PaginationConfig>();
    @Input() public searchForm$: Subject<string> | undefined;

    constructor(private pagesService: PagesService) {}

    public ngOnChanges(changes: SimpleChanges) {
        (this.config as PaginationConfig).totalItems = changes?.data?.currentValue?.totalCount;
        (this.data as Register<Immunization>).data = changes?.data?.currentValue?.data;
        if (changes?.data?.currentValue?.currentPage) {
            (this.config as PaginationConfig).currentPage = changes?.data?.currentValue?.currentPage;
        }
    }

    public changePage() {
        this.configEvent.emit(this.config);
        this.pagesService.scrollToTop('app-immunization');
    }
}
