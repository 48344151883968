import {Component, OnInit} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-no-doctor-declaration-popup',
    templateUrl: './no-doctor-declaration-popup.component.html',
    styleUrls: ['./no-doctor-declaration-popup.component.scss'],
})
export class NoDoctorDeclarationPopupComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<NoDoctorDeclarationPopupComponent>
    ) {
    }

    ngOnInit() {
    }

}
