import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    ApiListService,
    AuthenticationService,
    datePreparation,
    emailValidators,
    minDateObject,
    newDate,
    strToDate,
    ValidateDateFromNow,
    variables,
} from '@core/_services';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, of, Subject, switchMap, throwError } from 'rxjs';
import { catchError, filter, first, map, takeUntil } from 'rxjs/operators';
import { StorageService } from '@core/_services/store.service';
import { ModalController } from '@ionic/angular';
import { RegistrationModalFirstComponent } from '../registration-modal-first/registration-modal-first.component';
import { ModalService } from '@core/_services/modal.service';

@Component({
    selector: 'app-registration-modal-second',
    templateUrl: './registration-modal-second.component.html',
    styleUrls: ['./registration-modal-second.component.scss'],
    imports: [],
})
export class RegistrationModalSecondComponent implements OnInit, OnDestroy {
    @Input() public login: string | null = null;
    @Input() public password: string | null = null;
    @Input() public passwordConfirm: string | null = null;
    public option = 'full';
    public form: FormGroup | undefined;
    public formGUID: FormGroup | undefined;
    public submitted = false;
    public registration = true;
    public error: string | undefined;
    public errorEmail: string | undefined;
    public currentDate = newDate;
    public minDateObject = minDateObject;
    public dateSubscription$ = new Subject();
    public loading$ = new BehaviorSubject<boolean>(false);
    public isViewCall$ = new BehaviorSubject<boolean>(false);
    private destroyed$ = new Subject<void>();

    constructor(
        private fb: FormBuilder,
        private storageService: StorageService,
        private translate: TranslateService,
        private authenticationService: AuthenticationService,
        private apiListService: ApiListService,
        private cdr: ChangeDetectorRef,
        private modalCtrl: ModalController,
        private modalService: ModalService,
    ) {}

    public ngOnInit() {
        this.form = new FormGroup<any>({
            lastName: new FormControl('', Validators.required),
            firstName: new FormControl('', Validators.required),
            middleName: new FormControl('', Validators.required),
            sex: new FormControl(null, Validators.required),
            email: new FormControl(null, emailValidators()),
        });
        this.formGUID = new FormGroup<any>({
            accessGUID: new FormControl(
                '',
                Validators.compose([
                    Validators.maxLength(variables.codeFromMisLength),
                    Validators.minLength(variables.codeFromMisLength),
                    Validators.required,
                ]),
            ),
            email: new FormControl(null, emailValidators()),
        });

        this.formGUID.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.error = '';
            this.errorEmail = '';
        });
        this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.error = '';
            this.errorEmail = '';
        });
        this.dateSubscription$.pipe(filter(i => i instanceof FormGroup)).subscribe((f: any) => {
            if (f.valid && this.form?.valid) {
                this.submit(datePreparation(f.get('birthday')?.value));
            }
        });
    }

    public ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
    public preSubmit() {
        this.submitted = true;
        if (this.option === 'mis') {
            this.submit();
        } else {
            console.log(this.formGUID);
            console.log(this.form);
            console.log(this.submitted);
            this.dateSubscription$.next({ submitted: true });
        }
    }
    public returnToFirstRegisterModal() {
        this.modalService
            .openModalComponent(RegistrationModalFirstComponent, null, 'no-padding-popup', false)
            .pipe(first())
            .subscribe();
    }

    public submit(birthday?: string) {
        if (this.option === 'full' ? this.form?.valid : this.formGUID?.valid) {
            this.option === 'full' ? (this.registration = false) : (this.registration = true);
            const payload =
                this.option === 'full'
                    ? {
                          ...this.form?.value,
                          birthday,
                      }
                    : { ...this.formGUID?.value };
            this.loading$.next(true);
            setTimeout(() => {
                this.isViewCall$.next(true);
            }, 500);
            this.apiListService
                .registerUser(this.option, {
                    ...{ login: this.login, password: this.password, passwordConfirm: this.passwordConfirm },
                    ...payload,
                })
                .pipe(
                    first(),
                    catchError(err => {
                        this.loading$.next(false);
                        setTimeout(() => {
                            this.isViewCall$.next(false);
                        }, 500);
                        if (err?.error?.errorText[0]?.includes('email')) {
                            err.error.errorCode[0] = 'UserExistsEmail';
                            this.authenticationService.displayError(err, this.translate).then(message => {
                                this.errorEmail = message;
                            });
                        } else if (err?.error?.errorText[0]?.includes('Patient not found')) {
                            err.error.errorCode[0] = 'UserNotFound';
                            this.authenticationService.displayError(err, this.translate).then(message => {
                                this.error = message;
                            });
                        } else {
                            this.authenticationService.displayError(err, this.translate).then(message => {
                                this.error = message;
                            });
                        }

                        this.registration = true;
                        this.cdr.detectChanges();
                        return throwError(err);
                    }),
                    map(() => true),
                    filter(result => !!result),
                    switchMap(() => this.storageService.set(variables.login, this.login)),
                    takeUntil(this.destroyed$),
                )
                .subscribe(result => {
                    console.log('SUCCESS REGISTER', result);
                    this.loading$.next(false);
                    this.isViewCall$.next(false);
                    this.authenticationService
                        .openLogin('/pages/appointment', null, { option: this.option })
                        .pipe(first())
                        .subscribe();
                    this.cdr.detectChanges();
                });
        }
    }
}
