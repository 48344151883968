import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Instances, Person, RefreshToken, SuccessfulAuthType, UserInterface } from '../_interfaces';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LoginPopupComponent } from '../../auth/login-popup/login-popup.component';
import { ApiListService, AppConfig, variables } from './';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { IntanceConfigModel } from '@core/_interfaces/instance-config.model';
import {
    LinkData,
    RegistrationLinkPopupComponent,
} from '../../auth/registration-link-popup/registration-link-popup.component';
import { FcmService } from '@core/_services/fcm.service';
import { StorageService } from '@core/_services/store.service';
import { PlatformService } from '@core/_services/platform.service';
import { LoginModalComponent } from '../../auth/login-modal/login-modal.component';
import { ModalService } from '@core/_services/modal.service';
import { BiometricService } from '@core/_services/biometric.service';

export interface LoginPayload {
    login: string;
    password: string;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public instanceConfig$: BehaviorSubject<IntanceConfigModel | null> = new BehaviorSubject<IntanceConfigModel | null>(
        null,
    );
    public loginRef: MatDialogRef<LoginPopupComponent> | null = null;
    private appConfig = AppConfig;

    constructor(
        private http: HttpClient,
        private router: Router,
        private storageService: StorageService,
        public dialog: MatDialog,
        private pl: PlatformService,
        private fcm: FcmService,
        private apiListService: ApiListService,
        private snackBar: MatSnackBar,
        private modalService: ModalService,
        private injector: Injector,
    ) {}

    public async currentUserValue() {
        return this.getUser();
    }

    public login(body: LoginPayload): Observable<UserInterface> {
        return this.apiListService.getToken(body);
    }

    public logout(): Observable<any> {
        console.log('call logout');
        return combineLatest([
            this.storageService.remove(variables.currentUser),
            this.storageService.remove(variables.currentPerson),
            this.storageService.remove(variables.isBiometric),
            this.storageService.remove(variables.isBiometricsType),
        ]).pipe(
            first(),
            switchMap(() => {
                if (
                    (this.pl.isAndroidPlatform$?.value || this.pl.isIosPlatform$?.value) &&
                    !this.pl.isMobileWebPlatform$?.value
                ) {
                    return this.injector.get(BiometricService).deleteCredential();
                }

                return of(null);
            }),
            map(() => {
                console.log('call logout refirect auth');
                this.router.navigate([`/${variables.auth}`]).then();

                return { successfulAuth: false };
            }),
        );
    }

    public setUser(user: UserInterface | RefreshToken): Observable<any> {
        console.log('setUser ', user);
        return this.storageService.set(variables.currentUser, user).pipe(
            switchMap(() => {
                console.log('setUser set currentUser', user);
                return this.storageService.get(variables.currentPerson).pipe(
                    switchMap(currentPerson => {
                        console.log('setUser get currentPerson', currentPerson);
                        if (!currentPerson) {
                            return this.storageService.get<any>(variables.currentUser).pipe(
                                switchMap(currentUser => {
                                    console.log('setUser currentUser PERSON', currentUser);
                                    return this.storageService.set(variables.currentPerson, currentUser?.persons[0]);
                                }),
                            );
                        }
                        return of(currentPerson);
                    }),
                );
            }),
        );
    }

    public getUser(): Observable<UserInterface> {
        return this.storageService.get<UserInterface>(variables.currentUser);
    }

    public getPerson(): Observable<Person> {
        return this.storageService.get<Person>(variables.currentPerson);
    }

    public getInstance(): Observable<Instances> {
        return this.storageService.get<Instances>(variables.currentInstance);
    }

    public hearthBeat(returnURL?: string): Observable<any> {
        return this.apiListService.getPerson(returnURL).pipe(
            catchError(err => {
                return throwError(err);
            }),
        );
    }

    public openLoginDialog(route: string): Observable<{ successfulAuth: boolean }> {
        this.loginRef = null;
        if (route) {
            if (
                (this.pl.isIosPlatform$.value || this.pl.isAndroidPlatform$?.value) &&
                !this.pl.isMobileWebPlatform$?.value
            ) {
                return this.modalService
                    .openModalComponent(LoginModalComponent, { url: route }, 'login-popup', false)
                    .pipe(
                        map(result => {
                            // @ts-ignore
                            if (result?.data?.successfulAuth) {
                                return { successfulAuth: true };
                            }
                            return { successfulAuth: false };
                        }),
                    );
            } else {
                this.loginRef = this.dialog.open(LoginPopupComponent, {
                    panelClass: 'login-popup',
                    width: '500px',
                    maxWidth: 'calc(100% - 10px)',
                    data: { url: route },
                    backdropClass: 'blur-bg',
                    disableClose: route.includes('appointmentCovidImmunization'),
                });
                return this.loginRef.afterClosed().pipe(
                    map((successfulAuth: SuccessfulAuthType) => {
                        console.log('manageLogout into call afterClosed map');
                        this.loginRef = null;
                        if (successfulAuth?.successfulAuth) {
                            return { successfulAuth: true };
                        }
                        return { successfulAuth: false };
                    }),
                );
            }
        }

        console.log('manageLogout into call logout openLoginDialog');
        this.loginRef = null;
        return this.logout();
    }

    public manageLogout(route: string): Observable<{ successfulAuth: boolean }> {
        console.warn('manageLogout into call');
        return this.storageService.get<UserInterface>(variables.currentUser).pipe(
            switchMap((currentUser: UserInterface) => {
                console.log('manageLogout into currentUser', currentUser);
                if (!currentUser) {
                    return this.openLoginDialog(route);
                }

                return this.apiListService.refreshToken(currentUser?.refreshToken).pipe(
                    switchMap(res => {
                        return this.setUser(res).pipe(
                            map(() => {
                                this.router.navigate([route]).then();
                                return { successfulAuth: true };
                            }),
                        );
                    }),
                    catchError(err => {
                        console.log('manageLogout error refresh');

                        return this.openLoginDialog(route);
                    }),
                );
            }),
        );
    }

    public openAuthPopupByLink(
        data: LinkData,
    ): Observable<{ successfulAuth: boolean; callInRegisterLink: boolean; login: string }> {
        return this.dialog
            .open(RegistrationLinkPopupComponent, {
                panelClass: ['login-popup', 'scroll-container'],
                width: '500px',
                data,
                backdropClass: 'blur-bg',
                disableClose:
                    (this.pl.isIosPlatform$.value || this.pl.isAndroidPlatform$.value) &&
                    !this.pl.isMobileWebPlatform$.value,
            })
            .afterClosed();
    }

    public openLogin(
        route: string,
        registerData: { callInRegisterLink: boolean; successfulAuth: boolean; login: string } | null,
        resultRegister?: { option: string },
    ): Observable<SuccessfulAuthType> {
        console.warn('openLogin', route, registerData, resultRegister);
        if (!this.pl.isDesktopPlatform$.value) {
            return this.modalService
                .openModalComponent(
                    LoginModalComponent,
                    { url: route, registerData, resultRegister },
                    'login-popup',
                    false,
                )
                .pipe(
                    map(result => {
                        // @ts-ignore
                        if (result?.data?.successfulAuth) {
                            return { successfulAuth: true };
                        }
                        return { successfulAuth: false };
                    }),
                );
        }
        return this.dialog
            .open(LoginPopupComponent, {
                panelClass: 'login-popup',
                width: '500px',
                maxWidth: '100%',
                data: { url: route, registerData },
                backdropClass: 'blur-bg',
                disableClose:
                    route.includes('appointmentCovidImmunization') ||
                    ((this.pl.isIosPlatform$.value || this.pl.isAndroidPlatform$.value) &&
                        !this.pl.isMobileWebPlatform$.value),
            })
            .afterClosed();
    }

    public async displayError(err: HttpErrorResponse, translate: TranslateService): Promise<string> {
        if (err?.error?.errorCode?.length) {
            if (err.error.errorCode[0] === 'UserHasBeenMerged') {
                return translate.instant('errors.UserHasBeenMerged', { number: err.error.errorText[0] });
            }
            let str = '';
            err.error.errorCode.forEach((item: string) => {
                str += translate.instant('errors.' + item) + ',';
            });
            str = str.slice(0, -1);

            return str;
        } else {
            return '';
        }
    }

    public openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action);
    }

    public manageCurrentInstance(currentInstance: Instances): Observable<any> {
        return this.storageService.set(variables.currentInstance, currentInstance).pipe(
            switchMap(() =>
                this.apiListService.getInstanceConfig(currentInstance.id).pipe(
                    switchMap((instanceConfig: IntanceConfigModel) => {
                        const currentInstanceConfig: IntanceConfigModel = instanceConfig
                            ? instanceConfig
                            : {
                                  appStoreLinks: {
                                      android: this.appConfig.androidApp as string,
                                      ios: this.appConfig.iosApp as string,
                                  },
                                  reception: {
                                      showFieldsAppointments: true,
                                      showReasonsForCancel: false,
                                  },
                                  autocompleteFields: true,
                                  privateSector: true,
                                  showOrganizationName: true,
                                  showPages: this.appConfig.showingPages,
                                  showPortalNumberReferral: true,
                              };

                        this.instanceConfig$.next(currentInstanceConfig);

                        return this.storageService.set(variables.instanceConfig, currentInstanceConfig);
                    }),
                ),
            ),
        );
    }
}
