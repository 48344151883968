import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {dateToStr, minDateObject, newDate, strToDate, ValidateDateFromNow} from '@core/_services';
import {BehaviorSubject} from 'rxjs';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'app-date-input',
    templateUrl: './date-input.component.html',
    styleUrls: ['./date-input.component.scss'],
})
export class DateInputComponent implements OnInit, OnChanges {
    @Input('initialValue') initialValue: string | undefined | boolean;
    @Input('dateSubscription$') dateSubscription$?: BehaviorSubject<any>;
    @Output() outputForm = new EventEmitter<FormGroup>();
    form: FormGroup | undefined;
    currentDate = newDate;
    minDateObject = minDateObject;
    submitted = false;

    constructor(
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {
        this.__initForm();
        this.dateSubscription$?.pipe(
            filter(i => i?.submitted))
            .subscribe((val) => {
                this.submitted = true;
                this.dateSubscription$?.next(this.form);
            });
    }

    __initForm() {
        this.form = this.fb.group({
            birthday: [this.initialValue || '', Validators.required],
            birthdayHidden: ['']
        }, {validator: ValidateDateFromNow});
        this.form.get('birthdayHidden')?.valueChanges.subscribe((val: Date) => {
            this.form?.patchValue({
                birthday: dateToStr(val)
            }, {emitEvent: false});
        });
        this.form.get('birthday')?.valueChanges.subscribe((val: string) => {
            this.form?.patchValue({
                birthdayHidden: strToDate(val)
            }, {emitEvent: false});
        });
        if (this.initialValue) {
            this.form?.patchValue({
                birthdayHidden: strToDate(this.form.get('birthday')?.value as string)
            }, {emitEvent: false});
        }
        this.form.valueChanges.subscribe(() => {
            this.outputForm.emit(this.form);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (typeof changes?.initialValue?.currentValue === 'boolean') {
            this.submitted = true;
            this.outputForm.emit(this.form);
        } else if (changes?.initialValue?.currentValue) {
            this.initialValue = changes.initialValue.currentValue;
            this.__initForm();
        }
    }
}
