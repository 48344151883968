import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiListService } from '@core/_services';
import {
    Appointment,
    currentPage,
    IonicRefresherType,
    itemsPerPage,
    PaginationConfig,
    Register,
} from '@core/_interfaces';
import { PagesService } from '../pages.service';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription, switchMap, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

@Component({
    selector: 'app-telemedicine',
    templateUrl: './telemedicine.component.html',
    styleUrls: ['./telemedicine.component.scss'],
})
export class TelemedicineComponent implements OnInit, OnDestroy {
    public isActual = true;
    public config: PaginationConfig = {
        pageSize: itemsPerPage,
        page: currentPage,
    };
    public appointments: Register<Appointment> | undefined;
    public reload$: Subscription | undefined;
    public configEvent$ = new BehaviorSubject<PaginationConfig | null>({
        pageSize: itemsPerPage,
        currentPage,
    });

    public loading$ = new BehaviorSubject<boolean>(true);

    constructor(
        private router: Router,
        private pagesService: PagesService,
        private store: Store<{ page: string }>,
        private apiListService: ApiListService,
    ) {}

    public async ngOnInit() {
        this.reload$ = this.store
            .select(store => store.page)
            .subscribe(() => {
                this.getMyAppointments();
            });
    }

    public doRefresh(event: IonicRefresherType) {
        this.getMyAppointments(undefined, event);
    }

    public ngOnDestroy() {
        this.reload$?.unsubscribe();
    }

    public async getMyAppointments(config?: PaginationConfig, event?: IonicRefresherType): Promise<void> {
        if (!event) {
            this.loading$.next(true);
        }
        let paginationConfig: PaginationConfig = {
            page: this.configEvent$.getValue()?.currentPage,
            pageSize: itemsPerPage,
        };
        if (config) {
            paginationConfig = config;
        }
        this.pagesService
            .getSharedPayload()
            .pipe(
                first(),
                switchMap(sharedPayload => {
                    return this.apiListService.getMyAppointments({
                        page: paginationConfig.page,
                        pageSize: paginationConfig.pageSize,
                        personId: sharedPayload?.personId,
                        isActual: this.isActual || null,
                        isTelemedicineConsultation: true,
                    });
                }),
                catchError(err => {
                    if (event) {
                        event?.target?.complete();
                    } else {
                        this.loading$.next(false);
                    }

                    return throwError(err);
                }),
            )
            .subscribe((data: Register<Appointment>) => {
                this.pagesService.addImages(data.data, 'imageId').then((val: Register<Appointment>['data']) => {
                    this.appointments = {
                        data: val,
                        totalCount: data.totalCount,
                        currentPage: data.currentPage,
                    };
                    if (event) {
                        event?.target?.complete();
                    } else {
                        this.loading$.next(false);
                    }
                });
            });
    }

    public toggle(state: boolean): void {
        this.config = {
            pageSize: itemsPerPage,
            page: currentPage,
        };
        this.isActual = state;
        this.getMyAppointments(this.config);
    }

    public configEvent(ev: PaginationConfig) {
        this.configEvent$.next(ev);
        this.getMyAppointments();
    }
}
