import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AutoComplete, AutoCompleteDataOutputType, Location, Organization, SearchService } from './search.service';
import { ApiListService, debounceTimeDelay, OrganizationServiceType, variables } from '@core/_services';
import { AutoCompleteData } from '@core/_interfaces';
import { debounceTime, filter, first, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { PagesService } from '../../../pages/pages.service';
import { Store } from '@ngrx/store';
import { StorageService } from '@core/_services/store.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    providers: [SearchService],
})
export class SearchComponent implements OnInit, OnChanges {
    @Input() option$: BehaviorSubject<'doctor' | 'lz'> | undefined;
    @Input() makeAppointment?: boolean;
    @Input() fromOrganization?: boolean;
    @Input() fromOrganizationData?: OrganizationServiceType[] | undefined;
    @Input() region?: boolean | undefined;
    option: 'doctor' | 'lz' = 'doctor';
    @Input() loading$!: Subject<boolean>;
    rotate = false;
    doctorSearch = false;
    lzSearch = false;
    form: FormGroup | undefined;
    districts: AutoComplete[] | undefined;
    populationPointsData: Location[] | undefined;
    organizationData: Organization[] | undefined;
    specialityData: AutoComplete[] | undefined;
    serviceData: AutoComplete[] | undefined;
    orgData: AutoComplete[] | undefined;
    @Output() autoCompleteData = new EventEmitter<AutoCompleteDataOutputType>();
    @Output() clientSearch = new EventEmitter<string>();
    currentInstance: number | undefined;
    currentSettlement: number | undefined;
    firstTouch: string[] = [];
    reload$: Subscription | undefined;
    selectInstance: Subscription | undefined;
    selectInstance$: Subscription | undefined;
    setOption$: Subscription | undefined;
    showFieldsAppointment: boolean | undefined;
    autocompleteFields: boolean | undefined;
    orgVal = '';

    constructor(
        private fb: FormBuilder,
        private storageService: StorageService,
        private pagesService: PagesService,
        private store: Store<{ page: string; selectInstance: any }>,
        private apiListService: ApiListService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.selectInstance$ = this.store.select('selectInstance').subscribe(val => {
            this.loading$?.next(false);
        });
        function isString(val: object | string) {
            return typeof val === 'string';
        }
        this.reload$ = this.store
            .select(store => store.page)
            .subscribe(async (reload: any) => {
                this.storageService
                    .get<any>(variables.instanceConfig)
                    .pipe(first())
                    .subscribe(instanceConfig => {
                        if (instanceConfig) {
                            this.showFieldsAppointment = instanceConfig?.reception?.showFieldsAppointments;
                            this.autocompleteFields = instanceConfig?.autocompleteFields;
                        } else {
                            if (this.showFieldsAppointment === undefined) {
                                this.showFieldsAppointment = true;
                                this.cdr.detectChanges();
                            }
                        }
                    });

                this.storageService
                    .get<any>(variables.currentInstance)
                    .pipe(first())
                    .subscribe(currentInstance => {
                        this.currentInstance = currentInstance?.id;
                    });

                if (reload?.type) {
                    this.change();
                }
            });
        this.form = this.fb.group({
            area: ['', this.pagesService.valueSelected(this.districts)],
            populationPoints: ['', this.pagesService.valueSelected(this.populationPointsData)],
            organizations: [''],
            orgAutoCmplt: [''],
            serviceAutoCmplt: [''],
            speciality: ['', this.pagesService.valueSelected(this.specialityData)],
            doctor: [''],
            service: ['', this.pagesService.valueSelected(this.serviceData)],
        });
        this.form.controls.area.valueChanges
            .pipe(
                debounceTime(debounceTimeDelay),
                filter(n => isString(n)),
            )
            .subscribe(value => {
                this.getDistrictAutoComplete(value);
            });
        this.form.controls.service.valueChanges
            .pipe(
                debounceTime(debounceTimeDelay),
                filter(n => isString(n)),
            )
            .subscribe(value => {
                if (!this.fromOrganization) {
                    this.getServicesAutoComplete(value, 'service');
                } else {
                    this.clientSearch.emit(value);
                }
            });
        this.form.controls.serviceAutoCmplt.valueChanges
            .pipe(
                debounceTime(debounceTimeDelay),
                filter(n => isString(n)),
            )
            .subscribe(value => {
                this.getServicesAutoComplete(value, 'serviceAutoCmplt');
            });
        this.form.controls.orgAutoCmplt.valueChanges
            .pipe(
                debounceTime(debounceTimeDelay),
                filter(n => isString(n)),
            )
            .subscribe(value => {
                this.orgVal = value;
                this.getOrgAutoComplete(value);
                this.getServicesAutoComplete('', 'serviceAutoCmplt');
            });
        this.form.controls.populationPoints.valueChanges
            .pipe(
                debounceTime(debounceTimeDelay),
                filter(n => isString(n)),
            )
            .subscribe(value => {
                this.GetSettlementsAutoComplete(value);
            });
        this.form.controls.speciality.valueChanges
            .pipe(
                debounceTime(debounceTimeDelay),
                filter(n => isString(n)),
            )
            .subscribe(value => {
                this.GetSpecialitiesAutoComplete(value);
            });
        this.setOption$ = this.option$?.subscribe(v => {
            this.option = v;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.reload$?.unsubscribe();
        this.selectInstance$?.unsubscribe();
        this.setOption$?.unsubscribe();
    }

    async getDistrictAutoComplete(Text: string) {
        this.storageService
            .get<any>(variables.currentInstance)
            .pipe(
                first(),
                switchMap(currentInstance => {
                    this.currentInstance = currentInstance?.id;

                    return this.apiListService.getDistrictAutoComplete(Text, this.currentInstance as number);
                }),
            )
            .subscribe((districts: AutoComplete[]): void => {
                this.districts = districts;
                this.form?.get('area')?.setValidators(this.pagesService.valueSelected(districts));
                this._setField(districts, 'area');
                this.form?.get('populationPoints')?.patchValue('', { emitEvent: false });
                this.GetSettlementsAutoComplete(this.form?.get('area')?.value);
            });
    }

    async GetSettlementsAutoComplete(Text: string) {
        this.storageService
            .get<any>(variables.currentInstance)
            .pipe(
                first(),
                switchMap(currentInstance => {
                    this.currentInstance = currentInstance?.id;
                    const districtId = this._findID(this.districts, this.form?.get('area')?.value, 'name', 'id');

                    return this.apiListService.GetSettlementsAutoComplete(
                        districtId || null,
                        Text,
                        this.currentInstance as number,
                    );
                }),
            )
            .subscribe((resp: AutoComplete[]) => {
                this.populationPointsData = resp;
                this.form?.get('populationPoints')?.setValidators(this.pagesService.valueSelected(resp));
                this._setField(resp, 'populationPoints');
            });
    }

    async GetSpecialitiesAutoComplete(Text: string) {
        this.storageService
            .get<any>(variables.currentInstance)
            .pipe(
                first(),
                switchMap(currentInstance => {
                    this.currentInstance = currentInstance?.id;

                    return this.apiListService.GetSpecialitiesAutoComplete(Text, this.currentInstance as number);
                }),
            )
            .subscribe((resp: AutoComplete[]) => {
                this.specialityData = resp;
                this.form?.get('speciality')?.setValidators(this.pagesService.valueSelected(resp));
                this._setField(resp, 'speciality');
            });
    }

    async getServicesAutoComplete(Text: string = '', field: string) {
        const medicalOrgId = this.orgData?.find(el => el.name === this.orgVal)?.id;
        this.storageService
            .get<any>(variables.currentInstance)
            .pipe(
                first(),
                switchMap(currentInstance => {
                    this.currentInstance = currentInstance?.id;

                    return this.apiListService.getServicesAutoComplete(
                        Text,
                        this.currentInstance as number,
                        medicalOrgId,
                    );
                }),
            )
            .subscribe((resp: AutoComplete[]) => {
                this.serviceData = resp;
                this.form?.get(field)?.setValidators(this.pagesService.valueSelected(resp));
                this._setField(resp, field);
            });
    }

    async getOrgAutoComplete(Text: string) {
        this.storageService
            .get<any>(variables.currentInstance)
            .pipe(
                first(),
                switchMap(currentInstance => {
                    this.currentInstance = currentInstance?.id;

                    return this.apiListService.getOrgAutoComplete(Text, this.currentInstance as number);
                }),
            )
            .subscribe((resp: AutoComplete[]) => {
                this.orgData = resp;
                this.form?.get('orgAutoCmplt')?.setValidators(this.pagesService.valueSelected(resp));
                this._setField(resp, 'orgAutoCmplt');
            });
    }

    async submit() {
        if (this.form?.valid) {
            this.storageService
                .get<any>(variables.currentInstance)
                .pipe(first())
                .subscribe(currentInstance => {
                    this.currentInstance = currentInstance?.id;
                    const {
                        doctor,
                        area,
                        populationPoints,
                        orgAutoCmplt,
                        organizations,
                        speciality,
                        service,
                        serviceAutoCmplt,
                    } = this.form?.value;
                    const req = {
                        fullName: doctor?.trim() || organizations?.trim() || orgAutoCmplt?.trim() || '',
                        districtId: this._findID(this.districts, area, 'name', 'id'),
                        settlementId: this._findID(this.populationPointsData, populationPoints, 'name', 'id'),
                        organizationId: this._findID(this.organizationData, orgAutoCmplt, 'shortName', 'id'),
                        serviceId:
                            this._findID(this.serviceData, service, 'name', 'id') ||
                            this._findID(this.serviceData, serviceAutoCmplt, 'name', 'id'),
                        service: serviceAutoCmplt || service,
                        instanceId: this.currentInstance,
                        specialityId: this._findID(this.specialityData, speciality, 'name', 'id'),
                    } as AutoCompleteData;
                    this.autoCompleteData.emit({
                        type: 'search',
                        invalid: false,
                        option: this.option,
                        req,
                    });
                });
        } else {
            this.autoCompleteData.emit({
                type: 'search',
                invalid: true,
                option: this.option,
            });
        }
    }

    _setField(data: AutoComplete[], field: string): void {
        this.form?.get(field)?.updateValueAndValidity({ emitEvent: false });
        if (data?.length === 1) {
            this.form?.get(field)?.patchValue(data[0].name, { emitEvent: false });
        }
    }

    _findID<T>(
        arr: T[] | undefined,
        value: T[keyof T],
        searchParameter: keyof T,
        returnKeyID: keyof T,
    ): number | undefined {
        if (arr) {
            const el = arr.find(x => x[searchParameter] === value);
            if (el) {
                return Number(el[returnKeyID]);
            }
        }
    }

    pathFormField(field: string) {
        if (!this.firstTouch.includes(field)) {
            this.form?.get(field)?.patchValue('');
            this.firstTouch.push(field);
        }
    }

    change() {
        this.form?.reset();
        this.autoCompleteData.emit({ type: 'reset', option: this.option, invalid: false });
        this.firstTouch = [];
        this.loading$.next(false);
    }
}
