import {NavbarComponent} from './navbar/navbar.component';
import {RouterModule} from '@angular/router';
import {PopoverDropdownComponent} from "./navbar/popover-dropdown/popover-dropdown.component";
import {SharedModule} from "../@shared/shared.module";
import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ThemeService} from './theme.service';
import {ThemeDirective} from './theme.directive';
import {THEMES, ACTIVE_THEME, ThemeOptions} from './symbols';

@NgModule({
    imports: [CommonModule, RouterModule,
        SharedModule],
    providers: [ThemeService],
    declarations: [ThemeDirective, NavbarComponent, PopoverDropdownComponent],
    exports: [ThemeDirective, NavbarComponent]
})
export class ThemeModule {
    static forRoot(options: ThemeOptions): ModuleWithProviders<ThemeModule> {
        return {
            ngModule: ThemeModule,
            providers: [
                {
                    provide: THEMES,
                    useValue: options.themes
                },
                {
                    provide: ACTIVE_THEME,
                    useValue: options.active
                }
            ]
        };
    }
}
