import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable()
export class RouteReuseService implements RouteReuseStrategy {
    storedRouteHandles = new Map<string, DetachedRouteHandle>();

    shouldDetach(route: ActivatedRouteSnapshot | any): boolean {
        return route.data.reuseRoute;
    }

    store(route: ActivatedRouteSnapshot | any, handle: DetachedRouteHandle | any): void {
        if (route.data.reuseRoute) {
            this.storedRouteHandles.set(route.routeConfig.path, handle);
        } else {
            this.storedRouteHandles.set(route.routeConfig.path, {});
        }
    }

    shouldAttach(route: ActivatedRouteSnapshot | any): boolean {
        return this.storedRouteHandles.has(route.routeConfig.path);
    }

    retrieve(route: ActivatedRouteSnapshot | any): DetachedRouteHandle | any {
        return this.storedRouteHandles.get(route.routeConfig.path);
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }
}
