import { Component, OnDestroy, OnInit } from '@angular/core';
import { MyDoctor } from '@core/_interfaces';
import { Subscription, switchMap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PagesService } from '../../pages.service';
import { Store } from '@ngrx/store';
import { ApiListService } from '@core/_services';
import { NoDoctorDeclarationPopupComponent } from '../../../@shared/components/no-doctor-declaration-popup/no-doctor-declaration-popup.component';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-closed-render-declaration',
    templateUrl: './closed-render-declaration.component.html',
    styleUrls: ['./closed-render-declaration.component.scss'],
})
export class ClosedRenderDeclarationComponent implements OnInit, OnDestroy {
    declaration = '';
    data: MyDoctor | undefined;
    id: number | undefined;
    reload$: Subscription | undefined;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private pagesService: PagesService,
        private store: Store<{ page: string }>,
        private apiListService: ApiListService,
    ) {}

    ngOnInit() {
        this.reload$ = this.store
            .select(store => store.page)
            .subscribe(async () => {
                const id = +(this.route?.snapshot?.paramMap?.get('id') || 0);
                const instanceId = +(this.route?.snapshot?.paramMap?.get('instanceId') || 0);

                this.pagesService
                    .getSharedPayload()
                    .pipe(
                        first(),
                        switchMap(sharedPayload => {
                            return this.apiListService.getMyDoctorDeclaration(id, instanceId, sharedPayload.personId);
                        }),
                    )
                    .subscribe(data => {
                        if (data) {
                            this.declaration = data;
                        } else {
                            const downloadDeclarationBtn = document.querySelector('.declaration__files') as HTMLElement;
                            downloadDeclarationBtn.style.display = 'none';
                            this.dialog
                                .open(NoDoctorDeclarationPopupComponent, {
                                    width: '400px',
                                })
                                .afterClosed()
                                .subscribe(() => {
                                    this.router.navigate(['/pages/my-doctor/']);
                                });
                        }
                    });
            });
    }

    ngOnDestroy(): void {
        this.reload$?.unsubscribe();
    }

    downloadDeclarationPDF() {
        const body = `htmlData=${this.declaration}`;
        this.apiListService.HtmlToPdf(body).subscribe(
            data => {
                const fileName = 'declaration.pdf';
                this.pagesService.downloadBlobFile(data, fileName);
            },
            error => {
                console.log('oops', error.error.text);
            },
        );
    }
}
