import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { Platform } from '@ionic/angular';
import { BackButtonEmitter } from '@ionic/angular/providers/platform';
import { App, AppState } from '@capacitor/app';

@Injectable({
    providedIn: 'root',
})
export class PlatformService {
    public isIosPlatform$ = new BehaviorSubject<boolean>(false);
    public isAndroidPlatform$ = new BehaviorSubject<boolean>(false);
    public isMobileWebPlatform$ = new BehaviorSubject<boolean>(false);
    public isDesktopPlatform$ = new BehaviorSubject<boolean>(false);
    public pausePlatform$ = new Subject<void>();
    public resumePlatform$ = new Subject<void>();
    public readyPlatform$ = new BehaviorSubject<boolean>(false);
    constructor(public platform: Platform) {}

    public init() {
        from(this.platform.ready()).subscribe(() => {
            this.isIosPlatform$.next(!!this.platform.is('ios'));
            this.isAndroidPlatform$.next(!!this.platform.is('android'));
            this.isMobileWebPlatform$.next(!!this.platform.is('mobileweb'));
            this.isDesktopPlatform$.next(!!this.platform.is('desktop'));
            console.log('PlatformService init APP');
            console.log('PlatformService init APP isIosPlatform$', this.isIosPlatform$.value);
            console.log('PlatformService init APP isAndroidPlatform$', this.isAndroidPlatform$.value);
            console.log('PlatformService init APP isMobileWebPlatform$', this.isMobileWebPlatform$.value);
            console.log('PlatformService init APP isDesktopPlatform$', this.isDesktopPlatform$.value);
            this.readyPlatform$.next(true);
            this.platform.pause.subscribe(() => {
                console.log('PlatformService pause');
                this.pausePlatform$.next();
            });
            this.platform.resume.subscribe(() => {
                console.log('PlatformService resume');
                this.resumePlatform$.next();
            });
            // Listen for the app pause event
            App.addListener('appStateChange', (state: AppState) => {
                if (state.isActive) {
                    // App resumed
                    console.log('App resumed');
                    this.resumePlatform$.next();
                } else {
                    // App paused
                    console.log('App paused');
                    this.pausePlatform$.next();
                }
            });
        });
    }

    public backButton(): BackButtonEmitter {
        return this.platform.backButton;
    }
}
