import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiListService, variables } from '@core/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PagesService } from '../../pages.service';
import { DatePipe } from '@angular/common';
import { ReceptionInfo, Referral } from '@core/_interfaces';
import { BehaviorSubject, combineLatest, from, Observable, Subject, Subscription, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AppointmentService } from '../appointment.service';
import { NoFreeTimePopupComponent } from '../no-free-time-popup/no-free-time-popup.component';
import { catchError, filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import {
    MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
    MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { AppointmentPayload } from '@core/_interfaces/appointment-payload.model';
import { StorageService } from '@core/_services/store.service';
import { environment } from '@env/environment';
import { PlatformService } from '@core/_services/platform.service';
import { ModalService } from '@core/_services/modal.service';
import { AutocompleteModalComponent } from '../../../@shared/components/autocomplete-modal/autocomplete-modal.component';

@Component({
    selector: 'app-make-appointment',
    templateUrl: './make-appointment.component.html',
    styleUrls: ['./make-appointment.component.scss'],
    providers: [DatePipe, AppointmentService],
})
export class MakeAppointmentComponent implements OnInit, OnDestroy {
    @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
    public inputAutoComplete!: MatAutocompleteTrigger;
    public isIosPlatform$: Observable<boolean> = this.platform.isIosPlatform$;
    public isDesktopPlatform$: Observable<boolean> = this.platform.isDesktopPlatform$;
    public isAndroidPlatform$: Observable<boolean> = this.platform.isAndroidPlatform$;
    public isMobileWebPlatform$: Observable<boolean> = this.platform.isMobileWebPlatform$;
    public form: FormGroup = this.fb.group({
        service: ['', Validators.required],
        numberReferral: [''],
        isInformedPaid: [false],
    });
    public instanceID!: number;
    public receptionInfo: ReceptionInfo | undefined;
    public availableDaysInIntegers: number[] | undefined = [];
    public specialistId!: string;
    public medOrgId!: string;
    public serviceId!: number;
    public error = '';
    public emptyOption = this.translate.instant('Directions.EmptyOption');
    public reload$: Subscription | undefined;
    public loading$ = new BehaviorSubject<boolean>(true);
    public directions$: Observable<string[]> = this.pagesService.getSharedPayload().pipe(
        switchMap(sharedPayload =>
            this.apiListService
                .getMyDirections({
                    ...sharedPayload,
                    page: 1,
                    pageSize: 100,
                })
                .pipe(
                    map(response => response.data),
                    map(directions =>
                        directions.filter(
                            direction => direction.directionType === 'Referral' && direction.state === 'Нове',
                        ),
                    ),
                    map(directions => directions.map(direction => direction.number)),
                    map(direction => [...new Set(direction)]),
                ),
        ),
    );
    public showPortalNumberReferral$: Observable<boolean> = this.storageService
        .get<any>(variables.instanceConfig)
        .pipe(map(config => config?.showPortalNumberReferral || false));
    public showPaymentAgree$: Observable<boolean> = this.form.controls.numberReferral.valueChanges.pipe(
        map(numberReferral => {
            const visibleAgree: boolean = numberReferral === this.emptyOption;

            if (visibleAgree) {
                this.form.controls.isInformedPaid.setValidators(Validators.requiredTrue);
            } else {
                this.form.controls.isInformedPaid.clearValidators();
            }
            this.form.controls.isInformedPaid.updateValueAndValidity();

            return visibleAgree;
        }),
    );

    private destroyed$: Subject<void> = new Subject<void>();

    constructor(
        private fb: FormBuilder,
        private storageService: StorageService,
        private platform: PlatformService,
        private route: Router,
        private router: Router,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private pagesService: PagesService,
        private datePipe: DatePipe,
        private store: Store<{ page: string }>,
        private translate: TranslateService,
        private appointmentService: AppointmentService,
        private apiListService: ApiListService,
        private modalService: ModalService,
    ) {}

    public ngOnInit() {
        this.store
            .select(store => store.page)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(async () => {
                this.storageService
                    .get<any>(variables.currentInstance)
                    .pipe(first())
                    .subscribe(currentInstance => {
                        const doctorInstanceID = this.activatedRoute.snapshot.paramMap.get('instanceID');
                        if (doctorInstanceID) {
                            this.instanceID = +doctorInstanceID;
                        } else {
                            this.instanceID = currentInstance?.id;
                        }
                        this.specialistId = this.activatedRoute.snapshot.params.specialistId;
                        this.medOrgId = this.activatedRoute.snapshot.params.medOrgId;
                        this.apiListService
                            .getReceptionInfoBySpecialist(this.specialistId, this.medOrgId, this.instanceID)
                            .pipe(
                                catchError(err => {
                                    this.loading$.next(false);

                                    return throwError(err);
                                }),
                            )
                            .subscribe((resp: ReceptionInfo) => {
                                if (resp.firstFree === null) {
                                    this.loading$.next(false);
                                    this.dialog
                                        .open(NoFreeTimePopupComponent, {
                                            width: '400px',
                                        })
                                        .afterClosed()
                                        .pipe(first(), takeUntil(this.destroyed$))
                                        .subscribe(() => {
                                            this.router.navigate(['/pages/appointment']);
                                        });
                                } else {
                                    combineLatest([
                                        from(this.pagesService.addImages([resp], 'imageId')),
                                        this.storageService
                                            .get<any>(variables.instanceConfig)
                                            .pipe(map(config => config?.showPortalNumberReferral || false)),
                                    ])
                                        .pipe(
                                            first(),
                                            catchError(err => {
                                                this.loading$.next(false);

                                                return throwError(err);
                                            }),
                                            takeUntil(this.destroyed$),
                                        )
                                        .subscribe(([val, showPortalNumberReferral]: [ReceptionInfo[], boolean]) => {
                                            this.loading$.next(false);
                                            resp = val[0];
                                            resp?.availableDays?.forEach((item: Date) => {
                                                this.availableDaysInIntegers?.push(new Date(item).getTime());
                                            });
                                            this.receptionInfo = resp;

                                            if (resp.isOutpatient && showPortalNumberReferral) {
                                                this.form.controls.numberReferral.setValidators(Validators.required);
                                                this.form.controls.numberReferral.updateValueAndValidity();
                                            }
                                        });
                                }
                            });
                    });
            });
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
        this.reload$?.unsubscribe();
    }

    public openMobileAutoselect() {
        this.directions$
            .pipe(
                first(),
                map(list => {
                    const resultArray = list?.length ? list : [];
                    resultArray.unshift(this.translate.instant('Directions.EmptyOption'));
                    return resultArray;
                }),
                catchError(() => {
                    return [this.translate.instant('Directions.EmptyOption')];
                }),
                switchMap(list => {
                    return this.modalService.openModalComponent(AutocompleteModalComponent, {
                        list,
                        formData: this.form.getRawValue().numberReferral,
                    });
                }),
                filter(data => data?.data),
                takeUntil(this.destroyed$),
            )
            .subscribe(data => {
                console.log('data result', data);
                this.form.controls.numberReferral.setValue(data?.data);
            });
    }

    public async makeAppointment(form: FormGroup): Promise<void> {
        this.loading$.next(true);

        this.storageService
            .get<any>(variables.currentPerson)
            .pipe()
            .subscribe(currentPerson => {
                const personId = currentPerson?.personId;
                const isTelemedicineConsultation =
                    this.receptionInfo?.services.find(x => x.serviceId === +this.form?.get('service')?.value)
                        ?.serviceCode === 'TelemedicineConsultation';
                const { isInformedPaid, numberReferral } = this.form.value;
                const { date, time, freeInterval } = form.value;
                let payload: AppointmentPayload = {
                    instanceId: this.instanceID,
                    personId,
                    medicalOrgId: this.medOrgId,
                    serviceId: this.form?.get('service')?.value,
                    specialistId: this.specialistId,
                    visitDate: date,
                    intervalNumber: freeInterval,
                    isTelemedicineConsultation,
                    numberReferral,
                };
                if (isInformedPaid && numberReferral === this.emptyOption) {
                    payload = { ...payload, isInformedPaid };
                }
                this.apiListService
                    .createAppointment(payload)
                    .pipe(
                        first(),
                        catchError(err => {
                            this.loading$.next(false);
                            this.appointmentService.makeAppointmentError(err).then((e: string) => {
                                this.error = e;
                            });

                            return throwError(err);
                        }),
                        takeUntil(this.destroyed$),
                    )
                    .subscribe(() => {
                        this.loading$.next(false);
                        this.appointmentService.successMakeAppointment({
                            time,
                            date,
                            header: `${this.translate.instant('scheduled-for-doctors-appointment')}<br>${
                                this.receptionInfo?.fullName
                            }`,
                        });
                    });
            });
    }

    public selectedDirection(event: MatAutocompleteSelectedEvent): void {
        if (event.option.value === this.emptyOption) {
            this.form.controls.isInformedPaid.setValue(false);
        }
        this.form.controls.numberReferral.setValue(event.option.value);
    }

    public openPanel(): void {
        this.inputAutoComplete.openPanel();
    }
}
