import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiListService, phoneMask, phoneValidators, variables } from '@core/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, from, Observable, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PagesService } from '../../pages.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppointmentService } from '../appointment.service';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { AppointmentPayload } from '@core/_interfaces/appointment-payload.model';
import { filterNumbersFromString } from '@core/_utils/numbers-from-string';
import { StorageService } from '@core/_services/store.service';

@Component({
    selector: 'app-make-appointment-service',
    templateUrl: './make-appointment-service.component.html',
    styleUrls: ['./make-appointment-service.component.scss'],
    providers: [AppointmentService],
})
export class MakeAppointmentServiceComponent implements OnInit, OnDestroy {
    @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
    public inputAutoComplete!: MatAutocompleteTrigger;
    public info: any;
    public availableDaysInIntegers: number[] | undefined;
    public medOrgId!: string;
    public instanceID!: string;
    public serviceId!: string;
    public serviceIdModel!: number;
    public appointmentCovidImmunization = false;
    public serviceName: string | undefined;
    public isNSZUIP = false;
    public form: FormGroup = this.fb.group({
        numberReferral: [''],
        isInformedPaid: [false],
    });
    public phoneMask = phoneMask;
    public submitted = false;
    public error = '';
    public emptyOption = this.translate.instant('Directions.EmptyOption');
    public reload$: Subscription | undefined;
    public loading$ = new BehaviorSubject<boolean>(true);
    public presentPage = true;
    public directions$: Observable<string[]> = this.pagesService.getSharedPayload().pipe(
        switchMap(sharedPayload =>
            this.apiListService
                .getMyDirections({
                    ...sharedPayload,
                    page: 1,
                    pageSize: 100,
                })
                .pipe(
                    map(response => response.data),
                    map(directions =>
                        directions.filter(
                            direction => direction.directionType === 'Referral' && direction.state === 'Нове',
                        ),
                    ),
                    map(directions => directions.map(direction => direction.number)),
                    map(direction => [...new Set(direction)]),
                ),
        ),
    );
    public showPortalNumberReferral$: Observable<boolean> = this.storageService
        .get<any>(variables.instanceConfig)
        .pipe(map(config => config?.showPortalNumberReferral || false));

    public showPaymentAgree$: Observable<boolean> = this.form.controls.numberReferral.valueChanges.pipe(
        map(numberReferral => {
            const visibleAgree: boolean | undefined = numberReferral === this.emptyOption;

            if (visibleAgree) {
                this.form.controls.isInformedPaid.setValidators(Validators.requiredTrue);
            } else {
                this.form.controls.isInformedPaid.clearValidators();
            }
            this.form.controls.isInformedPaid.updateValueAndValidity();

            return visibleAgree;
        }),
    );

    private destroyed$: Subject<void> = new Subject<void>();

    constructor(
        private storageService: StorageService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private router: Router,
        private fb: FormBuilder,
        private alertController: AlertController,
        private store: Store<{ page: string }>,
        private translate: TranslateService,
        private pagesService: PagesService,
        private appointmentService: AppointmentService,
        private apiListService: ApiListService,
    ) {}

    public ngOnInit(): void {
        this.reload$ = this.store
            .select(store => store.page)
            .subscribe(() => {
                this.getAppointmentInfo();
            });
    }

    public ngOnDestroy(): void {
        this.reload$?.unsubscribe();
    }

    public async getAppointmentInfo(): Promise<void> {
        this.loading$.next(true);
        if (this.router.url.includes('appointmentCovidImmunization')) {
            this.appointmentCovidImmunization = true;
            const { ehealhLegalEntityID, ehealthDivisionID } = this.route.snapshot.params;

            this.storageService
                .get<any>(variables.currentInstance)
                .pipe(
                    first(),
                    switchMap(currentInstance => {
                        this.instanceID = currentInstance?.id;
                        return this.apiListService.getReceptionInfoCovidImmunization(
                            ehealhLegalEntityID,
                            ehealthDivisionID,
                        );
                    }),
                )
                .subscribe(async (resp: any) => {
                    this.loading$.next(false);
                    if (!resp?.services) {
                        const alert = await this.alertController.create({
                            header: this.translate.instant('no-need-covid'),
                            backdropDismiss: false,
                            cssClass: 'no-appointment',
                        });
                        await alert.present();

                        return;
                    }
                    this.isNSZUIP = resp?.isNSZUIP;
                    this.info = resp;
                    this.presentPage = resp?.services?.length > 0;
                    this.medOrgId = resp?.medicalOrgId;
                    if (resp.services?.length) {
                        this.serviceIdModel = resp.services[0]?.serviceId;
                    }
                    if (this.isNSZUIP) {
                        this.form.addControl('name', new FormControl('', Validators.required));
                        this.form.addControl(
                            'phone',
                            new FormControl('', Validators.compose([Validators.required, phoneValidators])),
                        );
                        this.form.controls.numberReferral.clearValidators();
                        this.form.controls.isInformedPaid.clearValidators();
                        this.form.updateValueAndValidity();
                    }
                });
        } else {
            this.medOrgId = this.route.snapshot.params.medOrgId;
            this.serviceId = this.route.snapshot.params.serviceId;
            this.serviceName = this.route.snapshot.params.serviceName;

            this.storageService
                .get<any>(variables.currentInstance)
                .pipe(
                    first(),
                    switchMap(currentInstance => {
                        this.instanceID = currentInstance?.id;

                        return combineLatest([
                            this.apiListService.getReceptionInfoByService(
                                this.instanceID,
                                this.serviceId,
                                this.medOrgId,
                            ),
                            this.storageService
                                .get<any>(variables.instanceConfig)
                                .pipe(map(config => config?.showPortalNumberReferral || false)),
                        ]);
                    }),
                    takeUntil(this.destroyed$),
                )
                .subscribe(([resp, showPortalNumberReferral]: [any, boolean]) => {
                    this.loading$.next(false);
                    this.presentPage = resp?.serviceID > 0;
                    this.info = resp;

                    if (resp.isOutpatient && showPortalNumberReferral) {
                        this.form.controls.numberReferral.setValidators(Validators.required);
                        this.form.controls.numberReferral.updateValueAndValidity();
                    }

                    resp?.availableDays?.forEach((item: any) => {
                        this.availableDaysInIntegers?.push(new Date(item).getTime());
                    });
                });
        }
    }

    public async makeAppointment(form: FormGroup): Promise<void> {
        if (this.form.valid) {
            this.storageService
                .get<any>(variables.currentPerson)
                .pipe(first())
                .subscribe(currentPerson => {
                    const personId = currentPerson?.personId;
                    const { date, time, freeInterval } = form.value;
                    const { numberReferral, isInformedPaid } = this.form.value;
                    let sharedRequestPayload: AppointmentPayload = {
                        instanceId: this.info?.instanceId || this.instanceID,
                        personId,
                        medicalOrgId: this.medOrgId,
                        serviceId: this.serviceIdModel || this.info?.serviceID,
                        visitDate: date,
                        intervalNumber: freeInterval,
                    };
                    this.submitted = true;
                    if (this.form.get('name') && this.form.get('phone')) {
                        this.makeRequest(
                            {
                                ...sharedRequestPayload,
                                isOperator: true,
                                patientDataByOperator:
                                    this.form.get('name')?.value +
                                    filterNumbersFromString(this.form.get('phone')?.value),
                            },
                            date,
                            time,
                        );
                    } else {
                        sharedRequestPayload = { ...sharedRequestPayload, numberReferral };
                        if (isInformedPaid && numberReferral === this.emptyOption) {
                            sharedRequestPayload = { ...sharedRequestPayload, isInformedPaid };
                        }

                        this.makeRequest(sharedRequestPayload, date, time);
                    }
                });
        }
    }

    public makeRequest(payload: AppointmentPayload, date: string, time: string): void {
        this.loading$.next(true);

        this.apiListService.createAppointment(payload).subscribe(
            () => {
                this.loading$.next(false);
                let serviceName = this.serviceName;
                if (!serviceName) {
                    serviceName = this.info?.services?.find(
                        (e: any) => e.serviceId === Number(this.serviceIdModel),
                    )?.serviceName;
                }
                this.appointmentService.successMakeAppointment({
                    time,
                    date,
                    header: !this.isNSZUIP
                        ? `${this.translate.instant('made-appointment')}:<br>${
                              this.info?.serviceName || this.translate.instant('covid-vaccination')
                          }`
                        : `${this.translate.instant('patient')} ${this.form?.get('name')?.value} <br>
                        ${this.translate.instant('tel')}: ${filterNumbersFromString(this.form?.get('phone')?.value)}<br>
                       ${this.translate.instant('appointment-for-what')}:
                        ${serviceName || this.serviceName}`,
                });
            },
            (err: HttpErrorResponse) => {
                this.loading$.next(false);
                this.appointmentService.makeAppointmentError(err).then((e: string) => {
                    this.error = e;
                });
            },
        );
    }

    public selectedDirection(event: MatAutocompleteSelectedEvent): void {
        if (event.option.value === this.emptyOption) {
            this.form.controls.isInformedPaid.setValue(false);
        }
        this.form.controls.numberReferral.setValue(event.option.value);
    }

    public openPanel(): void {
        this.inputAutoComplete.openPanel();
    }
}
