import { Component, Input, OnInit, ViewChild, EventEmitter, Output, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import {
    dateToStr,
    SearchDocListInterface,
    SearchOrgListInterface,
    strToDate,
    ValidateDateFromNow,
} from '@core/_services';
import { PopoverController } from '@ionic/angular';
import { DateLookupComponent } from './date-lookup/date-lookup.component';
import { prepareSearchForm } from '../../../pages/pages.service';
import { compareAsc } from 'date-fns';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, first } from 'rxjs/operators';

@Component({
    selector: 'app-header-search',
    templateUrl: './header-search.component.html',
    styleUrls: ['./header-search.component.scss'],
})
export class HeaderSearchComponent implements OnInit {
    @Input() header: string | undefined;
    @Output() searchForm = new EventEmitter<FormGroup['value']>();
    @ViewChild('clickHoverMenuTrigger') clickHoverMenuTrigger: MatMenuTrigger | undefined;
    form: FormGroup | undefined;
    width: number | undefined | null;
    toggle = false;
    lookupError = false;
    @Input() public loading = false;

    constructor(private fb: FormBuilder, public popoverController: PopoverController) {}

    public ngOnInit() {
        this.form = this.fb.group({
            search: [''],
            date: [{ value: '', disabled: false }],
            start: [''],
            end: [''],
        });

        // this.dataFromStorage$?.pipe(
        //     distinctUntilChanged()
        // )
        //     .subscribe( async (val: any) => {
        //         console.log(val?.filterText);
        //         this.data = val;
        //         // this.form?.get('search')?.setValue(val?.filterText);
        //         // console.log( this.form?.get('search')?.value);
        //         // const f = await prepareSearchForm(this.form as FormGroup);
        //         // console.log(f);
        //         // this.searchForm.emit({...f});
        //     });
        // console.log(this.data);
        this.form.get('end')?.valueChanges.subscribe(end => {
            const start = this.form?.get('start')?.value as string;
            if (start) {
                this.setDate(dateToStr(new Date(start)) + ' - ' + dateToStr(new Date(end))); // Пересмотри
            }
        });
        this.form.get('date')?.valueChanges.subscribe(date => {
            console.log(date);
            const d = date?.split(' - '); // OK
            if (d) {
                // !! => true !!false => false
                this.lookupError = !!(
                    ValidateDateFromNow(new FormControl({ birthday: d[0] }), true) ||
                    ValidateDateFromNow(new FormControl({ birthday: d[1] }), true)
                );
                if (d[0] && d[1]) {
                    const startDate = strToDate(d[0]);
                    const endDate = strToDate(d[1]);
                    if (compareAsc(startDate, endDate) === 1) {
                        this.lookupError = true;
                    }
                }
            } else {
                this.lookupError = false;
            }
        });
        this.form.valueChanges.subscribe(formValue => {
            console.log('123');
            this.submit(false);
        });
    }

    setDate(val: string) {
        this.form?.get('date')?.setValue(val);
    }

    async submit(fromButton: boolean) {
        if (!this.lookupError) {
            const f = await prepareSearchForm(this.form as FormGroup);
            console.log(f);
            this.searchForm.emit({ ...f, fromButton });
        }
    }

    async clearFormAndReset() {
        this.form?.reset();
        const f = await prepareSearchForm(this.form as FormGroup);
        this.searchForm.emit({ ...f, reset: true });
    }

    async openDateLookup(ev: PointerEvent) {
        const popover = await this.popoverController.create({
            component: DateLookupComponent,
            cssClass: 'date-lookup-popover',
            event: ev,
            translucent: true,
        });
        await popover.present();
        popover.onDidDismiss().then((form: { data?: FormGroup; role?: string }) => {
            const { date, start, end } = (form.data as FormGroup)?.value;
            this.form?.patchValue({
                date,
                start,
                end,
            });
        });
    }
}

export interface SearchData {
    dateFrom?: string;
    dateTo?: string;
    filterText: string;
    fromButton: boolean;
}
