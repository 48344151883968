import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
    ApiListService,
    AuthenticationService,
    passwordValidators,
    phoneMask,
    phoneValidators,
    variables,
} from '@core/_services';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, of, Subject, switchMap } from 'rxjs';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Store } from '@ngrx/store';
import { ReloadAction } from '@core/_effecs/reload/reload.actions';
import { ThemeService } from '../../@theme/theme.service';
import { catchError, debounceTime, filter, first, map, takeUntil, tap } from 'rxjs/operators';
import { Instances } from '@core/_interfaces';
import { filterNumbersFromString } from '@core/_utils/numbers-from-string';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { NotificationService } from '@core/_services/notification.service';
import { FcmService } from '@core/_services/fcm.service';
import { StorageService } from '@core/_services/store.service';
import { PlatformService } from '@core/_services/platform.service';
import { IonicModule, ModalController } from '@ionic/angular';
import { ModalService } from '@core/_services/modal.service';
import { RegistrationModalFirstComponent } from '../registration/registration-modal-first/registration-modal-first.component';
import { ResetPasswordModalComponent } from '../reset-password-modal/reset-password-modal.component';
import { Credentials } from 'capacitor-native-biometric';
import { BiometricService } from '@core/_services/biometric.service';
@Component({
    standalone: true,
    selector: 'app-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss'],
    providers: [AsyncPipe, MaskPipe],
    imports: [ReactiveFormsModule, TranslateModule, NgxMaskModule, NgClass, IonicModule, NgIf, AsyncPipe],
})
export class LoginModalComponent implements OnInit, OnDestroy {
    @Input() public resultRegister: { option: string } | null = null;
    @Input() public url: string | null = null;
    @Input() public registerData: { callInRegisterLink: boolean; successfulAuth: boolean; login: string } | null = null;
    public form: FormGroup | undefined;
    public visiblePassword = false;
    public submitted = false;
    public phoneMask = phoneMask;
    public redirectAfterClose = false;
    public error = '';
    public loading$ = new Subject<boolean>();
    public mobilePreventClose = false;
    public afterRegistration = '';
    public prefix = '(0';
    public localLogin = '';
    public credential$ = this.biometricService.getCredential().pipe(
        tap(credentials => {
            if (credentials) {
                this.openBiometric(credentials as Credentials);
            }
        }),
    );

    private destroyed$: Subject<void> = new Subject<void>();

    constructor(
        private fb: FormBuilder,
        private authenticationService: AuthenticationService,
        private storageService: StorageService,
        private router: Router,
        private translate: TranslateService,
        private pl: PlatformService,
        private store: Store<{ page: string }>,
        private themeService: ThemeService,
        private apiListService: ApiListService,
        private mask: MaskPipe,
        private notificationService: NotificationService,
        private fcmService: FcmService,
        private cdr: ChangeDetectorRef,
        private modalCtrl: ModalController,
        private modalService: ModalService,
        private biometricService: BiometricService,
    ) {}

    public ngOnInit() {
        console.log('LOGIN MODAL', this.resultRegister);
        if (this.resultRegister) {
            this.initData();
            this.redirectAfterClose = true;
            if (this.resultRegister?.option === 'mis') {
                this.afterRegistration = `${this.translate.instant(
                    'greetings-with-registration',
                )} <br> ${this.translate.instant('please-wait-for-and-hour')}`;
            } else {
                this.afterRegistration = this.translate.instant('greetings-with-registration');
            }
            setTimeout(() => {
                this.afterRegistration = '';
            }, 10 * 1000);
        } else {
            this.initData();
        }
    }
    public ngOnDestroy() {
        console.log('login destroy');
        this.destroyed$.next();
        this.destroyed$.complete();
        this.cdr.detectChanges();
    }

    public initData() {
        this.afterRegistration = '';
        this.mobilePreventClose =
            (this.pl.isIosPlatform$.value || this.pl.isAndroidPlatform$.value) && !this.pl.isMobileWebPlatform$.value;
        this.mobilePreventClose = this.themeService.getActiveTheme()?.name === 'jeremiah';
        this.storageService
            .get<string>(variables.login)
            .pipe(first(), takeUntil(this.destroyed$))
            .subscribe(storageLogin => {
                const login = this.registerData && this.registerData?.login ? this.registerData.login : storageLogin;
                this.localLogin = storageLogin;
                this.form = this.fb.group({
                    login: [login ? this.prefix + this.mask.transform(login.slice(1), phoneMask) : '', phoneValidators],
                    password: ['', passwordValidators],
                });
            });
    }

    public async submit() {
        this.submitted = true;
        if (this.form?.valid) {
            this.loading$.next(true);
            const login = filterNumbersFromString(this.form.get('login')?.value);
            const loginPayload = {
                login,
                password: this.form.get('password')?.value,
            };
            combineLatest([
                this.storageService.remove(variables.currentUser),
                this.storageService.remove(variables.currentPerson),
            ])
                .pipe(
                    switchMap(() =>
                        this.authenticationService.login(loginPayload).pipe(
                            first(),
                            catchError(async err => {
                                this.loading$.next(false);
                                this.error = await this.authenticationService.displayError(err, this.translate);

                                return null;
                            }),
                            filter(user => !!user),
                            switchMap((user: any) => {
                                this.loading$.next(false);
                                return this.authenticationService.setUser(user);
                            }),
                            switchMap(() => {
                                return this.storageService.get(variables.currentInstance).pipe(
                                    switchMap(currentInstance => {
                                        if (!currentInstance) {
                                            return this.storageService.get<any[]>(variables.instance).pipe(
                                                switchMap(instances => {
                                                    return this.apiListService.getMyPersonsRequest().pipe(
                                                        debounceTime(2000),
                                                        switchMap(persons => {
                                                            const currentInstanceName = persons
                                                                ? persons[0]?.instances[0]
                                                                : null;
                                                            if (currentInstanceName) {
                                                                const currentInstance = instances.find(
                                                                    (instance: Instances) =>
                                                                        instance.name === currentInstanceName,
                                                                );
                                                                if (currentInstance) {
                                                                    return this.authenticationService.manageCurrentInstance(
                                                                        currentInstance,
                                                                    );
                                                                }
                                                            }
                                                            return of(null);
                                                        }),
                                                    );
                                                }),
                                            );
                                        }

                                        return of(null);
                                    }),
                                    switchMap(() => {
                                        if (
                                            (this.pl.isIosPlatform$.value || this.pl.isAndroidPlatform$.value) &&
                                            !this.pl.isMobileWebPlatform$.value
                                        ) {
                                            this.fcmService.initPush(true);
                                            return this.fcmService.notificationToken$.pipe(
                                                first(),
                                                switchMap(() =>
                                                    this.storageService.get<boolean>(variables.isBiometric).pipe(
                                                        switchMap((isBiometric: boolean) => {
                                                            return isBiometric === undefined ||
                                                                isBiometric === null ||
                                                                this.localLogin !== login
                                                                ? this.biometricService
                                                                      .openBiometric(
                                                                          'auth.biometric.save-biometric-reason-ios',
                                                                          'auth.biometric.save-biometric-title-android',
                                                                          'auth.biometric.subtitle-android',
                                                                          'auth.biometric.save-biometric-description-android',
                                                                      )
                                                                      .pipe(
                                                                          catchError(() => of(false)),
                                                                          switchMap(result => {
                                                                              return result !== false
                                                                                  ? this.biometricService
                                                                                        .setCredential(
                                                                                            login,
                                                                                            loginPayload?.password,
                                                                                        )
                                                                                        .pipe(
                                                                                            switchMap(() => {
                                                                                                return this.storageService.set(
                                                                                                    variables.isBiometric,
                                                                                                    true,
                                                                                                );
                                                                                            }),
                                                                                        )
                                                                                  : of(null).pipe(
                                                                                        switchMap(() => {
                                                                                            return this.storageService.set(
                                                                                                variables.isBiometric,
                                                                                                false,
                                                                                            );
                                                                                        }),
                                                                                    );
                                                                          }),
                                                                      )
                                                                : of(null);
                                                        }),
                                                    ),
                                                ),
                                            );
                                        } else {
                                            return of(null);
                                        }
                                    }),
                                );
                            }),
                            switchMap(() => this.storageService.set(variables.login, login)),
                            takeUntil(this.destroyed$),
                        ),
                    ),
                )
                .subscribe(() => {
                    this.afterAuthAction();
                });
        }
    }

    public openBiometric(credential: Credentials): void {
        const { username, password } = credential;
        this.biometricService
            .openBiometric()
            .pipe(
                first(),
                filter(isSign => isSign !== false),
                tap(() => {
                    this.form?.patchValue(
                        { login: this.prefix + this.mask.transform(username.slice(1), phoneMask), password },
                        { emitEvent: false },
                    );
                    this.submit().then();
                }),
                takeUntil(this.destroyed$),
            )
            .subscribe();
    }

    private afterAuthAction(): void {
        if (this.url === '/') {
            this.url = '/pages';
        }
        console.log('manageLogout login popup this.data?.url', this.url);
        console.log('manageLogout login popup this.router?.url', this.router.url);
        if (this.fcmService.notificationUrl$.value || this.url) {
            console.log('manageLogout login popup go to this.data?.url 1', this.url);
            this.router.navigate([this.fcmService.notificationUrl$.value || this.url]).then(() => {
                this.fcmService.notificationUrl$.next('');
                this.modalCtrl.dismiss({ successfulAuth: true }, 'confirm').then();
                this.store.dispatch(new ReloadAction(this.router.url));
            });
        } else {
            console.log('manageLogout login popup go to this.data?.url 2', variables.pages);
            this.router.navigate([`/${variables.pages}`]).then(() => {
                this.fcmService.notificationUrl$.next('');
                this.modalCtrl.dismiss({ successfulAuth: true }, 'confirm').then();
                this.store.dispatch(new ReloadAction(this.router.url));
            });
        }
    }

    public resetPassword() {
        this.modalService
            .openModalComponent(ResetPasswordModalComponent, null, 'no-padding-popup')
            .pipe(first())
            .subscribe();
    }

    public reg() {
        this.modalService
            .openModalComponent(RegistrationModalFirstComponent, null, 'no-padding-popup', false)
            .pipe(first())
            .subscribe();
    }

    public pasteLogin(event: any) {
        event.preventDefault();

        const paste = event.clipboardData.getData('text');
        let pastePhone = paste || '';
        if (pastePhone?.length) {
            pastePhone = pastePhone.replace(/[^0-9]/g, '');
            if (pastePhone.length > 9) {
                pastePhone = pastePhone.substring(pastePhone.length - 9);
            }
        }
        this.form?.controls.login.setValue(this.prefix + this.mask.transform(pastePhone, phoneMask));
    }
}
