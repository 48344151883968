import {ActionReducer, createAction, MetaReducer} from '@ngrx/store';
import {SelectInstanceActions} from '../../_effecs/select-instance/select-instance.actions';

export const clear = createAction(
    '[Clear] clear all states'
);

// @ts-ignore
export function ClearAllStates(reducer: ActionReducer): ActionReducer {
    // @ts-ignore
    return (state, action) => {
        if (action != null && action.type === SelectInstanceActions.RESET_INSTANCE) {
            return reducer(undefined);
        }
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer[] = [ClearAllStates]
