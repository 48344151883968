import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { IntervalModelItem, ReceptionInfo } from '@core/_interfaces';

@Injectable()
export class AppointmentDateAndTimeService {
    constructor(private fb: FormBuilder) {}

    initFrom(service: string) {
        return this.fb.group({
            service: [service || ''],
            data: [''],
            time: [''],
            date: [''],
            dateLookup: [''],
            typeOfDay: [''],
            freeInterval: [''],
            specialistId: [''],
        });
    }

    prepareInterval(intervals: IntervalModelItem[], intervalLen: number): Promise<PrepareIntervalInterface> {
        return new Promise((resolve, reject) => {
            const freeIntervals: FreeIntervalsInterface = {
                morning: [],
                day: [],
                evening: [],
            };
            const allIntervalsStrings: string[] = [];
            intervals?.forEach((item: IntervalModelItem) => {
                const all = (item?.intervalNumber as number) * intervalLen;
                const hours = Math.floor(all / 60);
                const minutes = all - hours * 60;
                const prepare = (h: number, m: number) => {
                    return `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}`;
                };
                if (hours < 11) {
                    freeIntervals.morning.push({
                        intervalNumber: prepare(hours, minutes),
                        color: item?.color,
                        message: item?.message,
                    });
                } else if (hours >= 11 && hours < 15) {
                    freeIntervals.day.push({
                        intervalNumber: prepare(hours, minutes),
                        color: item?.color,
                        message: item?.message,
                    });
                } else if (hours >= 15) {
                    freeIntervals.evening.push({
                        intervalNumber: prepare(hours, minutes),
                        color: item?.color,
                        message: item?.message,
                    });
                }
            });
            const payload = [];
            if (freeIntervals?.morning?.length) {
                payload.push(...freeIntervals?.morning.map(el => el.intervalNumber as string));
            }
            if (freeIntervals?.day?.length) {
                payload.push(...freeIntervals?.day.map(el => el.intervalNumber as string));
            }
            if (freeIntervals?.evening?.length) {
                payload.push(...freeIntervals?.evening.map(el => el.intervalNumber as string));
            }
            allIntervalsStrings.push(...payload);
            resolve({ freeIntervals, allIntervalsStrings });
        });
    }
}

export interface AppointmentPayload {
    availableDaysInIntegers: Date[];
    instanceID: number;
    medOrgId: string;
    receptionInfo: ReceptionInfo;
    serviceId: string;
    specialistId: string;
    isNSZUIP?: boolean;
}

export interface FreeIntervalsInterface {
    morning: IntervalModelItem[];
    day: IntervalModelItem[];
    evening: IntervalModelItem[];
}

export interface PrepareIntervalInterface {
    freeIntervals: FreeIntervalsInterface;
    allIntervalsStrings: string[];
}
