import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { compareAsc, format, parse, isValid } from 'date-fns';
import { environment } from '@env/environment';
export * from './api-list.service';
export * from './api.base.service';
export * from './authentication.service';
export * from './getDoctors.service';
export * from './download';
export const AppConfig = environment;
export const year = new Date().getFullYear();
export const initialBarcodeSize = 0.3;
export const dateFormat = 'dd.MM.yyyy'; // Please change at whole project
export const newDate = new Date();
export const prefix = environment.prefixURL;

export function fullWidthRegister(isPDF: string) {
    const pdf = eval(isPDF);
    if (pdf) {
        document.getElementById('sidenav')?.setAttribute('style', 'display: none');
        document.getElementById('navbar')?.setAttribute('style', 'display: none');
        document.getElementById('back-nav')?.setAttribute('style', 'display: none');
    }
}

export const debounceTimeDelay = 500;
export const phoneMask = '00) 000 00 00';
export const prefixPhone = '(0';
export const directionMask = 'AAAA-AAAA-AAAA-AAAA';

export function openLoginPopup() {
    return {
        panelClass: 'no-padding-popup',
        width: '520px',
    };
}

export const variables = {
    currentUser: 'currentUser',
    auth: 'auth',
    pages: 'pages',
    login: 'login',
    codeFromMisLength: 11,
    currentInstance: 'currentInstance',
    instance: 'instance',
    currentPerson: 'currentPerson',
    appointment: 'appointment',
    images: 'images',
    ShowFieldsAppointments: 'ShowFieldsAppointments',
    instanceConfig: 'instanceConfig',
    notificationToken: 'notificationToken',
    isBiometric: 'isBiometric',
    isBiometricsType: 'isBiometricsType',
};

export function datePreparation(date: string): string {
    return format(strToDate(date), 'yyyy.MM.dd');
}

export function emailValidators() {
    const email = /^([a-z0-9.\-!#$%&‘*+—/=?^_`{|}~]{1,20})+@[a-z0-9.-]+\.[a-z]{2,4}/i;

    return Validators.compose([emailValidator(), Validators.required]);
}

export function emailValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
        const v = control?.value as string;
        if (v === null) {
            return null;
        }

        if (v.length > 1) {
            if (/^([a-z0-9.\-!#$%&‘*+—/=?^_`{|}~]{1,20})+@[a-z0-9.-]+\.[a-z]{2,4}/i.test(v)) {
                return null;
            } else {
                return {
                    emailValidatorError: true,
                };
            }
        } else {
            return null;
        }
    };
}

export const InstancePopupConfig = {
    ...openLoginPopup(),
    disableClose: true,
    backdropClass: 'blur-bg',
};

export const phoneValidators = Validators.compose([
    Validators.required,
    Validators.minLength(15),
    Validators.maxLength(15),
]);
export const passwordValidators = Validators.compose([
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(12),
]);
export const minDate = {
    day: 31,
    month: 12,
    year: 1900,
};
export const minDateObject = new Date(minDate.year, minDate.month, minDate.year);

export function ValidateDateFromNow(control: FormControl, skipFromNow: boolean = false) {
    try {
        // skipFromNow => Не делать проверку, что дата больше чем текущая дата
        const date = control.value?.birthday;
        if (date.length === 0) {
            return null;
        }
        const inputDate = strToDate(date);
        if (!isValid(inputDate)) {
            return { ValidateDate: true };
        }
        if (compareAsc(inputDate, minDateObject) === -1) {
            return { ValidateDate: true };
        }
        if (!skipFromNow) {
            if (compareAsc(inputDate, newDate) === 1) {
                return { ValidateDate: true, FromNow: true };
            }
        }
    } catch (e) {}
}

export function dateToStr(date: Date): string {
    return format(date, dateFormat);
}

export function strToDate(date: string) {
    return new Date(parse(date, dateFormat, newDate));
}

export interface RatingInterface {
    header: string;
    makeRating: string;
    placeholder: string;
}

export const ratingStringsOrganization: RatingInterface = {
    header: 'evaluation-of-institution',
    makeRating: 'оцініть лікувальний заклад',
    placeholder: 'Залиште ваш відгук про лікувальний заклад',
};
