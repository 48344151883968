import { FormGroup } from '@angular/forms';

export interface PaginationInterface {
    pageNumber?: number;
    pageSize?: number;
    totalItems?: number;
    itemsPerPage?: number;
    currentPage?: number;
}

export interface AutoCompleteData {
    instanceId: number;
    districtId: number;
    settlementId: number;
    organizationId: number;
    serviceId: number;
    fullName: string;
    option?: string;
}

export interface PaginationConfig {
    itemsPerPage?: number;
    currentPage?: number;
    totalItems?: null | number;
    page?: number;
    pageSize?: number;
    option?: string;
}

export const itemsPerPage = 12;
export const currentPage = 1;

export interface Person {
    personId: number;
    lastName: string;
    firstName: string;
    middleName: string;
    isVerified: boolean;
    sex: number;
}

export interface UserInterface {
    login: string;
    persons: Person[];
    accessToken: string;
    refreshToken: string;
}

export interface RefreshToken {
    login: string;
    persons: {
        id: number;
        lastName: string;
        firstName: string;
        middleName: string;
        isVerified: boolean;
    }[];
    accessToken: string;
    refreshToken: string;
}

export declare type Files = {
    fileId: number;
    fileName: string;
    fileSize: number;
};

export interface Analyze {
    address: string;
    analysisId: number;
    analysisName: string;
    date: Date;
    dateResult: Date;
    direction: {
        directionDate: Date;
        directionId: number;
        directionNumber: string;
        directionOrgId: number;
        directionOrgName: string;
    };
    files: Files[];
    indicators: {
        indicatorName: string;
        isRefferentAlarm: number;
        measurementName: string;
        refText: string;
        value: string;
        valueAccuracy: string;
    }[];
    directionDate: Date;
    directionId: number;
    directionNumber: string;
    directionOrgId: number;
    directionOrgName: string;
    investigationKind: string;
    investigationType: string;
    medicalOrgId: number;
    organizationName: string;
    paymentStatusCode: string;
    rowNum: number;
    totalCount: number;
    contentOfResult: string;
    divisionName: string;
    instanceId: number;
}

export declare type BodyType = {
    page: number;
    pageSize: number;
    instanceId: number;
    personId: number;
    id?: number;
    dateFrom?: Date;
    dateTo?: Date;
    filterText?: string;
};

export interface ReceptionInfo {
    imageID: number;
    divisionName: string;
    organizationName: string;
    address: string;
    firstFree: Date;
    serviceID: number;
    serviceName: string;
    serviceCode: string;
    fullName?: string;
    duration: number;
    notes: string;
    image?: string;
    imageId?: string;
    instanceId?: number;
    availableDays: Date[];
    specialitys: string;
    isOutpatient: boolean;
    services: {
        duration: number;
        notes: string;
        serviceName: string;
        serviceCode: string;
        serviceId: number;
    }[];
}

export declare type AvailableInMonth = Date[];

export interface FreeIntervals {
    intervalLen: number;
    intervals: IntervalModelItem[];
    specialistID: number;
}

export interface IntervalModelItem {
    intervalNumber: number | string;
    color: string;
    message: string;
}

export interface Referral {
    address: string;
    budget: string | null;
    date: Date;
    directionType: string;
    divisionName: string;
    expirationDate: Date | null;
    id: number;
    instanceId: number;
    medicalOrgId: number;
    number: string;
    organizationName: string;
    patientId: number;
    patientInstruction: string | null;
    priority: string | null;
    searchIndex?: null;
    service: string | null;
    specialist: string | null;
    specialitys: string | null;
    state: string | null;
}

export interface Prescription {
    patientId: number;
    id: number;
    medicalOrgId: number;
    divisionName: string;
    organizationName: string;
    address: string;
    date: Date;
    expirationDate: string;
    budget: string;
    number: string;
    specialist: string;
    specialitys: string;
    isEhealth: boolean;
    state: string;
    searchIndex: string;
    medications?: Medication[];
}

export interface Medication {
    medicationName: string;
    internationalName?: string;
    name?: string;
    duration?: string;
    takeFrom: string;
    takeTo: string;
    receptionQuantity: string;
    receptionFrequency: string;
    quantityInRecipe: string;
    recommendation: string;
    searchIndex?: string;
}

export interface Immunization {
    address: string;
    date: Date;
    divisionName: string;
    dose: string;
    immunizationId: number;
    instanceId: number;
    medicalOrgId: number;
    nextDate: null;
    organizationName: string;
    patientId: number;
    reaction: string | null;
    reactionDescription: string | null;
    specialistName: string;
    vaccinationDiseaseName: string;
    vaccinationKindName: string;
    vaccineName: string;
}

export const mimeTypeArray = {
    doc: 'application/msword',
    html: 'text/html',
    jpg: 'image/jpeg',
    json: 'application/json',
    pdf: 'application/pdf',
    rar: 'application/x-rar-compressed',
    ttf: 'font/ttf',
    xml: 'application/xml',
    zip: 'application/x-zip-compressed',
    png: 'image/png',
};

export interface MyResearch {
    address: string;
    conclusion: string;
    date: Date;
    diagnosis: string;
    files: Files[];
    // instrumentalResearchArea: string;
    instrumentalResearchArea: {
        UltrasoundResearch: false;
        InstrumentalResearch: true;
    };
    medicalOrgId: number;
    organizationName: string;
    recommendation: string;
    researchId: number;
    researchKind: string;
    researchType: string;
    resultFileName: string;
    rowNum: number;
    specialistFullName: string;
    stateName: string;
    totalCount: number;
    instanceId: number;
    divisionName: string;
}

export interface MyDoctor {
    organizationName: string;
    divisionName: string;
    medicalOrgId: number;
    address: string;
    state: string;
    specialist: string;
    specialistFullName: string;
    specialistId: number;
    specialitys: string;
    image?: string;
    imageID: number;
    stateCode: string;
    startDate: Date;
    endDate: Date;
    isActual: boolean;
    number: string;
    content: string;
    id: number;
    instanceId: number;
}

export declare type PayloadType = { [key: string]: number | string | null };
export declare type IonicRefresherType = {
    [key: string]: any;
    target: {
        complete: () => void;
    };
};

export declare type SuccessfulAuthType = {
    successfulAuth: boolean;
};

export declare type DateSubscriptionType = FormGroup | { submitted: boolean };

export interface RegistrationCredential {
    login: string;
    password: string;
    passwordConfirm: string;
}

export interface RegistrationFull extends RegistrationCredential {
    lastName: string;
    firstName: string;
    middleName: string;
    birthday: string;
    sex: string;
    email: string;
    accessGUID?: string;
}

export interface PersonSavePayload {
    birthday: string;
    firstName: string;
    lastName: string;
    email: string;
    middleName: string;
    relationDegree: string;
    sex: string;
    personId: string;
}

export interface Register<T> {
    data: T[];
    totalCount: number;
    page?: number;
    currentPage?: number;
    totalPages?: number;
}

export interface MyConsultations {
    specialistImageId: number | null;
    id: number;
    patientId: number;
    date: Date;
    name: string;
    specialist: string;
    conclusions: string;
    recomendationsPatient: string;
    icpcDiagnosis: string;
    endoscopicExaminationCode: string;
    endoscopicExaminationResults: string;
    performedManipulations: string;
    medications?: Medication[];
    icd: string;
    divisionName: string;
    dateNextVisit: Date | null;
    address: string;
    instanceId: number;
    medicalOrgId: number;
    specialitys: string;
    organizationName: string | null;
    image?: string;
    diagnosisDescription?: string;
}

export interface Appointment {
    specialistId: number;
    medicalOrgId: number;
    fullName: string;
    imageId: number;
    divisionName: string;
    organizationName: string;
    address: string;
    room: string;
    personalRank: number;
    visitDate: Date;
    visitTime: string;
    specialistAcdmTitle: string;
    specialitys: string | null;
    visitNumber: number;
    serviceId: number;
    serviceName: string;
    rowNum: number;
    appointmentId: number;
    totalCount: number;
    instanceId: number;
    isActual: boolean;
    conferenceId: string;
    image: string;
}

export interface Instances {
    id: number;
    misCode: string;
    misRegionId: number;
    name: string;
}

export interface EnvironmentInterface {
    production: boolean;
    webUrl?: string;
    apiUrl: string;
    customClinic?: string;
    showingPages: ShowingPagesInterface;
    covidExternalUrl: string;
    applicationVersion?: string;
    agreement: string;
    prefixURL: string;
    maxMonthForNSZU: number;
    hiddenInstances: string[];
    youtubeURL: string;
    androidApp?: string;
    iosApp?: string;
    androidId?: string;
    iosId?: string;
}

export interface ShowingPagesInterface {
    appointment: boolean;
    myAppointments: boolean;
    visits: boolean;
    immunization: boolean;
    prescription: boolean;
    analyzes: boolean;
    instrumentalResearch: boolean;
    referral: boolean;
    telemedicine: boolean;
    myDoctor: boolean;
    events: boolean;
    chat: boolean;
    notifications?: boolean;
}

export interface SharedPayload {
    personId: number;
    instanceId: number;
}
