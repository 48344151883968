import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { dateFormat, Download } from '@core/_services';
import { PagesService } from '../../../pages/pages.service';
import { Analyze, currentPage, itemsPerPage, PaginationConfig, Register } from '@core/_interfaces';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
    selector: 'app-analyze-card',
    templateUrl: './analyze-card.component.html',
    styleUrls: ['./analyze-card.component.scss'],
    providers: [Download],
})
export class AnalyzeCardComponent implements OnChanges {
    @Input() public clickable: boolean | undefined;
    @Input() public cssClass: boolean | undefined;
    @Input() public data: Register<Analyze> | undefined;
    @Output() public configEvent = new EventEmitter<PaginationConfig>();
    @Input() public searchForm$: Subject<string> | undefined;
    public dateFormat = dateFormat;
    public config: PaginationConfig = {
        itemsPerPage,
        currentPage,
    };
    public showPaidAnalysis: boolean | undefined;
    public loading$ = this.pagesService.loadingFile$;

    constructor(public download: Download, public pagesService: PagesService) {}

    public ngOnChanges(changes: SimpleChanges): void {
        (this.config as PaginationConfig).totalItems = changes?.data?.currentValue?.totalCount;
        if (this.data?.data) {
            this.data.data = changes?.data?.currentValue?.data;
        } else {
            (this.data as any) = { ...this.data, data: changes?.data?.currentValue?.data };
        }

        if (changes?.data?.currentValue?.currentPage) {
            (this.config as PaginationConfig).currentPage = changes?.data?.currentValue?.currentPage;
        }
    }

    public changePage() {
        this.configEvent.emit(this.config);
        this.pagesService.scrollToTop('app-analyzes');
    }

    public getFile(id: number, fileName: string, instanceId: number, skipRequest?: string | undefined) {
        this.pagesService.getFile(id, fileName, instanceId, skipRequest);
    }
}
