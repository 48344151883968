import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RatingPopupComponent } from './rating-popup/rating-popup.component';
import { icons } from './star.service';
import { AuthenticationService, RatingInterface } from '@core/_services';
import { UnauthorizedRatingPopupComponent } from './unauthorized-rating-popup/unauthorized-rating-popup.component';
import { catchError, first, switchMap, tap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';

@Component({
    selector: 'app-stars',
    templateUrl: './stars.component.html',
    styleUrls: ['./stars.component.scss'],
})
export class StarsComponent {
    @Input() ratingStrings: RatingInterface | undefined;
    icons = icons;
    initialStars = 0;
    reload = true;

    constructor(public dialog: MatDialog, private authenticationService: AuthenticationService) {}

    ratingOutput(star: number) {
        const successfullyAuth = () => {
            this.reload = false;
            this.dialog
                .open(RatingPopupComponent, {
                    width: '570px',
                    data: { star, ratingStrings: this.ratingStrings },
                })
                .afterClosed()
                .subscribe(val => {
                    this.initialStars = val;
                    this.reload = true;
                });
        };
        const failedAuth = () => {
            this.dialog
                .open(UnauthorizedRatingPopupComponent, {
                    width: '450px',
                })
                .afterClosed()
                .subscribe(isSuccessfullyAuth => {
                    if (isSuccessfullyAuth?.skip) {
                        return;
                    }
                    if (isSuccessfullyAuth?.successfulAuth) {
                        successfullyAuth();
                    } else {
                        failedAuth();
                    }
                });
        };
        this.authenticationService
            .hearthBeat()
            .pipe(
                catchError(err => {
                    failedAuth();
                    return throwError(err);
                }),
            )
            .subscribe(() => {
                successfullyAuth();
            });
    }
}
