import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiListService } from '@core/_services';
import { IonicRefresherType, MyDoctor, Register } from '@core/_interfaces';
import { Subscription, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PagesService } from '../../pages.service';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-render-declaration',
    templateUrl: './render-declaration.component.html',
    styleUrls: ['./render-declaration.component.scss'],
})
export class RenderDeclarationComponent implements OnInit, OnDestroy {
    declaration = '';
    data: Register<MyDoctor> | undefined;
    id: number | undefined;
    reload$: Subscription | undefined;

    constructor(
        private route: ActivatedRoute,
        private pagesService: PagesService,
        private store: Store<{ page: string }>,
        private apiListService: ApiListService,
    ) {}

    ngOnInit() {
        this.reload$ = this.store
            .select(store => store.page)
            .subscribe(async () => {
                const id = +(this.route?.snapshot?.paramMap?.get('id') || 0);
                const instanceId = +(this.route?.snapshot?.paramMap?.get('instanceId') || 0);
                this.pagesService
                    .getSharedPayload()
                    .pipe(
                        first(),
                        switchMap(sharedPayload => {
                            return this.apiListService.getMyDoctorDeclaration(id, instanceId, sharedPayload.personId);
                        }),
                    )
                    .subscribe(data => {
                        this.declaration = data;
                    });
            });
    }

    ngOnDestroy() {
        this.reload$?.unsubscribe();
    }
}
