import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
    AppointmentPopupComponent,
    AppointmentPopupInterface,
} from '../../@shared/components/appointment-popup/appointment-popup.component';
import { Router } from '@angular/router';

@Injectable()
export class AppointmentService {
    constructor(
        private fb: FormBuilder,
        private translate: TranslateService,
        private dialog: MatDialog,
        private router: Router,
    ) {}

    __initForm() {
        return this.fb.group({
            service: [''],
            time: [''],
            date: [''],
            dateLookup: [''],
            typeOfDay: [''],
        });
    }

    makeAppointmentError(err: HttpErrorResponse): Promise<string> {
        return new Promise(resolve => {
            let error = '';
            if (err?.error?.errorText[0].includes('Validation interval error')) {
                error = this.translate.instant('errors.unable-to-confirm-appointment');
                resolve(error);
            } else if (err?.error?.errorText[0].includes('Free specialist not found')) {
                error = this.translate.instant('errors.no-free-specialist');
                resolve(error);
            } else {
                resolve('');
            }
        });
    }

    successMakeAppointment(data: AppointmentPopupInterface) {
        this.dialog
            .open(AppointmentPopupComponent, {
                width: '750px',
                backdropClass: 'blur-bg',
                data,
            })
            .afterClosed()
            .subscribe(() => {
                this.router.navigate(['/pages/my-appointments']);
            });
    }
}
