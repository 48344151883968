import { Component, OnInit } from '@angular/core';
import { AuthenticationService, variables } from '@core/_services';
import { Platform, PopoverController } from '@ionic/angular';
import { Person, UserInterface } from '@core/_interfaces';
import { Router } from '@angular/router';
import { PagesService } from '../../../pages/pages.service';
import { Store } from '@ngrx/store';
import { ReloadAction } from '@core/_effecs/reload/reload.actions';
import { StorageService } from '@core/_services/store.service';
import { first } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { PlatformService } from '@core/_services/platform.service';

@Component({
    selector: 'app-popover-dropdown',
    templateUrl: './popover-dropdown.component.html',
    styleUrls: ['./popover-dropdown.component.scss'],
    providers: [],
})
export class PopoverDropdownComponent implements OnInit {
    public currentUser: UserInterface | undefined;
    public currentPerson: Person | undefined;
    public users: Person[] | undefined;
    public isMobileDevice =
        (this.pl.isAndroidPlatform$.value || this.pl.isIosPlatform$.value) && !this.pl.isMobileWebPlatform$.value;
    constructor(
        private authenticationService: AuthenticationService,
        private popoverController: PopoverController,
        private storageService: StorageService,
        private router: Router,
        private pagesService: PagesService,
        private store: Store,
        private pl: PlatformService,
    ) {}

    public ngOnInit(): void {
        combineLatest([this.authenticationService.getUser(), this.authenticationService.getPerson()])
            .pipe(first())
            .subscribe(([currentUser, currentPerson]) => {
                this.currentUser = currentUser;
                this.currentPerson = currentPerson;
                this.users = this.currentUser?.persons?.filter((e: Person) => {
                    return e.personId !== this.currentPerson?.personId;
                });
                console.log('POPOVER PERSON currentUser', currentUser);
                console.log('POPOVER PERSON currentPerson', currentPerson);
                console.log('POPOVER PERSON this.users', this.users);
            });
    }

    public selectUser(user: Person): void {
        console.log('selectUser POPOVER PERSON', user);
        this.storageService
            .set(variables.currentPerson, user)
            .pipe(first())
            .subscribe(() => {
                console.log('this.router.url', this.router.url);
                this.router.navigate(['/pages']).then(() => {
                    this.popoverController.dismiss({ skip: true }).then();
                    this.store.dispatch(new ReloadAction(this.router.url));
                });
            });
    }

    public logout(): void {
        this.popoverController.dismiss({ skip: true }).then(() => {
            this.authenticationService.logout().subscribe();
        });
    }

    public goToProfile(): void {
        this.popoverController.dismiss().then(() => {
            this.router.navigate(['/pages/my-profile']).then();
        });
    }

    public goToSettings(): void {
        this.popoverController.dismiss().then(() => {
            this.router.navigate(['/pages/settings']).then();
        });
    }
}
