import { Component, OnInit } from '@angular/core';
import { variables } from '@core/_services';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { JsonPipe } from '@angular/common';
import { ReloadAction } from '@core/_effecs/reload/reload.actions';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@core/_services/store.service';
import { first } from 'rxjs/operators';

export interface Instances {
    id: number;
    misRegionId: number;
    name: string;
    misCode: string;
}

@Component({
    selector: 'app-instance-popup',
    templateUrl: './instance-popup.component.html',
    styleUrls: ['./instance-popup.component.scss'],
    providers: [JsonPipe],
})
export class InstancePopupComponent implements OnInit {
    instances: Instances[] | undefined;
    form: FormGroup | undefined;
    submitted = false;
    error = '';

    constructor(
        private fb: FormBuilder,
        private storageService: StorageService,
        public dialogRef: MatDialogRef<InstancePopupComponent>,
        private jsonPipe: JsonPipe,
        private store: Store,
        private translate: TranslateService,
    ) {}

    ngOnInit() {}

    async submit() {
        this.submitted = true;
        this.storageService
            .get<Instances>(variables.currentInstance)
            .pipe(first())
            .subscribe(instance => {
                if (instance) {
                    this.store.dispatch(new ReloadAction(''));
                    this.dialogRef.close();
                } else {
                    // this.translate.instance('')
                    this.error = this.translate.instant('errors.choose-region');
                }
            });
    }

    testSelect() {
        if (this.instances?.length) {
            this.form?.get('currentInstance')?.patchValue(this.jsonPipe.transform(this.instances[0]));
            this.submit();
        }
    }
}
