import {Component, OnInit} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

@Component({
    selector: 'app-cancel-confirmation-popup',
    templateUrl: './cancel-confirmation-popup.component.html',
    styleUrls: ['./cancel-confirmation-popup.component.scss'],
})
export class CancelConfirmationPopupComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<CancelConfirmationPopupComponent>
    ) {
    }

    ngOnInit() {
        setTimeout(() => {
            this.dialogRef.close();
        }, 3 * 1000);
    }

}
