import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    ApiListService,
    AuthenticationService,
    emailValidators,
    passwordValidators,
    phoneMask,
    variables,
} from '@core/_services';
import { LoginTypeEnum } from '@core/_enums';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, switchMap, throwError } from 'rxjs';
import { filterNumbersFromString } from '@core/_utils/numbers-from-string';
import { MaskPipe } from 'ngx-mask';
import { StorageService } from '@core/_services/store.service';
import { catchError, first } from 'rxjs/operators';

@Component({
    selector: 'app-reset-password-modal',
    templateUrl: './reset-password-modal.component.html',
    styleUrls: ['./reset-password-modal.component.scss'],
    providers: [MaskPipe],
})
export class ResetPasswordModalComponent implements OnInit {
    public resetMode = false;
    public form: FormGroup | undefined;
    public extraEmailForm: FormGroup | undefined;
    public formSetNewPassword: FormGroup | undefined;
    public phoneMask = phoneMask;
    public submitted = false;
    public submittedNewPassword = false;
    public phone: string | null | undefined;
    public error = '';
    public firstPart = true;
    public visiblePassword = false;
    public loginType: number | undefined;
    public countDownTimer: number | null = null;
    public callForExtraReset$ = new BehaviorSubject(false);
    public prefix = '(0';

    public loading$ = new BehaviorSubject<boolean>(false);
    constructor(
        private fb: FormBuilder,
        private storageService: StorageService,
        private translate: TranslateService,
        private authenticationService: AuthenticationService,
        private apiListService: ApiListService,
        private mask: MaskPipe,
    ) {}

    async ngOnInit() {
        this.storageService
            .get<any>(variables.login)
            .pipe(first())
            .subscribe(phone => {
                this.phone = phone;
            });
        this.form = this.fb.group({
            phone: ['', Validators.required],
        });
    }

    subscribeToForm() {
        this.form?.valueChanges.subscribe(() => {
            this.error = '';
        });
    }

    toggle(state: boolean) {
        this.extraEmailForm = undefined;
        this.submitted = false;
        this.error = '';
        if (this.resetMode !== state) {
            this.resetMode = !this.resetMode;
            if (this.resetMode) {
                this.form = this.fb.group({
                    // phone: [this.phone || '0974491132', Validators.required]
                    email: ['', emailValidators()],
                });
                this.subscribeToForm();
            } else {
                this.form = this.fb.group({
                    phone: ['', Validators.required],
                });
                this.subscribeToForm();
            }
        }
    }

    __newPasswordSharedControls() {
        return {
            password: ['', passwordValidators],
            code: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])],
        };
    }

    submit() {
        this.submitted = true;
        if (this.extraEmailForm) {
            if (this.form?.valid && this.extraEmailForm.valid) {
                setTimeout(() => {
                    this.callForExtraReset$.next(true);
                }, 700);
                this.loading$.next(true);
                this.apiListService
                    .resetPasswordAndSetEmail(
                        filterNumbersFromString(this.form?.get('phone')?.value),
                        this.extraEmailForm.get('email')?.value,
                    )
                    .pipe(
                        first(),
                        catchError(err => {
                            this.authenticationService.displayError(err, this.translate).then(er => {
                                this.error = er;
                            });
                            this.loading$.next(false);
                            return throwError(err);
                        }),
                    )
                    .subscribe(() => {
                        this.loading$.next(false);
                        this.callForExtraReset$.next(false);
                        this.firstPart = false;
                        this.formSetNewPassword = this.fb.group({
                            phone: [filterNumbersFromString(this.form?.get('phone')?.value) || '', Validators.required],
                            ...this.__newPasswordSharedControls(),
                        });
                    });
            }
        } else {
            if (this.form?.valid) {
                const login = filterNumbersFromString(this.form?.get('phone')?.value) || this.form?.get('email')?.value;
                this.loginType = this.resetMode ? LoginTypeEnum.Email : LoginTypeEnum.Phone;
                if (this.loginType === LoginTypeEnum.Phone) {
                    this.formSetNewPassword = this.fb.group({
                        phone: [
                            login ? this.prefix + this.mask.transform(login.slice(1), phoneMask) : '',
                            Validators.required,
                        ],
                        ...this.__newPasswordSharedControls(),
                    });
                } else {
                    this.formSetNewPassword = this.fb.group({
                        email: [login || '', emailValidators()],
                        ...this.__newPasswordSharedControls(),
                    });
                }
                this.loading$.next(true);
                this.apiListService
                    .resetPasswordReq(login, this.loginType)
                    .pipe(
                        first(),
                        catchError(err => {
                            this.loading$.next(false);
                            if (err?.error?.errorCode[0] === 'UserNotFound') {
                                this.error = this.translate.instant('errors.user-not-found');
                            } else if (err?.error?.errorCode[0] === 'WaitForNextAttempt') {
                                this.countDownTimer = +err?.error?.errorText[0];
                            } else if (err?.error?.errorCode[0] === 'NoEmailForUser') {
                                console.log('I am here');
                                this.extraEmailForm = this.fb.group({
                                    email: ['', emailValidators()],
                                });
                                this.form?.get('phone')?.valueChanges.subscribe(() => {
                                    this.extraEmailForm = undefined;
                                });
                            }
                            return throwError(err);
                        }),
                    )
                    .subscribe(() => {
                        this.loading$.next(false);
                        this.firstPart = false;
                    });
            }
        }
    }

    submitNewPassword() {
        this.submittedNewPassword = true;
        if (this.formSetNewPassword?.valid) {
            const phoneValue = filterNumbersFromString(this.formSetNewPassword?.get('phone')?.value);
            this.loading$.next(true);
            this.apiListService
                .setNewPassword({
                    loginType: this.loginType || LoginTypeEnum.Phone,
                    newPassword: this.formSetNewPassword.get('password')?.value,
                    resetCode: this.formSetNewPassword.get('code')?.value,
                    login:
                        this.loginType === LoginTypeEnum.Phone
                            ? phoneValue
                            : this.formSetNewPassword?.get('email')?.value,
                })
                .pipe(
                    first(),
                    catchError(err => {
                        this.loading$.next(false);
                        this.authenticationService.displayError(err, this.translate).then(er => {
                            this.error = er;
                        });
                        return throwError(err);
                    }),
                    switchMap(() => {
                        this.loading$.next(false);
                        return this.authenticationService.openLogin('/pages/appointment', null);
                    }),
                )
                .subscribe();
        }
    }

    handleStop(ev: any) {
        if (ev?.action === 'done') {
            this.countDownTimer = null;
        }
    }

    public openLogin(value?: any, role?: string): void {
        this.authenticationService.openLogin('/pages/appointment', value).pipe(first()).subscribe();
    }
}
