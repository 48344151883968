import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopoverDropdownComponent } from './popover-dropdown/popover-dropdown.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AuthenticationService } from '@core/_services';
import { Person, UserInterface } from '@core/_interfaces';
import { changePerson } from '@core/_store/person/person.action';
import { SexEnum } from 'src/app/@core/_enums';
import { StorageService } from '@core/_services/store.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
    logo = window.innerWidth < 500 ? 'logo-mobile-red' : 'logo-desktop-red';
    @ViewChild('panel') panel: MatExpansionPanel | undefined;
    openFullWidthVideo$: Observable<boolean> | undefined;
    openFullVideo$: Subscription | undefined;
    currentUser: UserInterface | undefined;
    currentPerson: Person | undefined;
    currentPersonInitial: Person | undefined;
    sexEnum = SexEnum;
    reload$: Subscription;

    constructor(
        private storageService: StorageService,
        public popoverController: PopoverController,
        private store: Store<{ fullScreen: boolean; changePerson: string; page: string }>,
        private authenticationService: AuthenticationService,
        private cd: ChangeDetectorRef,
    ) {
        this.reload$ = this.store
            .select(st => st.page)
            .subscribe(async () => {
                await this.reloadMyView();
            });
    }

    async ngOnInit() {
        await this.reloadMyView();
        this.openFullWidthVideo$ = this.store.pipe(select('fullScreen'));
        this.openFullVideo$ = this.openFullWidthVideo$.subscribe((state: boolean) => {
            state ? this.panel?.close() : this.panel?.open();
        });
    }

    async reloadMyView() {
        this.authenticationService
            .getUser()
            .pipe(first())
            .subscribe(currentUser => {
                this.currentUser = currentUser;
            });
        this.authenticationService
            .getPerson()
            .pipe(first())
            .subscribe(currentPerson => {
                console.log('NAVBAR get PERSON', currentPerson);
                this.currentPerson = currentPerson;
                this.currentPersonInitial = currentPerson;
            });
    }

    async presentPopover(ev: any) {
        console.log('presentPopover', ev);
        const popover = await this.popoverController.create({
            component: PopoverDropdownComponent,
            showBackdrop: false,
            event: ev,
        });
        await popover.present();
        popover.onDidDismiss().then(async (skip: any) => {
            if (!skip?.skip) {
                this.authenticationService
                    .getPerson()
                    .pipe(first())
                    .subscribe(currentPerson => {
                        this.currentPerson = currentPerson;
                        if (this.currentPersonInitial?.personId !== this.currentPerson?.personId) {
                            this.store.dispatch(changePerson());
                            this.currentPersonInitial = this.currentPerson;
                        }
                    });
            }
        });
    }

    onClose() {}

    sc() {}

    onOpen() {}

    ngOnDestroy() {
        this.openFullVideo$?.unsubscribe();
        this.reload$?.unsubscribe();
    }
}
