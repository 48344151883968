import {PagesActionTypes} from './reload.actions';
import {Action} from '@ngrx/store';


const initialState = [''];

export function PageReducer(state: any = initialState, action: Action) {
    switch (action.type) {
        case PagesActionTypes.RELOAD:
            return action;
        default:
            return state;
    }
}
