import {Injectable, Inject, EventEmitter} from '@angular/core';
import {THEMES, ACTIVE_THEME, Theme} from './symbols';

@Injectable()
export class ThemeService {
    customViewForPrivateClinic = [
        {
            code: 'SER',
            value: 'jeremiah'
        }
    ]
    themeChange = new EventEmitter<Theme>();

    constructor(
        @Inject(THEMES) public themes: Theme[],
        @Inject(ACTIVE_THEME) public theme: string
    ) {
    }


    getTheme(name: string) {
        const theme = this.themes.find(t => t.name === name);
        if (!theme) {
            throw new Error(`Theme not found: '${name}'`);
        }
        return theme;
    }

    getActiveTheme() {
        return this.getTheme(this.theme);
    }

    getProperty(propName: string) {
        return this.getActiveTheme().properties[propName];
    }

    manageMisCode(misCode: string) {
        const e = this.customViewForPrivateClinic.find((i) => i.code === misCode);
        if (e) {
            this.setTheme(e.value);
        } else {
            this.setTheme('default');
        }
    }

    setTheme(name: string) {
        this.theme = name;
        this.themeChange.emit(this.getActiveTheme());
    }

    registerTheme(theme: Theme) {
        this.themes.push(theme);
    }

    updateTheme(name: string, properties: { [key: string]: string; }) {
        const theme = this.getTheme(name);
        theme.properties = {
            ...theme.properties,
            ...properties
        };

        if (name === this.theme) {
            this.themeChange.emit(theme);
        }
    }

}
