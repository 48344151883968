import { Component } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import { ModalController } from '@ionic/angular';
import { ContactsComponent } from './contacts/contacts.component';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
    htmlContent = '';
    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        enableToolbar: true,
        showToolbar: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            [
                'undo',
                'redo',
                'strikeThrough',
                'subscript',
                'superscript',
                'justifyLeft',
                'justifyCenter',
                'justifyRight',
                'justifyFull',
                'indent',
                'outdent',
                'insertUnorderedList',
                'insertOrderedList',
                'heading',
                'fontName',
            ],
            [
                'fontSize',
                'textColor',
                'backgroundColor',
                'customClasses',
                'link',
                'unlink',
                'insertImage',
                'insertVideo',
                'insertHorizontalRule',
                'removeFormat',
                'toggleEditorMode',
            ],
        ],
    };
    messages = [
        {
            type: 'doctor',
            content: 'Добрий день Далібор як вам можу допомогти?',
        },
        {
            type: 'patient',
            content: 'Добрий день в мене з’явилися проблема',
        },
        {
            type: 'patient',
            content: 'Скажіть будь ласка чи можу записатися до вас на прийом',
        },
        {
            type: 'patient',
            content: 'Скажіть будь ласка чи можу записатися до вас на прийом',
        },
        {
            type: 'doctor',
            content: 'Звісно що можете',
        },
        {
            type: 'doctor',
            content: 'Зайдить в Мій профіль і оберіть термін та час який вам підходить',
        },
        {
            type: 'patient',
            content: 'Дякую гарного вам дня !!!',
        },
    ];

    constructor(public modalController: ModalController) {}

    ionViewDidEnter() {}

    async presentModal() {
        const modal = await this.modalController.create({
            component: ContactsComponent,
        });
        return await modal.present();
    }
}
