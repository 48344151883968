import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './components/search/search.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AppointmentPopupComponent } from './components/appointment-popup/appointment-popup.component';
import { RouterModule } from '@angular/router';
import { HeaderSearchComponent } from './components/header-search/header-search.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { IonicModule } from '@ionic/angular';
import { CardOptionsComponent } from './components/card-options/card-options.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { DownloadAppComponent } from './components/download-app/download-app.component';
import { NgxMaskModule } from 'ngx-mask';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { StarsComponent } from './components/stars/stars.component';
import { NgxStarsModule } from 'ngx-stars';
import { RatingPopupComponent } from './components/stars/rating-popup/rating-popup.component';
import { OrganizationCardComponent } from './pages/organization-card/organization-card.component';
import { TelemedicineSharedComponent } from './pages/telemedicine-shared/telemedicine-shared.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { UnauthorizedRatingPopupComponent } from './components/stars/unauthorized-rating-popup/unauthorized-rating-popup.component';
import { OrganizationPageComponent } from './pages/organization-page/organization-page.component';
import { AppointmentDateAndTimeComponent } from './components/appointment-date-and-time/appointment-date-and-time.component';
import { RegionLookupComponent } from './components/region-lookup/region-lookup.component';
import { DateLookupComponent } from './components/header-search/date-lookup/date-lookup.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SpecialistCardComponent } from './pages/specialist-card/specialist-card.component';
import { MapPopupComponent } from './pages/organization-page/map-popup/map-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { PreventDoubleClickDirective } from '@core/_directives/prevent-double-click.directive';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { SearchContainerComponent } from './pages/search-container/search-container.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { HighlightDirective } from '@core/_directives/highlight.directive';
import { YoutubeComponent } from './components/youtube/youtube.component';
import { YoutubePopupComponent } from './components/youtube/youtube-popup/youtube-popup.component';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { SafePipe } from '@core/_pipes/safe.pipe';
import { DownloadAppPopupComponent } from './components/download-app-popup/download-app-popup.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { VersionExpiredPopupComponent } from './components/version-expired-popup/version-expired-popup.component';
import { InstancePopupComponent } from '../auth/instance-popup/instance-popup.component';
import { CustomToolTipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { CustomTooltipDirective } from '@core/_directives/custom-tooltip.directive';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { AutocompleteOffDirective } from '@core/_directives/autocomplete-off.directive';
import { HeaderComponent } from '../auth/@shared/header/header.component';
import { FooterComponent } from '../auth/@shared/footer/footer.component';

@NgModule({
    declarations: [
        MapPopupComponent,
        SpecialistCardComponent,
        DateLookupComponent,
        RegionLookupComponent,
        AppointmentDateAndTimeComponent,
        OrganizationPageComponent,
        UnauthorizedRatingPopupComponent,
        YoutubePopupComponent,
        TelemedicineSharedComponent,
        OrganizationCardComponent,
        RatingPopupComponent,
        ConfirmDialogComponent,
        StarsComponent,
        YoutubeComponent,
        DateInputComponent,
        SearchComponent,
        AppointmentPopupComponent,
        HeaderSearchComponent,
        BackButtonComponent,
        CardOptionsComponent,
        DownloadAppComponent,
        DownloadAppPopupComponent,
        PreventDoubleClickDirective,
        AutocompleteOffDirective,
        SearchContainerComponent,
        HighlightDirective,
        CustomTooltipDirective,
        SafePipe,
        VersionExpiredPopupComponent,
        InstancePopupComponent,
        CustomToolTipComponent,
        HeaderComponent,
        FooterComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        NgxPaginationModule,
        MatNativeDateModule,
        MatDatepickerModule,
        RouterModule,
        IonicModule,
        MatMenuModule,
        NgxMaskModule,
        MatInputModule,
        NgxStarsModule,
        MatButtonModule,
        DragDropModule,
        FilterPipeModule,
        MatProgressSpinnerModule,
        TranslateModule,
        MatExpansionModule,
        MatToolbarModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatSnackBarModule,
        MatDialogModule,
        MatSelectModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatInputModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        YoutubeComponent,
        NgxMaskModule,
        IonicModule,
        MatExpansionModule,
        NgxPaginationModule,
        MatDatepickerModule,
        TranslateModule,
        RegionLookupComponent,
        AppointmentDateAndTimeComponent,
        OrganizationPageComponent,
        TelemedicineSharedComponent,
        OrganizationCardComponent,
        StarsComponent,
        DateInputComponent,
        SearchComponent,
        HeaderComponent,
        FooterComponent,
        SpecialistCardComponent,
        HeaderSearchComponent,
        BackButtonComponent,
        CardOptionsComponent,
        DownloadAppComponent,
        DownloadAppPopupComponent,
        MatProgressSpinnerModule,
        PreventDoubleClickDirective,
        AutocompleteOffDirective,
        MatToolbarModule,
        SearchContainerComponent,
        MatButtonToggleModule,
        HighlightDirective,
        CustomTooltipDirective,
        MatSnackBarModule,
        SafePipe,
        VersionExpiredPopupComponent,
        CustomToolTipComponent,
    ],
})
export class SharedModule {}
