import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ApiListService, variables } from '../../../@core/_services';
import { Subject } from 'rxjs';
import { catchError, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { StorageService } from '@core/_services/store.service';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import { PlatformService } from '@core/_services/platform.service';

@Component({
    selector: 'app-version-expired-popup',
    templateUrl: './version-expired-popup.component.html',
    styleUrls: ['./version-expired-popup.component.scss'],
})
export class VersionExpiredPopupComponent implements OnInit, OnDestroy {
    public isIos = false;
    public isAndroid = false;
    public androidApp = environment.androidId;
    public iosApp = environment.iosId;
    private destroyed$ = new Subject<void>();
    constructor(
        private translate: TranslateService,
        private platform: PlatformService,
        // private market: Market,
        private storageService: StorageService,
        private apiListService: ApiListService,
        private market: Market,
    ) {}

    public ngOnInit(): void {
        this.storageService
            .get(variables.currentInstance)
            .pipe(
                switchMap((currentInstance: any) =>
                    currentInstance?.id
                        ? this.apiListService.getInstanceConfig(currentInstance?.id).pipe(
                              tap(async instanceConfig =>
                                  this.storageService
                                      .set(variables.instanceConfig, instanceConfig)
                                      .pipe(first())
                                      .subscribe(),
                              ),
                              catchError(() => this.storageService.get(variables.instanceConfig)),
                          )
                        : this.storageService.get(variables.instanceConfig),
                ),
                first(),
                takeUntil(this.destroyed$),
            )
            .subscribe((instanceConfig: any) => {
                this.androidApp = instanceConfig?.appStoreLinks.android
                    ? instanceConfig.appStoreLinks.android
                    : this.androidApp;

                this.iosApp = instanceConfig?.appStoreLinks.ios ? instanceConfig.appStoreLinks.ios : this.iosApp;

                if (this.platform.isIosPlatform$.value) {
                    this.isIos = true;
                } else if (this.platform.isAndroidPlatform$.value) {
                    this.isAndroid = true;
                }
            });
    }

    public async openIosApp(): Promise<void> {
        if (this.iosApp) {
            const appId = (this.iosApp as string).split('/id')[1];
            if (appId) {
                await this.market.open('id' + appId);
            } else {
                await this.market.open(this.iosApp);
            }
        }
    }

    public async openGoogleApp(): Promise<void> {
        if (this.androidApp) {
            const appId = (this.androidApp as string).split('?id=')[1];
            if (appId) {
                await this.market.open(appId);
            } else {
                window.open(this.androidApp, '_system', 'location=yes');
            }
        }
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
