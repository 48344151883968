import { HttpClient, HttpContext, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

type BaseHttpParams =
    | HttpParams
    | {
          [param: string]: string | string[];
      };

type BaseHttpHeaders =
    | HttpHeaders
    | {
          [header: string]: string | string[];
      };

type BaseBody = any | null;

@Injectable({ providedIn: 'root' })
export class ApiBaseService {
    constructor(private http: HttpClient) {}

    fullUrl = (): string => {
        return environment.apiUrl + '/' || '';
    };

    get = <T>(action: string, headers?: BaseHttpHeaders): Observable<T> => {
        return this.http.get<T>(this.fullUrl() + action, { headers }).pipe(catchError(err => throwError(err)));
    };

    getWithOptions = <T>(action: string, options: any): Observable<HttpEvent<T>> | Observable<any> => {
        return this.http.get<T>(this.fullUrl() + action, options).pipe(catchError(err => throwError(err)));
    };
    postWithOptions = <T>(action: string, body: BaseBody, options: any): Observable<HttpEvent<T>> | Observable<any> => {
        return this.http.post<T>(this.fullUrl() + action, body, options).pipe(catchError(err => throwError(err)));
    };

    put = <T>(action: string, body: BaseBody, headers?: BaseHttpHeaders): Observable<T> => {
        return this.http.put<T>(this.fullUrl() + action, body, { headers }).pipe(catchError(err => throwError(err)));
    };

    post = <T>(action: string, body: BaseBody, headers?: BaseHttpHeaders): Observable<T> => {
        return this.http.post<T>(this.fullUrl() + action, body, { headers }).pipe(catchError(err => throwError(err)));
    };

    delete = <T>(action: string, headers?: BaseHttpHeaders): Observable<T> => {
        return this.http.delete<T>(this.fullUrl() + action, { headers }).pipe(catchError(err => throwError(err)));
    };

    patch = <T>(action: string, body: BaseBody, headers?: BaseHttpHeaders): Observable<T> => {
        return this.http.patch<T>(this.fullUrl(), body, { headers }).pipe(catchError(err => throwError(err)));
    };
}
