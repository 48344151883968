import {Component} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-youtube-popup',
  templateUrl: './youtube-popup.component.html',
  styleUrls: ['./youtube-popup.component.scss'],
  providers: []
})

export class YoutubePopupComponent {
  public safeURL: SafeResourceUrl;
  constructor(
      private sanitizer: DomSanitizer) {
    this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/aMQSkrgcaSs');
  }

}

