import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { IonicRefresherType, MyDoctor } from '@core/_interfaces';
import { PagesService } from '../pages.service';
import { BehaviorSubject, Subscription, switchMap, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiListService, Download, dateFormat, variables } from '@core/_services';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NoDoctorPopupComponent } from './no-doctor-popup/no-doctor-popup.component';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { catchError, first } from 'rxjs/operators';
import { StorageService } from '@core/_services/store.service';

@Component({
    selector: 'app-my-doctor',
    templateUrl: './my-doctor.component.html',
    styleUrls: ['./my-doctor.component.scss'],
    providers: [Download],
    encapsulation: ViewEncapsulation.None,
})
export class MyDoctorComponent implements OnInit, OnDestroy {
    public isActive = true;
    public data: MyDoctor[] | undefined;
    public reload$: Subscription | undefined;
    public dateFormat = dateFormat;
    @Input() cssClass: boolean | undefined;
    public qrLink: any;
    public href = '';
    public loading$ = new BehaviorSubject<boolean>(true);

    constructor(
        private router: Router,
        private storageService: StorageService,
        private dialog: MatDialog,
        private pagesService: PagesService,
        private store: Store<{ page: string }>,
        public download: Download,
        private apiListService: ApiListService,
    ) {}

    public ngOnInit() {
        this.reload$ = this.store
            .select(store => store.page)
            .subscribe(() => {
                this.getMyDoctor(undefined, true);
            });
    }

    public doRefresh(event: IonicRefresherType) {
        this.getMyDoctor(event);
    }

    public ngOnDestroy() {
        this.reload$?.unsubscribe();
    }

    public async getMyDoctor(event?: IonicRefresherType, isActive?: boolean) {
        if (!event) {
            this.loading$.next(true);
        }

        this.storageService
            .get<any>(variables.currentPerson)
            .pipe(
                first(),
                switchMap(currentPerson => {
                    const personId = currentPerson?.personId;
                    return this.apiListService
                        .getMyDoctor({
                            personId,
                            isActive,
                        })
                        .pipe(
                            first(),
                            catchError(err => {
                                if (event) {
                                    event?.target?.complete();
                                } else {
                                    this.loading$.next(false);
                                }

                                return throwError(err);
                            }),
                        );
                }),
            )
            .subscribe((data: any) => {
                this.pagesService.addImages(data, 'imageID').then((resp: MyDoctor[]) => {
                    this.data = resp;
                    if (isActive === false) {
                        if (event) {
                            event?.target?.complete();
                        } else {
                            this.loading$.next(false);
                        }
                        if (this.isActive !== isActive) {
                            this.isActive = !this.isActive;
                        }
                    } else if (data.length === 0 && window.location.hash === '#/pages/my-doctor') {
                        this.loading$.next(false);
                        this.dialog
                            .open(NoDoctorPopupComponent, {
                                width: '400px',
                            })
                            .afterClosed()
                            .pipe(
                                first(),
                                catchError(err => {
                                    if (event) {
                                        event?.target?.complete();
                                    } else {
                                        this.loading$.next(false);
                                    }

                                    return throwError(err);
                                }),
                            )
                            .subscribe(() => {
                                this.router.navigate(['/pages/appointment']);
                            });
                    } else {
                        if (event) {
                            event?.target?.complete();
                        } else {
                            this.loading$.next(false);
                        }
                        if (this.isActive !== isActive) {
                            this.isActive = !this.isActive;
                        }
                    }
                });
            });
    }

    public toggle(isActive: boolean): void {
        this.getMyDoctor(undefined, isActive);
    }

    public changePage(): void {
        this.getMyDoctor(undefined, this.isActive);
    }

    public async goToChild(item: MyDoctor): Promise<void> {
        this.router.navigate(['/pages/my-doctor/', item.id, item.instanceId]);
    }

    public async generateQR(item: MyDoctor): Promise<void> {
        document.querySelectorAll('.visited').forEach(element => {
            element.classList.remove('visited');
        });

        const itemID = document.getElementById(`${item.id}`) as HTMLElement;
        itemID.classList.toggle('visited');

        this.storageService
            .get<any>(variables.currentPerson)
            .pipe(
                switchMap(currentPerson => {
                    const personId = currentPerson?.personId;
                    return this.apiListService.getAccessGuidForShareDocument(item.id, item.instanceId, personId);
                }),
            )
            .subscribe((val: string) => {
                this.getQrLink(val);
            });
    }

    public getQrLink(val: string): void {
        // this.qrLink = window.location.origin + '/#/declaration/' + val;
        this.qrLink = `${environment?.webUrl}/#/declaration/${val}`;
    }
}
