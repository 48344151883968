import {Action} from '@ngrx/store';
import {SelectInstanceActions} from './select-instance.actions';

const initialState = {

}

export function SelectInstanceReducer(state: any = initialState, action: Action) {
    switch (action.type) {
        case SelectInstanceActions.PASS_ERROR:
            return action;
        case 'RESET_EVENT':
            return Object.assign({}, initialState);
        default:
            return state;
    }
}
