import { Component, OnInit, ViewChild } from '@angular/core';
import { GetDoctorsService } from '@core/_services/getDoctors.service';
import { AuthenticationService, variables, year } from '@core/_services';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { RegistrationPopupFirstComponent } from '../../registration/registration-popup-first/registration-popup-first.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { IonContent } from '@ionic/angular';
import { YoutubePopupComponent } from '../../../@shared/components/youtube/youtube-popup/youtube-popup.component';
import { Instances } from '@core/_interfaces';
import { environment } from '@env/environment';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
    @ViewChild(IonContent, { static: true }) content: IonContent | undefined;
    currentInstance: Instances | undefined | null;
    instances: Instances[] | undefined;
    environment = environment;
    type: 'doctor' | 'lz' | null = null;
    cards = [
        'doctors-appointment',
        'reminder',
        'evaluation',
        'access-to-information',
        'offer-your-medical-facility',
        'exchanging-messages-with-doctor',
        'sharing-health-information',
    ];
    year = year;
    payload: any;
    spacing = false;
    loading$ = new Subject<boolean>();
    option: string | undefined;
    option$ = new BehaviorSubject('doctor');
    youtubeURL = environment?.youtubeURL;

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {}

    async ngOnInit() {}

    register() {
        this.dialog
            .open(RegistrationPopupFirstComponent, {
                panelClass: 'no-padding-popup',
                width: '420px',
                maxWidth: 'calc(100% - 10px)',
                backdropClass: 'blur-bg',
            })
            .afterClosed()
            .subscribe(async data => {
                if (data) {
                    this.router.navigate([`/${variables.pages}`]);
                } else if (data === null) {
                    const url = `/pages/appointment`;
                    this.authenticationService
                        .hearthBeat(url)
                        .pipe(first())
                        .subscribe(() => {
                            this.router.navigate([url]);
                        });
                }
            });
    }

    openYoutube() {
        this.dialog.open(YoutubePopupComponent);
    }

    scrollTop(type: string) {
        this.option$.next(type);
        this.content?.scrollToTop(400);
    }
}
