import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ratingStringsOrganization, SearchOrgListInterface } from '@core/_services';
import { currentPage, Instances, itemsPerPage, PaginationConfig } from '@core/_interfaces';
import { PagesService } from '../../../pages/pages.service';
import { Subject, Subscription } from 'rxjs';
import { StorageService } from '@core/_services/store.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-organization-card',
    templateUrl: './organization-card.component.html',
    styleUrls: ['./organization-card.component.scss'],
})
export class OrganizationCardComponent implements OnInit, OnDestroy {
    @Input() public organizations$: Subject<SearchOrgListInterface | undefined> | any;
    @Input() public fromLanding: boolean | undefined;
    @Input() public loading$?: Subject<boolean> | undefined;
    @Output() public configEvent = new EventEmitter<any>();
    public setOrganizations$: Subscription | undefined;
    public organizations: SearchOrgListInterface | undefined | any;
    public config: PaginationConfig = {
        itemsPerPage,
        currentPage,
        page: currentPage,
        pageSize: itemsPerPage,
    };
    public ratingStrings = ratingStringsOrganization;
    public showOrganizationName: boolean | undefined;

    constructor(private storageService: StorageService, private pagesService: PagesService) {}

    public async ngOnInit() {
        this.setOrganizations$ = this.organizations$?.subscribe((v: any) => {
            this.organizations = v;
            this.config.totalItems = v?.totalCount || v?.totalItems || 0;
        });

        this.storageService
            .get<any>('instanceConfig')
            .pipe(first())
            .subscribe(instanceConfig => {
                this.showOrganizationName = instanceConfig?.showOrganizationName;
                if (this.showOrganizationName === undefined) {
                    this.showOrganizationName = true;
                }
            });
    }

    public changePage(): void {
        const changePageData = {
            page: this.config?.currentPage,
            pageSize: this.config?.itemsPerPage,
        };
        this.pagesService.scrollToTop('app-appointment');
        this.pagesService.scrollToTop('app-auth');
        this.configEvent.emit({ ...changePageData, option: 'lz' });
    }

    public ngOnDestroy(): void {
        this.setOrganizations$?.unsubscribe();
    }
}
