// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { EnvironmentInterface } from '@core/_interfaces';

export const environment: EnvironmentInterface = {
   production: false,
  apiUrl: 'https://medportal.ua',
  applicationVersion: '1.79.0',
  showingPages: {
    appointment: true,
    myAppointments: true,
    visits: true,
    immunization: true,
    prescription: true,
    analyzes: true,
    instrumentalResearch: true,
    referral: true,
    telemedicine: true,
    myDoctor: true,
    events: false,
    chat: false
  },
  covidExternalUrl: 'https://vaccination.covid19.gov.ua/',
  agreement: 'https://drive.google.com/file/d/13MQ9fndR9yLxXOjjW3f00vjO3pwJpZLh/view',
  prefixURL: 'api/portal/',
  maxMonthForNSZU: 3,
  androidId: 'market://details?id=elife.medportal.ua',
  iosId: 'id1555657404',
  hiddenInstances: ['THR', 'KSM', 'SER', 'MDS', 'MDU', 'ALA', 'MBH', 'LOR', 'NEY', 'MDR', 'AMI', 'ALL', 'INE', 'GAR', 'MFD', 'MSP', 'MDA', 'MSU', 'NFR', 'KMK', 'NCI', 'RID', 'ION'],
  youtubeURL: 'https://www.youtube.com/channel/UCPLhgsgNnr-2ymEXXxzWnwQ/featured',
  androidApp: 'https://play.google.com/store/apps/details?id=elife.medportal.ua',
  iosApp: 'https://apps.apple.com/ua/app/medportal/id1555657404'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
