import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageComponent } from './pages/page.component';
import { variables } from '@core/_services';
import { TelemedicinePublicComponent } from './auth/telemedicine-public/telemedicine-public.component';
import { DeclarationComponent } from './auth/declaration/declaration.component';
import { AuthGuard } from '@core/_helpers/auth.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./auth/auth-routing.module').then(m => m.AuthRoutingModule),
    },
    {
        path: 'policy',
        loadChildren: () => import('./pages/policy/policy.module').then(m => m.PolicyModule),
    },
    {
        path: 'auth',
        redirectTo: '',
        pathMatch: 'full',
    },
    {
        path: variables.pages,
        component: PageComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    },
    // {
    //     path: 'telemedicine/:PortalUserID/:PatientName/:Service/:Phones', component: TelemedicinePublicComponent,
    // },
    // {
    //     path: 'telemedicine/:PortalUserID/:PatientName/:Service/:Phones/:first/:second/:third', component: TelemedicinePublicComponent
    // },
    {
        path: 'telemedicine/:conferenceId',
        component: TelemedicinePublicComponent,
    },
    {
        path: 'declaration/:guid',
        component: DeclarationComponent,
    },
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            onSameUrlNavigation: 'reload',
            scrollPositionRestoration: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
