import { Directive, Input, TemplateRef, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { first, takeUntil } from 'rxjs/operators';
import { ComponentPortal } from '@angular/cdk/portal';
import { CustomToolTipComponent } from '../../@shared/components/custom-tooltip/custom-tooltip.component';
import { Subject } from 'rxjs';

@Directive({
    selector: '[customToolTip]',
})
export class CustomTooltipDirective {
    @Input() public showToolTip = true;
    @Input(`customToolTip`) public text = '';
    @Input() public contentTemplate!: TemplateRef<any>;

    private _overlayRef!: OverlayRef;
    private _tooltipInstance: any;
    private _mouseInTooltip = false;
    private _hasListeners = false;
    private destroeyd$ = new Subject<void>();

    constructor(
        private _overlay: Overlay,
        private _overlayPositionBuilder: OverlayPositionBuilder,
        private _elementRef: ElementRef,
        private _r2: Renderer2,
    ) {}

    public ngOnInit(): void {
        if (!this.showToolTip) {
            return;
        }
        const positionStrategy = this._overlayPositionBuilder.flexibleConnectedTo(this._elementRef).withPositions([
            {
                originX: 'center',
                originY: 'center',
                overlayX: 'start',
                overlayY: 'center',
                offsetX: 25,
            },
        ]);

        this._overlayRef = this._overlay.create({ positionStrategy });
    }

    @HostListener('mouseenter')
    private show(): void {
        if (this._overlayRef && !this._overlayRef.hasAttached()) {
            this._tooltipInstance = this._overlayRef.attach(new ComponentPortal(CustomToolTipComponent)).instance;
            this._overlayRef.updatePositionStrategy(
                this._overlayPositionBuilder.flexibleConnectedTo(this._elementRef).withPositions([
                    {
                        originX: 'center',
                        originY: 'center',
                        overlayX: 'start',
                        overlayY: 'center',
                        offsetX: 25,
                        // offsetX: this.getOffsetX(),
                    },
                ]),
            );
            this._overlayRef.updatePosition();

            if (this._tooltipInstance) {
                this._tooltipInstance.text = this.text;
                this._tooltipInstance._tooltip = this._elementRef;
                this._tooltipInstance._triggerElement = this._elementRef.nativeElement;
                this._tooltipInstance.contentTemplate = this.contentTemplate;
                this._tooltipInstance.show(0);

                this._tooltipInstance
                    .afterHidden()
                    .pipe(first(), takeUntil(this.destroeyd$))
                    .subscribe(() => {
                        this._overlayRef.detach();
                    });
            }

            if (!this._hasListeners) {
                this._hasListeners = true;
                this._r2.listen(this._overlayRef.overlayElement, 'mouseleave', () => {
                    this._mouseInTooltip = false;
                    this.hide();
                });
                this._r2.listen(this._overlayRef.overlayElement, 'mouseenter', () => {
                    this._mouseInTooltip = true;
                });
            }
        }
    }

    @HostListener('mouseleave')
    private hide(): void {
        setTimeout(() => {
            if (!this._mouseInTooltip) {
                this._tooltipInstance._onHide.next();
            }
        }, 20);
    }

    private getOffsetY(): number {
        console.log('getOffsetY', this._elementRef.nativeElement.getBoundingClientRect());
        if (this._elementRef.nativeElement.getBoundingClientRect().bottom > 500) return -400;
        if (this._elementRef.nativeElement.getBoundingClientRect().bottom > 400) return -300;
        if (this._elementRef.nativeElement.getBoundingClientRect().bottom > 300) return -200;

        return -10;
    }

    private getOffsetX(): number {
        console.log('getOffsetX', this._elementRef.nativeElement.getBoundingClientRect());
        if (this._elementRef.nativeElement.getBoundingClientRect().right > 500) return -400;
        if (this._elementRef.nativeElement.getBoundingClientRect().right > 400) return -300;
        if (this._elementRef.nativeElement.getBoundingClientRect().right > 300) return -200;

        return -25;
    }
}
