import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiListService } from '@core/_services';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesService } from '../pages/pages.service';
import { Store } from '@ngrx/store';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NoDoctorDeclarationPopupComponent } from '../@shared/components/no-doctor-declaration-popup/no-doctor-declaration-popup.component';

@Component({
    selector: 'app-declaration',
    templateUrl: './declaration.component.html',
    styleUrls: ['./declaration.component.scss'],
})
export class DeclarationComponent implements OnInit, OnDestroy {
    declaration = '';
    reload$: Subscription | undefined;
    guid: number | undefined;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private pagesService: PagesService,
        private store: Store<{ page: string }>,
        private activatedRoute: ActivatedRoute,
        private apiListService: ApiListService,
        private dialog: MatDialog,
    ) {}

    ngOnInit() {
        setTimeout(() => location.reload(), 6180000);
        this.reload$ = this.store
            .select(store => store.page)
            .subscribe(async () => {
                const guid = this.activatedRoute.snapshot.params.guid;
                this.apiListService.getDocumentByQr(guid).subscribe((data: any) => {
                    if (data) {
                        this.declaration = data;
                    } else {
                        const declarationHeader = document.querySelector('.declarationHeader') as HTMLElement;
                        declarationHeader.style.display = 'none';
                        this.dialog
                            .open(NoDoctorDeclarationPopupComponent, {
                                width: '400px',
                            })
                            .afterClosed()
                            .subscribe(() => {
                                this.router.navigate(['/']);
                            });
                    }
                });
            });
    }

    ngOnDestroy() {}
}
