import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { AuthenticationService, variables } from '../_services';
import { Store } from '@ngrx/store';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Instances, UserInterface } from '@core/_interfaces';
import { StorageService } from '@core/_services/store.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    instanceUrls = [
        'AutoCmplt/GetDistrictsAutoCmplt',
        'AutoCmplt/GetSettlementsAutoCmplt',
        'AutoCmplt/GetOrganizationsAutoCmplt',
        'AutoCmplt/GetServicesAutoCmplt',
        'AutoCmplt/GetSpecialitiesAutoCmplt',
        'Common/GetImage',
        'Reception/SearchDocList',
    ];

    constructor(
        private authenticationService: AuthenticationService,
        private store: Store<{ instance: number; selectInstance: string }>,
        private dialog: MatDialog,
        private storageService: StorageService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // @ts-ignore
        return this.handle(req, next);
    }

    handle(req: HttpRequest<any>, next: HttpHandler) {
        return combineLatest([
            this.storageService.get<UserInterface>(variables.currentUser),
            this.storageService.get<Instances>(variables.currentInstance),
        ]).pipe(
            switchMap(([authToken, currentInstance]) => {
                let authReq;
                if (authToken) {
                    authReq = req.clone({
                        setHeaders: {
                            Authorization: 'Bearer ' + authToken.accessToken,
                        },
                    });
                } else {
                    authReq = req.clone({});
                }
                // for (const key of this.instanceUrls) {
                //     if (req.url.includes(key)) {
                //         if (!currentInstance) {
                //             this.store.dispatch(new PassError(''));
                //
                //             return throwError('No instance');
                //         }
                //     }
                // }

                return next.handle(authReq);
            }),
        );
    }
}
