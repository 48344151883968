import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { FormsService } from '@core/_services/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiListService, AuthenticationService, variables } from '@core/_services';
import { MatAccordion } from '@angular/material/expansion';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, switchMap } from 'rxjs';
import { SexEnum } from '@core/_enums';
import { GUIDValidator, PagesService } from '../pages.service';
import { Store } from '@ngrx/store';
import { IonicRefresherType } from '@core/_interfaces';
import { DatePipe } from '@angular/common';
import { PersonPopupComponent } from './person-popup/person-popup.component';
import { ToastController } from '@ionic/angular';
import { StorageService } from '@core/_services/store.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-my-profile',
    templateUrl: './my-profile.component.html',
    styleUrls: ['./my-profile.component.scss'],
    providers: [FormsService, DatePipe],
})
export class MyProfileComponent implements OnInit, OnDestroy {
    @ViewChild('peoplePanelAccordion') public peoplePanelAccordion: MatAccordion | undefined;
    @ViewChild('codePanelAccordion') public codePanelAccordion: MatAccordion | undefined;
    public form: FormGroup | undefined;
    public instanceForm: FormGroup | undefined;
    public connectedPeopleForm: FormGroup | undefined;
    public peoplePanelAccordionState = false;
    public codePanelAccordionState = false;
    public submitted = false;
    public initialBirthday: string | undefined | boolean;
    public instancesOfCurrentUser: string[] = [];
    public allUsers: MyPersons[] = [];
    public addInstanceToCurrentUser = false;
    public instanceFormSubmitted = false;
    public submitPersonSubmitted = false;
    public error = '';
    public reload$: Subscription | undefined;
    public instanceFormSubmit$ = new Subject<boolean>();
    public loading$ = new Subject();
    public sexEnum = SexEnum;

    constructor(
        private formsService: FormsService,
        private storageService: StorageService,
        public toastController: ToastController,
        public dialog: MatDialog,
        private translate: TranslateService,
        private authenticationService: AuthenticationService,
        private pagesService: PagesService,
        private fb: FormBuilder,
        private store: Store<{ page: string }>,
        private apiListService: ApiListService,
    ) {}

    public async ngOnInit() {
        this.storageService
            .get<any>('profile')
            .pipe(first())
            .subscribe(profile => {
                this.initialBirthday = profile?.birthday;
            });
        this.reload$ = this.store
            .select(store => store.page)
            .subscribe((reloadType: any) => {
                console.log(reloadType);
                this.getMyPersons();
            });
    }

    public doRefresh(event: IonicRefresherType) {
        this.getMyPersons(event);
    }

    public ngOnDestroy() {
        this.reload$?.unsubscribe();
    }

    public openPerson(person: AbstractControl | null) {
        this.dialog
            .open(PersonPopupComponent, {
                width: '900px',
                maxWidth: 'calc(100% - 10px)',
                data: person,
                panelClass: 'personal-panel',
                disableClose: false,
            })
            .afterClosed()
            .subscribe(() => {
                this.getMyPersons();
            });
    }

    public clearCodeForMainUser() {
        this.addInstanceToCurrentUser = false;
        this.instanceFormSubmitted = false;
        this.instanceForm?.reset();
    }

    public getMyPersons(event?: IonicRefresherType) {
        this.loading$.next(true);
        this.apiListService
            .getMyPersonsRequest()
            .pipe(first())
            .subscribe(async (persons: MyPersons[]) => {
                this.authenticationService
                    .getUser()
                    .pipe(
                        first(),
                        switchMap(currentUser => {
                            return this.storageService.set(variables.currentUser, { ...currentUser, persons }).pipe(
                                switchMap(() => {
                                    return this.storageService.get<any>(variables.currentPerson).pipe(
                                        switchMap(currentPerson => {
                                            const item = persons?.find(
                                                (e: any) => e.personId === currentPerson?.personId,
                                            );
                                            return this.storageService.set(variables.currentPerson, item);
                                        }),
                                    );
                                }),
                            );
                        }),
                    )
                    .subscribe();
                const arrayPersons = persons.slice(1);
                this.instancesOfCurrentUser = persons[0].instances;
                this.form = await this.pagesService.personForm(persons[0]);
                this.connectedPeopleForm = this.fb.group({
                    people: this.fb.array([]),
                });
                arrayPersons.forEach((item: MyPersons) => {
                    (this.connectedPeopleForm?.get('people') as FormArray).push(this.pagesService.personForm(item));
                });
                if (!this.connectedPeopleForm) {
                    this.connectedPeopleForm = new FormGroup({});
                }
                this.allUsers.forEach((user, index) => {
                    this.allUsers[index].accessGUID = this.fb.group({
                        accessGUID: ['', GUIDValidator],
                        personId: [user?.personId || null],
                    });
                });
                event?.target?.complete();
                this.loading$.next(false);
            });
    }

    get p() {
        return this.connectedPeopleForm?.controls?.people as FormArray;
    }
}

export interface MyPersons {
    birthday: Date;
    firstName: string;
    instances: string[];
    lastName: string;
    middleName: string;
    personId: number;
    sex: number;
    phoneNumber: string;
    email: string;
    accessGUID?: FormGroup;
    relationDegree?: any;
}

export interface SubmitPersonGUIDInterface extends MyPersons {
    submitted?: true;
    accessGUID: FormGroup;
    errorServerSide?: string;
}
