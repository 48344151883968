import { Component, Input, OnInit } from '@angular/core';
import {
    ApiListService,
    DoctorInterface,
    GetDoctorsService,
    OrganizationList,
    OrganizationServiceType,
    ratingStringsOrganization,
    SearchDocListInterface,
    SearchOrgListInterface,
    variables,
} from '@core/_services';
import { ActivatedRoute } from '@angular/router';
import { PagesService } from '../../../pages/pages.service';
import { currentPage, itemsPerPage, PayloadType } from '@core/_interfaces';
import { BehaviorSubject, Subject, Subscription, throwError } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MapPopupComponent } from './map-popup/map-popup.component';
import { catchError, first, switchMap, takeUntil } from 'rxjs/operators';
import { StorageService } from '@core/_services/store.service';

@Component({
    selector: 'app-organization-page',
    templateUrl: './organization-page.component.html',
    styleUrls: ['./organization-page.component.scss'],
    providers: [GetDoctorsService],
})
export class OrganizationPageComponent implements OnInit {
    @Input() public fromLanding: boolean | undefined;
    public setLoading$: Subscription | undefined;
    public organizationInfo: OrganizationList | undefined;
    public ratingStrings = ratingStringsOrganization;
    public payload: any = { option: 'doctor' };
    public organizations: SearchOrgListInterface | undefined;
    public id: number | undefined;
    public organizationServices: OrganizationServiceType[] | undefined | null;
    public fromOrganizationData: OrganizationServiceType[] | undefined;
    public doctors$: Subject<any> = new Subject<any>();
    public filterByValue = {
        serviceName: '',
    };
    public map: any;
    public loadingPreload$ = new BehaviorSubject<boolean>(true);
    public loading$ = new Subject<boolean>();
    public showOrganizationName: boolean | undefined;
    private destroyed$ = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private storageService: StorageService,
        private pageService: PagesService,
        private dialog: MatDialog,
        private apiListService: ApiListService,
    ) {}

    public async ngOnInit() {
        this.setLoading$ = this.loading$.subscribe(value => {
            if (!value) {
                this.filterByValue = {
                    serviceName: '',
                };
            }
        });
        this.storageService
            .get<any>(variables.currentInstance)
            .pipe(
                first(),
                switchMap(currentInstance => {
                    const instanceId = currentInstance?.id;
                    this.payload = { instanceId };
                    this.id = +(this.route?.snapshot?.paramMap?.get('id') || 0);
                    this.loading$.next(true);
                    this.loadingPreload$.next(true);

                    return this.apiListService
                        .GetOrganizationServices(this.id as number, this.payload.instanceId as number)
                        .pipe(
                            first(),
                            switchMap((data: OrganizationServiceType[]) => {
                                this.fromOrganizationData = data;
                                this.organizationServices = data;

                                return this.apiListService.getOrgInfo(this.payload.instanceId, this.id);
                            }),
                            catchError(err => {
                                this.loadingPreload$.next(false);

                                return throwError(err);
                            }),
                        );
                }),
                takeUntil(this.destroyed$),
            )
            .subscribe(async (data: OrganizationList) => {
                this.pageService.addImages([data], 'imageId').then((val: any) => {
                    this.organizationInfo = val[0];
                    this.payload.option = 'doctor';
                    this.loading$.next(false);
                    this.loadingPreload$.next(false);
                    this.getAutoCompleteData();
                });
            });
        this.storageService
            .get<any>('instanceConfig')
            .pipe(first(), takeUntil(this.destroyed$))
            .subscribe(instanceConfig => {
                this.showOrganizationName = instanceConfig?.showOrganizationName;
                if (this.showOrganizationName === undefined) {
                    this.showOrganizationName = true;
                }
            });
    }

    public storeData(ev: PayloadType) {
        if (ev?.type === 'reset') {
            this.payload.option = ev?.option || 'doctor';

            return;
        } else {
            this.payload.option = ev?.option;
        }
        if (ev?.invalid) {
            this.doctors$.next(null);
            this.payload.option = 'doctor';
            this.loading$.next(false);

            return;
        }
        if (ev?.type === 'service') {
            this.payload.option = 'lz';
        } else {
            this.payload = { ...this.payload, ...ev };
            this.getAutoCompleteData();
        }
    }

    public clientSearch(event: string) {
        this.filterByValue.serviceName = event;
    }

    public getAutoCompleteData(): void {
        if (!this.payload.option) {
            this.payload.option = 'doctor';
        }
        let payload = { ...this.payload, organizationId: this.organizationInfo?.medicalOrgId };
        if (!payload?.page) {
            payload.page = currentPage;
        }
        if (!payload?.pageSize) {
            payload.pageSize = itemsPerPage;
        }
        this.loading$.next(true);
        if (payload?.req) {
            payload = { ...payload, ...payload.req };
            delete payload.req;
        }
        this.loadingPreload$.next(true);
        this.apiListService
            .searchDocList({ ...payload, organizationId: this.id })
            .pipe(
                catchError(err => {
                    this.loadingPreload$.next(false);

                    return throwError(err);
                }),
            )
            .subscribe((data: SearchDocListInterface) => {
                this.pageService.addImages(data.data as DoctorInterface[], 'imageID').then((val: any) => {
                    data.data = val;
                    this.doctors$.next(data);
                    this.loading$.next(false);
                    this.loadingPreload$.next(false);
                });
            });
    }

    public openMap() {
        this.dialog.open(MapPopupComponent, {
            width: '90vw',
            height: '90vh',
            autoFocus: false,
            backdropClass: 'blur-bg',
        });
    }

    public ngOnDestroy() {
        this.setLoading$?.unsubscribe();
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
