import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Appointment, Register} from "@core/_interfaces";

@Component({
    selector: 'app-telemedicine-public',
    templateUrl: './telemedicine-public.component.html',
    styleUrls: ['./telemedicine-public.component.scss'],
})
export class TelemedicinePublicComponent implements OnInit {
    id: any;

    constructor(
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('conferenceId');
        // this.id = this.route.snapshot.paramMap.get('PortalUserID');
    }

}
