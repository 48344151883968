import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '@core/_services/notification.service';
import { catchError, filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, from, iif, of, Subject, Subscription, throwError } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NotificationSettings } from '@core/_interfaces/notification-settings.model';
import { PlatformService } from '@core/_services/platform.service';
import { DeviceInfoService } from '@core/_services/device-info.service';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { StoreKeysMainEnum, StoreKeysSubEnum } from '@core/_enums/store-keys.enum';
import { StorageService } from '@core/_services/store.service';
import { FcmService } from '@core/_services/fcm.service';
import { NetworkService } from '@core/_services/network.service';
import { AlertService } from '@core/_services/alert.service';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-notifications-setting',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    providers: [AndroidPermissions, OpenNativeSettings],
})
export class NotificationsSettingsComponent implements OnInit, OnDestroy {
    // @ts-ignore
    @Input() set refresh(event: any): void {
        if (this.networkService.networkStatusChange$.value) {
            this.update$.next({
                runTime: new Date(),
                event,
            });
        } else {
            event.target.complete();
        }
    }
    public form: FormGroup = this.fb.group({
        isActiveNotifications: new FormControl(false),
        isNotificationEnabled: new FormControl(false),
        notifications: this.fb.array([]),
    });
    public update$ = new BehaviorSubject<{
        event?: any;
        runTime?: Date;
    }>({
        runTime: new Date(),
    });
    public isAvailableNotificationPermission$ = new BehaviorSubject<boolean>(false);
    public isOldAndroid$ = new BehaviorSubject<boolean>(false);
    public loading$ = new BehaviorSubject<boolean>(false);
    public isLoading$ = new Subject<boolean | null>();
    private updateSubscription: Subscription | null = null;
    public isIosPlatform = false;
    public reload$: Subscription | undefined;
    private destroyed$ = new Subject<void>();
    private destroyedToggles$ = new Subject<void>();
    constructor(
        private networkService: NetworkService,
        private notificationService: NotificationService,
        private fb: FormBuilder,
        private platformService: PlatformService,
        private androidPermissions: AndroidPermissions,
        private deviceService: DeviceInfoService,
        private storageService: StorageService,
        private cdr: ChangeDetectorRef,
        private fcmService: FcmService,
        private openNativeSettings: OpenNativeSettings,
        private alertService: AlertService,
        private store: Store<{ page: string }>,
    ) {
        this.platformService.isIosPlatform$.pipe(first()).subscribe((isIos: boolean) => (this.isIosPlatform = isIos));
    }

    public ngOnInit(): void {
        this.reload$ = this.store
            .select(store => store.page)
            .subscribe(() => {
                this.update$.next({
                    runTime: new Date(),
                });
            });

        this.platformService.resumePlatform$.pipe().subscribe(() => {
            console.warn('noti log RESUME');
            if (this.networkService.networkStatusChange$.value) {
                console.warn('noti log RESUME next');
                this.update$.next({
                    runTime: new Date(),
                });
            }
        });

        this.updateSubscription = this.update$
            .pipe(
                tap(({ event }) => console.log('noti log update$', event)),
                switchMap(({ event }) =>
                    combineLatest([
                        this.isIosPlatform
                            ? of(true)
                            : this.deviceService
                                  .getDeviceInfo()
                                  .pipe(
                                      switchMap(deviceInfo => {
                                          const wholeVersion = deviceInfo.osVersion.split('.')[0];
                                          const isCheckPermission = wholeVersion ? Number(wholeVersion) >= 13 : false;

                                          return isCheckPermission
                                              ? from(
                                                    this.androidPermissions.checkPermission(
                                                        this.androidPermissions.PERMISSION.POST_NOTIFICATIONS,
                                                    ),
                                                ).pipe(tap(() => this.isOldAndroid$.next(false)))
                                              : of({ hasPermission: true }).pipe(
                                                    tap(() => this.isOldAndroid$.next(true)),
                                                );
                                      }),
                                  )
                                  .pipe(
                                      map((result: { hasPermission: boolean }) => result.hasPermission),
                                      catchError(() => {
                                          return of(false);
                                      }),
                                  ),
                        this.storageService.getSubItem(
                            StoreKeysMainEnum.SETTINGS,
                            StoreKeysSubEnum.AVAILABLE_NOTIFICATION_PERMISSION,
                        ),
                    ]).pipe(
                        map(([appPermission, storePermission]) => {
                            console.log('noti log appPermission', appPermission);
                            console.log('noti log storePermission', storePermission);
                            this.isAvailableNotificationPermission$.next(!!appPermission && !!storePermission);

                            return { appPermission, storePermission };
                        }),
                        switchMap(({ appPermission, storePermission }) => {
                            console.log(
                                'noti log isAvailableNotificationPermission',
                                !!appPermission && !!storePermission,
                            );
                            console.log('noti log storePermission', storePermission);
                            console.log('noti log !storePermission', !storePermission);
                            this.fcmService.initPush(!storePermission);

                            return this.fcmService.notificationToken$.pipe(
                                switchMap((notificationToken: string | null) => {
                                    console.log('noti log notificationToken', notificationToken);
                                    const isNotificationEnabled = this.isIosPlatform
                                        ? !!notificationToken
                                        : !!appPermission && !!storePermission;
                                    console.log('noti log isNotificationEnabled', isNotificationEnabled);
                                    this.form.controls.isActiveNotifications.setValue(isNotificationEnabled, {
                                        emitEvent: false,
                                    });
                                    this.form.controls.isNotificationEnabled.setValue(isNotificationEnabled, {
                                        emitEvent: false,
                                    });

                                    if (!event) {
                                        this.loading$.next(true);
                                    }

                                    return isNotificationEnabled
                                        ? this.notificationService.getSubscribeList().pipe(
                                              catchError(err => {
                                                  if (event) {
                                                      event.target.complete();
                                                  }

                                                  if (!event) {
                                                      this.loading$.next(false);
                                                  }

                                                  return throwError(err);
                                              }),
                                              tap(() => {
                                                  if (event) {
                                                      event.target.complete();
                                                  }
                                                  if (!event) {
                                                      this.loading$.next(false);
                                                  }
                                              }),
                                              filter((settings: NotificationSettings[] | null) => !!settings?.length),
                                              takeUntil(this.destroyed$),
                                          )
                                        : of(null).pipe(
                                              tap(() => {
                                                  if (event) {
                                                      event.target.complete();
                                                  }
                                                  if (!event) {
                                                      this.loading$.next(false);
                                                  }
                                              }),
                                          );
                                }),
                            );
                        }),
                    ),
                ),
            )
            .subscribe(data => {
                console.log('noti log subs');
                if (data) {
                    this.destroyedToggles$.next();
                    this.form.controls.notifications = this.fb.array([]);
                    data.forEach(item => {
                        const group = new FormGroup({});
                        Object.entries(item).forEach(value => {
                            group.addControl(value[0], new FormControl(value[1]));
                        });
                        (this.form.controls.notifications as FormArray).push(group);
                        group.valueChanges
                            .pipe(takeUntil(this.destroyedToggles$))
                            .pipe(
                                switchMap((v: any) =>
                                    this.notificationService.saveSubscribeNotification(v.id, v.isActive).pipe(
                                        first(),
                                        catchError(err => {
                                            (group as FormGroup).controls.isActive.setValue(!v.isActive, {
                                                emitEvent: false,
                                            });

                                            return throwError(err);
                                        }),
                                        takeUntil(this.destroyed$),
                                    ),
                                ),
                            )
                            .subscribe(v => {
                                console.log('toggle changes', v);
                            });
                    });
                }
                this.isLoading$.next(false);
                this.cdr.detectChanges();
            });

        this.form.controls.isActiveNotifications.valueChanges
            .pipe(
                filter(v => !!v),
                switchMap(() => {
                    return iif(
                        () => this.isIosPlatform,
                        of(false),
                        from(
                            this.androidPermissions.checkPermission(
                                this.androidPermissions.PERMISSION.POST_NOTIFICATIONS,
                            ),
                        ).pipe(
                            first(),
                            catchError(() => of({ hasPermission: false })),
                            map((v: { hasPermission: boolean }) => {
                                console.log('noti log pre open alert v', v);

                                return v.hasPermission;
                            }),
                        ),
                    ).pipe(
                        switchMap((isEnabled: boolean) => {
                            if (isEnabled) {
                                return of(true);
                            }

                            return this.alertService
                                .openAlert('notification.go-to-settings', ``, '', 'ion-alert ion-alert-center', [
                                    {
                                        text: 'common.cancel',
                                        role: 'cancel',
                                        cssClass: 'btn btn-outline-blue',
                                    },
                                    {
                                        text: 'common.go-to',
                                        role: 'confirm',
                                        cssClass: 'btn btn-outline-blue fill',
                                    },
                                ])
                                .pipe(
                                    map(({ role }) => {
                                        this.form.controls.isActiveNotifications.setValue(false, {
                                            emitEvent: false,
                                        });
                                        if (role === 'confirm') {
                                            this.openAppSettings();
                                        }
                                    }),
                                );
                        }),
                    );
                }),
                takeUntil(this.destroyed$),
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
        this.updateSubscription?.unsubscribe();
        this.loading$.next(false);
    }

    public openAppSettings(): void {
        this.openNativeSettings.open('application_details').then();
    }

    public get notifications(): FormArray {
        return this.form.get('notifications') as FormArray;
    }
}
