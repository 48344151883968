import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { ApiBaseService, prefix } from '@core/_services/';
import { NotificationSettings } from '@core/_interfaces/notification-settings.model';
import { NotificationModel } from '@core/_interfaces/notification.model';
import { StoreKeysMainEnum } from '@core/_enums/store-keys.enum';
import { map, switchMap } from 'rxjs/operators';
import { DeviceInfoService } from '@core/_services/device-info.service';
import { StorageService } from '@core/_services/store.service';

@Injectable()
export class NotificationService {
    public setNewLog$ = new Subject<{ date: Date; message: string }>();
    public fcmIsActive$ = new BehaviorSubject<boolean>(false);
    constructor(
        private api: ApiBaseService,
        private deviceService: DeviceInfoService,
        private storageService: StorageService,
    ) {}

    public deviceRegistration(notificationToken: string): Observable<any> {
        return this.deviceService
            .getDeviceInfo()
            .pipe(
                switchMap(deviceInfo =>
                    this.storageService.set(StoreKeysMainEnum.DEVICE_INFO, deviceInfo).pipe(map(() => deviceInfo)),
                ),
            )
            .pipe(
                switchMap(deviceInfo =>
                    this.api.post(`${prefix}Notification/DeviceRegistration`, {
                        deviceName: `${
                            deviceInfo.manufacturer.charAt(0).toUpperCase() + deviceInfo.manufacturer.slice(1)
                        } ${deviceInfo.model} ${deviceInfo.operatingSystem} ${deviceInfo.osVersion}`,
                        notificationToken,
                    }),
                ),
            );
    }

    public getSubscribeList(): Observable<NotificationSettings[]> {
        return this.api.get(`${prefix}Notification/SubscribeList/?DeliveryTypeId=2`);
    }

    public saveSubscribeNotification(id: number, isActive: boolean): Observable<NotificationSettings[]> {
        return this.api.put(`${prefix}Notification/UpdateSubscribeNotification/${id}?isActive=${isActive}`, {});
    }

    public getNotificationsHistory(deviceToken: string): Observable<NotificationModel[]> {
        return this.api.get(`${prefix}Notification/History`, {
            deviceToken,
        });
    }
}
