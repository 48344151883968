import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, throwError } from 'rxjs';
import { AutoCompleteDataOutputType } from '../../components/search/search.service';
import { currentPage, itemsPerPage, PaginationConfig } from '@core/_interfaces';
import {
    ApiListService,
    GetDoctorsService,
    SearchDocListInterface,
    SearchOrgListInterface,
    variables,
} from '@core/_services';
import { PagesService } from '../../../pages/pages.service';
import { StorageService } from '@core/_services/store.service';
import { catchError, first } from 'rxjs/operators';

@Component({
    selector: 'app-search-container',
    templateUrl: './search-container.component.html',
    styleUrls: ['./search-container.component.scss'],
    providers: [GetDoctorsService],
})
export class SearchContainerComponent implements OnInit, OnDestroy {
    @Input() public region: boolean | undefined;
    @Input() public fromLanding: boolean | undefined;
    @Input() public option$: BehaviorSubject<string> | undefined;
    loading$ = new BehaviorSubject<boolean>(false);
    setOptions$: Subscription | undefined;
    option: string | undefined | any;
    type: 'doctor' | 'lz' | null | any = null;
    doctors$: any = new Subject();
    organizations$: any = new Subject();
    payload: any = {};
    spacing = false;
    @Output() typeChange = new EventEmitter<string | null>();

    constructor(
        private pagesService: PagesService,
        private storageService: StorageService,
        private apiListService: ApiListService,
    ) {}

    public async ngOnInit() {
        this.storageService
            .get<any>(variables.currentInstance)
            .pipe(first())
            .subscribe(currentInstance => {
                const instanceId = currentInstance?.id;
                this.payload = { instanceId };
            });

        this.setOptions$ = this.option$?.subscribe(v => {
            this.option = v;
        });
    }

    public storeData(ev: AutoCompleteDataOutputType) {
        if (ev.invalid) {
            this.option = ev.option;
            this.doctors$.next(null);
            this.organizations$.next(null);
            this.loading$.next(false);
            this.typeChange.emit(ev.option);
        } else if (ev.type === 'reset') {
            this.option = null;
            this.typeChange.emit(null);
        } else {
            this.getAutoCompleteData(ev.option as 'doctor' | 'lz');
            this.typeChange.emit(ev.option);
            this.payload = ev.req;
        }
    }

    public ngOnDestroy() {
        this.setOptions$?.unsubscribe();
    }

    public getCardsEvent($event: PaginationConfig, type: 'doctor' | 'lz') {
        if ($event?.page) {
            this.payload.page = $event.page;
        }
        if ($event?.pageSize) {
            this.payload.pageSize = $event.pageSize;
        }
        this.type = type;
        this.getAutoCompleteData(this.type);
    }

    private getAutoCompleteData(type: 'doctor' | 'lz') {
        this.option = type ? type : this.option;

        this.storageService
            .get<any>(variables.currentInstance)
            .pipe(first())
            .subscribe(currentInstance => {
                const instanceId = currentInstance?.id;
                if (!this.payload.page) {
                    this.payload.page = currentPage;
                }
                if (!this.payload.pageSize) {
                    this.payload.pageSize = itemsPerPage;
                }
                if (this.option === 'doctor') {
                    this.loading$.next(true);
                    this.apiListService
                        .searchDocList({ ...this.payload, instanceId })
                        .pipe(
                            first(),
                            catchError(err => {
                                this.loading$.next(false);
                                return throwError(err);
                            }),
                        )
                        .subscribe(async (resp: SearchDocListInterface) => {
                            this.doctors$.next(await afterLoad(resp));
                        });
                } else if (this.option === 'lz') {
                    this.loading$.next(true);
                    console.log(this.payload);
                    this.apiListService
                        .searchOrgList({ ...this.payload, instanceId })
                        .pipe(
                            first(),
                            catchError(err => {
                                this.loading$.next(false);
                                return throwError(err);
                            }),
                        )
                        .subscribe(async (resp: SearchOrgListInterface) => {
                            this.organizations$.next(await afterLoad(resp));
                        });
                }
                const afterLoad = (resp: SearchDocListInterface | SearchOrgListInterface) => {
                    return new Promise(resolve => {
                        this.spacing = !resp.data.length;
                        this.pagesService.addImages(resp.data as any, 'imageID').then((data: any) => {
                            this.loading$.next(false);
                            resolve({
                                data,
                                totalCount: resp.totalCount,
                            });
                        });
                    });
                };
            });
    }
}
