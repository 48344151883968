import {Action, createReducer, on} from '@ngrx/store';
import {hide, show} from './video.action';

export const initialState = false;

const _fullScreenState = createReducer(initialState,
    on(hide, state => false),
    on(show, state => true)
);

export function fullScreenState(state: boolean | undefined, action: Action) {
    return _fullScreenState(state, action);
}
