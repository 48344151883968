import { Injectable } from '@angular/core';

export interface DoctorInterface {
    address: string;
    divisionName: string;
    firstFree: string;
    fullName: string;
    imageID: number;
    medicalOrgId: number;
    organizationName: string;
    personalRank: string;
    room: string;
    rowNum: number;
    specialistAcdmTitle: string;
    specialistId: number;
    specialitys: string;
    totalCount: number;
    showTime?: boolean;
    stars?: boolean[];
    isOutpatient?: boolean;
}

export interface OrganizationList {
    address: string;
    divisionName: string;
    imageID: number;
    imageId?: number;
    medicalOrgId: number;
    organizationName: string;
    phones: string;
    rowNum: number;
    totalCount: number;
    workTime: string;
    image?: string;
}

export declare type SearchDocListInterface = {
    data: DoctorInterface[];
    totalCount?: number;
    resetFilter?: boolean;
};

export declare type SearchOrgListInterface = {
    data: OrganizationList[];
    totalCount?: number;
    resetFilter?: boolean;
};
export declare type OrganizationServiceType = {
    duration: number;
    notes: string;
    serviceCode: number;
    serviceId: number;
    serviceName: string;
};

@Injectable()
export class GetDoctorsService {
    constructor() {}
}
