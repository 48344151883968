import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavController, IonRouterOutlet, AlertController, IonicSafeString } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { variables, AuthenticationService, ApiListService } from '@core/_services';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PagesService } from './pages/pages.service';
import { NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReloadAction } from '@core/_effecs/reload/reload.actions';
import { BehaviorSubject, combineLatest, of, Subject, Subscription } from 'rxjs';
import { ThemeService } from './@theme/theme.service';
import { Instances } from '@core/_interfaces';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { catchError, filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { environment } from '@env/environment';
import { DownloadAppPopupComponent } from './@shared/components/download-app-popup/download-app-popup.component';
import { NotificationService } from '@core/_services/notification.service';
import { PlatformService } from '@core/_services/platform.service';
import { NotificationTypeEnum } from '@core/_enums/notification-type.enum';
import { FcmService } from '@core/_services/fcm.service';
import { PushNotifications } from '@capacitor/push-notifications';
import { StorageService } from '@core/_services/store.service';
import { NetworkService } from '@core/_services/network.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    providers: [],
})
export class AppComponent implements OnInit, OnDestroy {
    public logs: { date: Date; message: string }[] = [];
    public loading$ = new BehaviorSubject<boolean>(true);
    public loginToMis$: Subscription = new Subscription();
    public isIos = this.platformService.isIosPlatform$;
    public isAndroid = this.platformService.isAndroidPlatform$;
    @ViewChild(IonRouterOutlet) public routerOutlet: IonRouterOutlet | undefined;
    public privateClinicAtr = environment.customClinic;
    private alert!: HTMLIonAlertElement;
    private destroyed$: Subject<void> = new Subject<void>();
    private destroyedPush$: Subject<void> = new Subject<void>();

    constructor(
        // private splashScreen: SplashScreen,
        // private statusBar: StatusBar,
        private storageService: StorageService,
        private store: Store<{ page: string; receivedMISCode: any }>,
        private dialog: MatDialog,
        private authenticationService: AuthenticationService,
        private pagesService: PagesService,
        private zone: NgZone,
        private navCtrl: NavController,
        private route: Router,
        private router: ActivatedRoute,
        private themeService: ThemeService,
        private snackBar: MatSnackBar,
        private apiListService: ApiListService,
        private translate: TranslateService,
        private location: Location,
        private notificationService: NotificationService,
        private platformService: PlatformService,
        private alertController: AlertController,
        private cdr: ChangeDetectorRef,
        private fcm: FcmService,
        private networkService: NetworkService,
    ) {
        this.platformService.init();
        console.log('create store');
        this.storageService.create().pipe(first()).subscribe();
        this.networkService.init();
    }

    public async initializeApp() {
        (window as any).selectRegion = async () => {
            this.storageService
                .get<any[]>(variables.instance)
                .pipe(
                    first(),
                    switchMap(instances => {
                        if (instances?.length) {
                            return this.storageService.set(variables.currentInstance, instances[0]);
                        }
                        return of(null);
                    }),
                )
                .subscribe();
        };

        (window as any).deleteStorage = async () => {
            this.storageService.clear().pipe(first()).subscribe();
        };
    }

    public ngOnInit() {
        this.initializeApp();
        this.notificationService.setNewLog$.subscribe(log => {
            console.log(log);
            this.logs.unshift(log);
        });
        this.platformService.readyPlatform$.subscribe(async () => {
            // this.splashScreen.hide();

            // if (this.platform.is('ios')) {
            //     // this.statusBar.styleDefault();
            // }
            if (this.platformService.isAndroidPlatform$.value) {
                this.platformService.backButton().subscribeWithPriority(0, () => {
                    if (this.route.url === `/pages/appointment`) {
                        (navigator as any).app.exitApp();
                    } else {
                        this.location.back();
                    }
                });
            }

            // this.statusBar.overlaysWebView(false);
            // this.statusBar.styleLightContent();

            if (
                (this.platformService.isIosPlatform$.value || this.platformService.isAndroidPlatform$.value) &&
                !this.platformService.isMobileWebPlatform$.value
            ) {
                this.fcm.subscribeNotifications$.pipe(takeUntil(this.destroyed$)).subscribe(date => {
                    console.warn('RUN TAB subscribeNotifications$', date);
                    this.fcm.clearListeners();
                    PushNotifications.addListener('pushNotificationActionPerformed', result => {
                        console.log('noti log pushNotificationActionPerformed', result);
                        console.log(
                            'noti log pushNotificationActionPerformed result.notification?.data',
                            result.notification?.data,
                        );
                        console.log(
                            'noti log pushNotificationActionPerformed result.notification.data.entity',
                            result.notification.data.entity,
                        );
                        console.log(
                            'noti log pushNotificationActionPerformed result.notification.data.portalEntityId',
                            result.notification.data.portalEntityId,
                        );

                        if (result.notification?.data) {
                            switch (result.notification.data.entity) {
                                case NotificationTypeEnum.Analysis:
                                    if (result.notification.data?.portalEntityId) {
                                        setTimeout(() => {
                                            console.log(
                                                'noti log pushNotificationActionPerformed loginRef',
                                                this.authenticationService.loginRef,
                                            );
                                            this.notificationService.setNewLog$.next({
                                                date: new Date(),
                                                message:
                                                    'redirect analisys ' + result.notification.data?.portalEntityId,
                                            });
                                            console.log('noti log pushNotificationActionPerformed redirect');
                                            this.fcm.notificationUrl$.next(
                                                `/pages/analyzes/${result.notification.data?.portalEntityId}`,
                                            );
                                            this.route
                                                .navigate([
                                                    `pages/analyzes/${result.notification.data?.portalEntityId}`,
                                                ])
                                                .then(() => {
                                                    console.log(
                                                        'noti log pushNotificationActionPerformed redirect done',
                                                    );
                                                    this.cdr.detectChanges();
                                                });
                                        }, 500);
                                    }
                                    break;
                                case NotificationTypeEnum.Appointments:
                                    setTimeout(() => {
                                        this.notificationService.setNewLog$.next({
                                            date: new Date(),
                                            message: 'redirect analisys ' + result.notification.data?.portalEntityId,
                                        });
                                        this.route.navigateByUrl(`pages/my-appointments`);
                                    }, 500);
                                    break;
                            }
                        }
                    });
                    PushNotifications.addListener('pushNotificationReceived', notifications => {
                        console.log('noti log pushNotificationReceived', notifications);
                        switch (notifications.data?.entity) {
                            case NotificationTypeEnum.Analysis:
                                if (notifications.data?.portalEntityId) {
                                    this.presentAlert(
                                        notifications.title || '',
                                        notifications.body || '',
                                        `pages/analyzes/${notifications.data?.portalEntityId}`,
                                    );
                                }
                                break;
                            case NotificationTypeEnum.Appointments:
                                this.presentAlert(
                                    notifications.title || '',
                                    notifications.body || '',
                                    `pages/my-appointments`,
                                );
                                break;
                            default:
                                this.presentAlert(notifications.title || '', notifications.body || '');
                                break;
                        }
                    });
                });
            }
        });

        // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
        this.loginToMis$ = this.store
            .select(store => store.receivedMISCode)
            .pipe(
                filter(n => n),
                switchMap(() =>
                    this.storageService.get<any>(variables.currentPerson).pipe(
                        switchMap(currentPerson => {
                            console.log('APP PERSON SET PRE', { ...currentPerson });
                            currentPerson.isVerified = true;
                            console.log('APP PERSON SET AFTER', currentPerson);
                            return this.storageService.set(variables.currentPerson, currentPerson);
                        }),
                    ),
                ),
            )
            .subscribe(() => {
                this.openSnackBar(
                    `${this.translate.instant('code-has-been-added')}. \n${this.translate.instant(
                        'please-wait-for-and-hour',
                    )}`,
                    'Зрозуміло',
                );
                this.store.dispatch(new ReloadAction('ReloadMyUsersView'));
            });

        this.loading$.next(true);
        this.apiListService
            .getInstancesRequest()
            .pipe(first(), takeUntil(this.destroyed$))
            .subscribe(async (data: Instances[]) => {
                //@TODO refactorign this
                this.pagesService
                    .getInstances(data)
                    .pipe(first(), takeUntil(this.destroyed$))
                    .subscribe(async (instance: Instances[]) => {
                        console.warn('instance set 1', instance);
                        this.storageService.set(variables.instance, instance).pipe(first()).subscribe();
                        const misCode = this.privateClinicAtr
                            ? this.privateClinicAtr
                            : this.router.snapshot.queryParams?.misCode;
                        if (misCode) {
                            const currentInstance = data.filter(i => i.misCode === misCode);
                            console.warn('currentInstance set 2', currentInstance);
                            this.storageService.set(variables.instance, currentInstance).pipe(first()).subscribe();
                            if (currentInstance) {
                                this.authenticationService
                                    .manageCurrentInstance(currentInstance[0])
                                    .pipe(first())
                                    .subscribe();
                                this.loading$.next(false);
                            } else {
                                this.loading$.next(false);
                            }
                        } else {
                            this.storageService
                                .get<Instances>(variables.currentInstance)
                                .pipe(
                                    first(),
                                    switchMap(currentInstance => {
                                        if (currentInstance) {
                                            this.loading$.next(false);
                                            return this.authenticationService.manageCurrentInstance(currentInstance);
                                        }

                                        return this.storageService.get(variables.currentUser).pipe(
                                            first(),
                                            switchMap(currentUser => {
                                                return combineLatest([
                                                    of(currentUser),
                                                    currentUser
                                                        ? this.apiListService
                                                              .getMyPersonsRequest()
                                                              .pipe(catchError(err => of(null)))
                                                        : of(null),
                                                ]).pipe(
                                                    switchMap(([currentUser, persons]) => {
                                                        this.loading$.next(false);
                                                        if (currentUser && persons) {
                                                            //TODO refactoring this
                                                            const currentInstanceName = persons
                                                                ? persons[0]?.instances[0]
                                                                : null;
                                                            if (currentInstanceName) {
                                                                const currentInstance = data.find(
                                                                    i => i.name === currentInstanceName,
                                                                );
                                                                if (currentInstance) {
                                                                    return this.authenticationService.manageCurrentInstance(
                                                                        currentInstance,
                                                                    );
                                                                }
                                                            }
                                                        }

                                                        return of(null);
                                                    }),
                                                );
                                            }),
                                        );
                                    }),
                                )
                                .subscribe();
                        }
                    });
            });

        if (this.platformService.isMobileWebPlatform$.value && !(window.location.hash.indexOf('/declaration/') > -1)) {
            setTimeout(() => {
                this.dialog.open(DownloadAppPopupComponent, {
                    width: '100%',
                    height: 'auto',
                    maxWidth: '100vw',
                    position: { top: '0' },
                });
            }, 2000);
        }
    }

    public async presentAlert(header: string, message: string, url?: string) {
        if (this.alert) {
            await this.alert.dismiss();
        }
        this.alert = await this.alertController.create({
            header: header,
            message: new IonicSafeString(message.replaceAll('\n', '<br>')),
            buttons: [
                {
                    text: 'Закрити',
                    role: 'cancel',
                    cssClass: 'button',
                },
                {
                    text: 'Детальніше',
                    role: 'confirm',
                    cssClass: 'button button--blue',
                    handler: () => {
                        if (url) {
                            console.log('URL', url);
                            this.route.navigateByUrl(url);
                        }
                    },
                },
            ],
        });

        await this.alert.present();
    }

    public deleteToken() {
        this.storageService.remove(variables.currentUser).pipe(first()).subscribe();
    }

    public async deleteAccessToken() {
        const currentUser = await this.authenticationService.currentUserValue();
        this.storageService
            .set(variables.currentUser, { ...currentUser, accessToken: '123' })
            .pipe(first())
            .subscribe();
    }

    public deleteRegion() {
        this.storageService.remove(variables.currentInstance).pipe(first()).subscribe();
    }

    public async refresh() {}

    public heartBeat() {
        this.authenticationService.hearthBeat();
    }

    public ngOnDestroy() {
        this.loginToMis$.unsubscribe();
    }

    private openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action);
    }
}
