import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppointmentComponent } from './appointment/appointment.component';
import { MyAppointmentsComponent } from './my-appointments/my-appointments.component';
import { MakeAppointmentComponent } from './appointment/make-appointment/make-appointment.component';
import { MyDoctorComponent } from './my-doctor/my-doctor.component';
import { VisitsComponent } from './visits/visits.component';
import { VisitDetailsComponent } from './visits/visit-details/visit-details.component';
import { ImmunizationComponent } from './immunization/immunization.component';
import { ImmunizationDetailsComponent } from './immunization/immunization-details/immunization-details.component';
import { PrescriptionComponent } from './prescription/prescription.component';
import { PrescriptionDetailsComponent } from './prescription/prescription-details/prescription-details.component';
import { AnalyzesComponent } from './analyzes/analyzes.component';
import { AnalyzesDetailsComponent } from './analyzes/analyzes-details/analyzes-details.component';
import { InstrumentalResearchComponent } from './instrumental-research/instrumental-research.component';
import { InstrumentalResearchDetailsComponent } from './instrumental-research/instrumental-research-details/instrumental-research-details.component';
import { ReferralComponent } from './referral/referral.component';
import { ReferralDetailsComponent } from './referral/referral-details/referral-details.component';
import { TelemedicineComponent } from './telemedicine/telemedicine.component';
import { TelemedicineDetailsComponent } from './telemedicine/telemedicine-details/telemedicine-details.component';
import { EventsComponent } from './events/events.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { OrganizationOpenPageComponent } from './organization-open-page/organization-open-page.component';
import { MakeAppointmentServiceComponent } from './appointment/make-appointment-service/make-appointment-service.component';
import { ChatComponent } from './chat/chat.component';
import { GUIDGuard } from '@core/_helpers';
import { ClosedRenderDeclarationComponent } from './my-doctor/closed-render-declaration/closed-render-declaration.component';
import { InstanceAccessGuard } from '@core/_helpers/instance-access.guard';
import { SettingsComponent } from './settings/settings.component';
import { NotificationsComponent } from './notifications/notifications.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'appointment',
        pathMatch: 'full',
    },
    {
        path: 'appointment',
        component: AppointmentComponent,
    },
    {
        path: 'appointment/specialist/:specialistId/:medOrgId',
        component: MakeAppointmentComponent,
    },
    {
        path: 'appointment/service/:medOrgId/:serviceCode/:serviceId/:serviceName',
        component: MakeAppointmentServiceComponent,
    },
    {
        path: 'appointment/organization/:id',
        component: OrganizationOpenPageComponent,
    },
    {
        path: 'appointmentCovidImmunization/:ehealhLegalEntityID/:ehealthDivisionID',
        component: MakeAppointmentServiceComponent,
    },
    {
        path: 'my-appointments',
        canActivate: [InstanceAccessGuard],
        component: MyAppointmentsComponent,
    },
    {
        path: 'my-doctor',
        component: MyDoctorComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    {
        path: 'my-doctor/:id/:instanceId',
        component: ClosedRenderDeclarationComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    {
        path: 'visits',
        component: VisitsComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    {
        path: 'visits/:id',
        component: VisitDetailsComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    {
        path: 'immunization',
        component: ImmunizationComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    {
        path: 'immunization/:id',
        component: ImmunizationDetailsComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    {
        path: 'prescription',
        component: PrescriptionComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    {
        path: 'prescription/:id',
        component: PrescriptionDetailsComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    {
        path: 'analyzes',
        component: AnalyzesComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    {
        path: 'analyzes/:id',
        component: AnalyzesDetailsComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    {
        path: 'research',
        component: InstrumentalResearchComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    {
        path: 'research/:id',
        component: InstrumentalResearchDetailsComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    {
        path: 'referral',
        component: ReferralComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    {
        path: 'referral/:id',
        component: ReferralDetailsComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    {
        path: 'telemedicine',
        component: TelemedicineComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    {
        path: 'telemedicine/:id',
        component: TelemedicineDetailsComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    // {
    //     path: 'telemedicine/:id', component: TelemedicinePatientCallComponent,
    //     canActivate: [GUIDGuard]
    // },
    {
        path: 'events',
        component: EventsComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    {
        path: 'my-profile',
        component: MyProfileComponent,
    },
    {
        path: 'chat',
        component: ChatComponent,
        canActivate: [GUIDGuard, InstanceAccessGuard],
    },
    {
        path: 'settings',
        component: SettingsComponent,
    },
    {
        path: 'notifications',
        component: NotificationsComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PagesRoutingModule {}
