import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { BiometryType } from 'capacitor-native-biometric';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { NetworkService } from '@core/_services/network.service';
import { StorageService } from '@core/_services/store.service';
import { BiometricService } from '@core/_services/biometric.service';
import { PlatformService } from '@core/_services/platform.service';
import { AuthenticationService, variables } from '@core/_services';

@Component({
    selector: 'app-security',
    templateUrl: 'security.page.html',
    styleUrls: ['security.page.scss'],
    providers: [OpenNativeSettings],
})
export class SecurityPage implements OnInit {
    // @ts-ignore
    @Input() set refresh(event: any): void {
        if (this.networkService.networkStatusChange$.value) {
            this.update$.next({
                runTime: new Date(),
                event,
            });
        } else {
            event.target.complete();
        }
    }
    public update$ = new BehaviorSubject<{
        event?: any;
        runTime?: Date;
    }>({
        runTime: new Date(),
    });
    public biometricIsAvailable$ = new Subject<boolean>();
    public readonly biometryType = BiometryType;
    public isIosPlatform = false;
    public biometricType$ = new Subject<BiometryType>();
    public isIosPlatform$ = this.platform.isIosPlatform$;
    public form = this.fb.group({
        isBiometric: [false],
    });

    private destroyed$ = new Subject<void>();
    private resumeSubscription: Subscription | null = null;

    constructor(
        private networkService: NetworkService,
        private storageService: StorageService,
        private openNativeSettings: OpenNativeSettings,
        private biometricService: BiometricService,
        private router: Router,
        private fb: FormBuilder,
        private platform: PlatformService,
        private cdr: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
    ) {
        this.platform.isIosPlatform$.pipe(first()).subscribe((isIos: boolean) => (this.isIosPlatform = isIos));
    }

    public ngOnInit(): void {
        this.biometricService
            .getCredential()
            .pipe(map(value => !!value))
            .pipe(first(), takeUntil(this.destroyed$))
            .subscribe(isCredential => {
                this.form.controls.isBiometric.setValue(isCredential, { emitEvent: false });
            });

        this.form.controls.isBiometric.valueChanges
            .pipe(
                switchMap(isBiometric => {
                    return !isBiometric
                        ? this.biometricService
                              .deleteCredential()
                              .pipe(switchMap(() => this.storageService.set(variables.isBiometric, false)))
                        : this.storageService.remove(variables.isBiometric).pipe(
                              switchMap(() => {
                                  return this.authenticationService.openLogin('/pages/appointment', null);
                              }),
                          );
                }),
                takeUntil(this.destroyed$),
            )
            .subscribe();

        this.storageService
            .get<boolean>(variables.isBiometric)
            .pipe(first(), takeUntil(this.destroyed$))
            .subscribe(isBiometrics => {
                console.log('user setting isBiometrics', isBiometrics);
                this.form.controls.isBiometric.setValue(isBiometrics, { emitEvent: false });
            });
        this.getBiometricData();
        this.resumeSubscription = this.platform.resumePlatform$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.getBiometricData();
        });
    }

    public goToSystemSettings() {
        this.platform.isIosPlatform$.pipe(first(), takeUntil(this.destroyed$)).subscribe(isIos => {
            const settingType = isIos ? 'application_details' : 'security';
            this.openNativeSettings.open(settingType).then();
        });
    }

    public getBiometricData() {
        this.biometricService
            .biometricIsAvailableAllData()
            .pipe(
                first(),
                switchMap(value => {
                    this.biometricType$.next(value.biometryType);
                    this.biometricIsAvailable$.next(value.isAvailable);

                    return this.storageService.get(variables.isBiometricsType).pipe(
                        // switchMap(biometryType => {
                        //     return this.isIosPlatform && !biometryType
                        //         ? this.storageService
                        //               .setSubItem(
                        //                   StoreKeysSubSettingsEnum.BIOMENTRICS,
                        //                   value.isAvailable,
                        //                   StoreKeysMainEnum.SETTINGS,
                        //               )
                        //               .pipe(
                        //                   map(() =>
                        //                       this.form.controls.isBiometrics.setValue(value.isAvailable, {
                        //                           emitEvent: false,
                        //                       }),
                        //                   ),
                        //               )
                        //         : of(null);
                        // }),
                        switchMap(() => this.storageService.set(variables.isBiometricsType, value.biometryType)),
                    );
                }),
                takeUntil(this.destroyed$),
            )
            .subscribe(() => {
                this.cdr.detectChanges();
            });
    }
}
