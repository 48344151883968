import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiListService } from '@core/_services';
import {
    Appointment,
    currentPage,
    IonicRefresherType,
    itemsPerPage,
    PaginationConfig,
    Register,
} from '@core/_interfaces';
import { PagesService } from '../pages.service';
import { ConfirmDialogComponent } from '../../@shared/components/confirm-dialog/confirm-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CancelConfirmationPopupComponent } from './cancel-confirmation-popup/cancel-confirmation-popup.component';
import { Store } from '@ngrx/store';
import { BehaviorSubject, from, iif, of, Subject, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { dateFormat } from '@core/_services';
import { catchError, filter, first, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-my-appointments',
    templateUrl: './my-appointments.component.html',
    styleUrls: ['./my-appointments.component.scss'],
})
export class MyAppointmentsComponent implements OnInit, OnDestroy {
    public isActual = true;
    public config: PaginationConfig | undefined;
    public dateFormat = dateFormat;
    public appointments: Register<Appointment> | undefined;

    private loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private destroyed$: Subject<void> = new Subject<void>();

    constructor(
        private pagesService: PagesService,
        private dialog: MatDialog,
        private store: Store<{ page: string }>,
        private translate: TranslateService,
        private apiListService: ApiListService,
    ) {}

    public ngOnInit(): void {
        this.config = {
            pageSize: itemsPerPage,
            page: currentPage,
            itemsPerPage,
            currentPage,
        };
        this.store
            .select(store => store.page)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                (this.config as PaginationConfig).page = 1;
                (this.config as PaginationConfig).currentPage = 1;
                this.getAppointments(undefined, this.isActual);
            });
    }

    public doRefresh(event: IonicRefresherType): void {
        this.getAppointments(event, this.isActual);
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public getAppointments(event?: IonicRefresherType, state?: boolean): void {
        if (!event) {
            this.loading$.next(true);
        }

        this.pagesService
            .getSharedPayload()
            .pipe(
                first(),
                switchMap(sharedPayload => {
                    return this.apiListService.getMyAppointments({
                        personId: sharedPayload.personId,
                        ...this.config,
                        isActual: state || null,
                    });
                }),
                catchError(err => {
                    if (event) {
                        event?.target?.complete();
                    } else {
                        this.loading$.next(false);
                    }

                    return throwError(err);
                }),
                takeUntil(this.destroyed$),
            )
            .subscribe((data: Register<Appointment>) => {
                this.pagesService.addImages(data.data, 'imageId').then((val: any) => {
                    this.appointments = val;
                    console.log('appointments', this.appointments);
                    (this.config as PaginationConfig).totalItems = data.totalCount;
                    if (event) {
                        event?.target?.complete();
                    } else {
                        this.loading$.next(false);
                    }
                    if (this.isActual !== state) {
                        this.isActual = !this.isActual;
                    }
                });
            });
    }

    public toggle(state: boolean): void {
        this.config = {
            pageSize: itemsPerPage,
            page: currentPage,
            itemsPerPage,
            currentPage,
        };
        this.getAppointments(undefined, state);
    }

    public async cancel(item: Appointment): Promise<void> {
        iif(
            () => !!item.instanceId,
            this.apiListService
                .getInstanceConfig(item.instanceId)
                .pipe(
                    switchMap(instanceConfig =>
                        instanceConfig?.reception?.showReasonsForCancel
                            ? this.apiListService.getReasonsForCancel(item.instanceId).pipe(catchError(() => of(null)))
                            : of(null),
                    ),
                ),
            of(null),
        )
            .pipe(
                first(),
                switchMap(list =>
                    this.dialog
                        .open(ConfirmDialogComponent, {
                            width: '500px',
                            data: {
                                title: this.translate.instant('confirmation-of-canceling-appointment'),
                                list,
                            },
                            autoFocus: false,
                            backdropClass: 'blur-bg',
                        })
                        .afterClosed()
                        .pipe(
                            filter(data => !!data.action),
                            switchMap(data =>
                                this.pagesService.getSharedPayload().pipe(
                                    switchMap(payload =>
                                        this.apiListService
                                            .cancelAppointment(
                                                item.instanceId,
                                                payload.personId,
                                                item.appointmentId,
                                                data?.selectedListId,
                                            )
                                            .pipe(
                                                switchMap(() =>
                                                    this.dialog
                                                        .open(CancelConfirmationPopupComponent, {
                                                            width: '400px',
                                                        })
                                                        .afterClosed(),
                                                ),
                                            ),
                                    ),
                                ),
                            ),
                        ),
                ),
                takeUntil(this.destroyed$),
            )
            .subscribe(() => {
                this.toggle(true);
            });
    }

    public changePage(): void {
        this.getAppointments(undefined, this.isActual);
        this.pagesService.scrollToTop('app-prescriptions');
    }
}
