import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthenticationService } from '@core/_services';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { PlatformService } from '@core/_services/platform.service';
import { Observable, combineLatest } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MobileAuthGuard implements CanActivate {
    constructor(
        private pl: PlatformService,
        public dialog: MatDialog,
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        console.log('MobileAuthGuard run');
        return this.pl.readyPlatform$.pipe(
            filter(isReady => !!isReady),
            switchMap(() => {
                return combineLatest([
                    this.pl.isIosPlatform$,
                    this.pl.isAndroidPlatform$,
                    this.pl.isMobileWebPlatform$,
                ]).pipe(
                    map(([isIos, isAndroid, isMobileWeb]) => {
                        console.log('MobileAuthGuard ', isIos, isAndroid, isMobileWeb);
                        if ((isIos || isAndroid) && !isMobileWeb) {
                            this.authenticationService
                                .hearthBeat('/pages')
                                .pipe(first())
                                .subscribe(() => {
                                    this.router.navigate(['/pages']).then();
                                });

                            return false;
                        } else {
                            return true;
                        }
                    }),
                );
            }),
        );
    }
}
