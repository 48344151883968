import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    BodyType,
    currentPage,
    IonicRefresherType,
    itemsPerPage,
    PaginationConfig,
    Prescription,
    Register,
} from '@core/_interfaces';
import { BehaviorSubject, Subscription, switchMap, throwError } from 'rxjs';
import { PagesService } from '../pages.service';
import { Store } from '@ngrx/store';
import { ApiListService } from '@core/_services';
import { catchError, first } from 'rxjs/operators';

@Component({
    selector: 'app-prescription',
    templateUrl: './prescription.component.html',
    styleUrls: ['./prescription.component.scss'],
})
export class PrescriptionComponent implements OnInit, OnDestroy {
    public config: PaginationConfig = {
        pageSize: itemsPerPage,
        page: currentPage,
    };
    public data: Register<Prescription> | undefined;
    public reload$: Subscription | undefined;
    public searchForm$ = new BehaviorSubject<any>(null);
    public loading$ = new BehaviorSubject<boolean>(true);
    public configEvent$ = new BehaviorSubject<PaginationConfig | null>({
        pageSize: itemsPerPage,
        currentPage,
    });
    routerState$: Subscription | undefined;
    childPathIdentifier: any;

    constructor(
        private pagesService: PagesService,
        private store: Store<{ page: string }>,
        private apiListService: ApiListService,
    ) {}

    public ngOnInit(): void {
        // this.activatedRoute.url.subscribe((val) => {
        //     this.childPathIdentifier = val[0].path;
        //     console.log(this.childPathIdentifier);
        // });
        // this.routerState$ = this.router.events.subscribe(async (event) => {
        //     if (event instanceof NavigationEnd) {
        //         const url = event.url.split('/');
        //         const starUrl = url.slice(2);
        //         if (starUrl.length === 2) {
        //             if (starUrl[0] === this.childPathIdentifier && !isNaN(+starUrl[1] as number)) {
        //                 await this.storage.set('searchFormValueOnLeave$', this.searchForm$.value);
        //             }
        //         } else if (starUrl[0] === this.childPathIdentifier) {
        //             // this.searchForm$.next(await this.storage.get('searchFormValueOnLeave$'));
        //             // Pass To Child
        //             this.dataFromStorage$.next(await this.storage.get('searchFormValueOnLeave$'));
        //         }
        //     }
        // });
        // this.routerState$ = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        //     .subscribe(async (event) => {
        //         console.log(event.url);
        // preseption/56
        // const urlTREE =  'preseption/56'.split('/') ===> [] last elemnt '61212'
        // isNaN ==> is Not a number
        /// RESULT + / +  22344 ====> CHILD
        // console.log('router kuda idem');
        // console.log('Here is dispatcher if we go to child page');
        // console.log(val);
        // await this.storage.set('searchFormValueOnLeave$', this.searchForm$.value);
        // });
        this.reload$ = this.store
            .select(store => store.page)
            .subscribe(() => {
                this.getData();
            });
        this.searchForm$.subscribe(searchFormValue => {
            console.log(searchFormValue);
            if (searchFormValue?.fromButton) {
                this.configEvent$.next({ currentPage });
                this.getData();
            }
            if (searchFormValue?.reset) {
                this.configEvent$.next({ currentPage });
                this.getData();
            }
        });
    }

    public doRefresh(event: IonicRefresherType): void {
        this.getData(event);
    }

    public ngOnDestroy(): void {
        // this func runs when user is going to leave
        // 2. Write to localstorage
        // await this.storage.set(variables.currentPerson, user);
        this.reload$?.unsubscribe();
        this.searchForm$.unsubscribe();
        this.configEvent$.unsubscribe();
        // this.routerState$?.unsubscribe();
    }

    async getData(event?: IonicRefresherType) {
        if (!event) {
            this.loading$.next(true);
        }
        const searchPayload = this.searchForm$.getValue();

        this.pagesService
            .getSharedPayload()
            .pipe(
                first(),
                switchMap(sharedPayload => {
                    return this.apiListService.getMyPrescriptions({
                        page: this.configEvent$.getValue()?.currentPage,
                        pageSize: itemsPerPage,
                        personId: sharedPayload?.personId,
                        ...searchPayload,
                    } as BodyType);
                }),
                catchError(err => {
                    if (event) {
                        event?.target?.complete();
                    } else {
                        this.loading$.next(false);
                    }

                    return throwError(err);
                }),
            )
            .subscribe((data: Register<Prescription>) => {
                this.data = data;
                this.config.totalItems = data?.totalCount;
                if (event) {
                    event?.target?.complete();
                } else {
                    this.loading$.next(false);
                }
            });
    }

    public configEvent(ev: PaginationConfig): void {
        this.configEvent$.next(ev);
        this.getData();
    }
}
