import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { IntanceConfigModel } from '@core/_interfaces/instance-config.model';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NavInterface } from '../../pages/page.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { navLinks } from '@core/_utils/nav';
import { AuthenticationService } from '@core/_services';

@Injectable({ providedIn: 'root' })
export class InstanceAccessGuard implements CanActivate {
    private nav: NavInterface[] = navLinks;
    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private authService: AuthenticationService,
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.instanceConfig$.pipe(
            filter(config => !!config),
            map((config: IntanceConfigModel | null) => {
                const targetPage = this.nav.find(item =>
                    (route.routeConfig?.path as string).split('/').some(segment => item.link === segment),
                )?.id;

                if (!targetPage) {
                    this.router.navigate(['pages/appointment']);
                    this.showErrorMessage();

                    return true;
                }

                const isGuardRoute = Object.entries((config as IntanceConfigModel).showPages).filter(([key, value]) => {
                    return key === targetPage && !!value;
                });

                if (!isGuardRoute.length) {
                    this.router.navigate(['pages/appointment']);
                    this.showErrorMessage();

                    return true;
                }

                return !!isGuardRoute.length;
            }),
        );
    }

    private showErrorMessage() {
        this.snackBar.open(`${this.translate.instant('no-access-functional')}`, 'Зрозуміло');
    }
}
