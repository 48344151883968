import { Component } from '@angular/core';
import { GetDoctorsService, SearchDocListInterface, SearchOrgListInterface } from '@core/_services/getDoctors.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-appointment',
    templateUrl: './appointment.component.html',
    styleUrls: ['./appointment.component.scss'],
    providers: [GetDoctorsService],
})
export class AppointmentComponent {
    doctors: SearchDocListInterface | undefined | string | null = null;
    organizations: SearchOrgListInterface | undefined | string | null = null;
    autoCompleteData: any;
    lz = false;
    loading = false;
    showEmptyText = false;
    public loading$ = new BehaviorSubject<boolean>(true);

    constructor() {}
}
