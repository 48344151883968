import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';
import { defer, from, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { StoreKeysMainEnum, StoreKeysSubEnum } from '@core/_enums/store-keys.enum';

@Injectable({ providedIn: 'root' })
export class StorageService {
    private storage: Storage | null = null;
    public storageChanges$: Subject<string> = new Subject<string>();

    constructor(private storageService: Storage) {}

    public create(): Observable<any> {
        return from(this.storageService?.create()).pipe(
            tap(storage => {
                console.log('set storage', storage);
                this.storage = storage;
            }),
        );
    }

    public set(key: string, value: any): Observable<any> {
        return this.storage
            ? from(this.storage.set(key, value)).pipe(map(() => value))
            : this.create().pipe(switchMap(() => this.set(key, value)));
    }

    public setSubItem(key: StoreKeysSubEnum, value: unknown, keyParent: StoreKeysMainEnum): Observable<void | null> {
        return this.storage
            ? from(this.storage.get(keyParent)).pipe(
                  catchError(() => {
                      return of(null);
                  }),
                  switchMap(item => {
                      if (item) {
                          return from((this.storage as Storage).set(keyParent, { ...item, [key]: value }));
                      } else {
                          return from((this.storage as Storage).set(keyParent, { [key]: value }));
                      }
                  }),
                  tap(() => {
                      this.storageChanges$.next(key);
                  }),
              )
            : this.create().pipe(switchMap(() => this.setSubItem(key, value, keyParent)));
    }

    public get<T>(key: string): Observable<T> {
        return this.storage ? from(this.storage?.get(key)) : this.create().pipe(switchMap(() => this.get(key)));
    }

    public getSubItem<T>(key: StoreKeysMainEnum, subKey: StoreKeysSubEnum, log?: boolean): Observable<T> {
        if (log) {
            console.log('getSubItem ', subKey);
            console.log('this.storage ', { ...this.storage });
            this.getItem(key);
        }

        // @ts-ignore
        return this.storage
            ? from(this.storage!.get(key)).pipe(
                  tap(v => {
                      if (log) {
                          console.log('PRERERE TAP', v, subKey);
                      }
                  }),
                  map(value => {
                      if (log) {
                          console.log('getSubItem subKey map', value, subKey);
                      }
                      if (value && value[subKey]) {
                          if (log) {
                              console.log('getSubItem subKey VALUE', subKey, value[subKey]);
                          }

                          return value[subKey];
                      } else {
                          return null;
                      }
                  }),
                  tap(v => {
                      if (log) {
                          console.log('SUBITEMRESULT', v, subKey);
                      }
                  }),
              )
            : this.create().pipe(switchMap(() => this.getSubItem(key, subKey)));
    }

    async getItem(key: string) {
        const targetKey = await this.storage!.get(key);
        console.warn('getItem', key, targetKey);
    }

    public remove(key: string): Observable<void> {
        return this.storage ? from(this.storage?.remove(key)) : of();
    }

    public removeSubItem(key: StoreKeysSubEnum, keyParent: StoreKeysMainEnum): Observable<any> {
        return this.storage
            ? from(this.storage.get(keyParent)).pipe(
                  switchMap(item => {
                      if (item) {
                          delete item[key];

                          return from((this.storage as Storage).set(keyParent, item));
                      } else {
                          return of();
                      }
                  }),
              )
            : of();
    }

    public clear(): Observable<void> {
        return this.storage ? from(this.storage.clear()) : of();
    }
}
