import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ApiListService, dateFormat } from '@core/_services';
import { Appointment, currentPage, itemsPerPage, PaginationConfig, Register } from '@core/_interfaces';
import { PagesService } from '../../pages.service';
import { ConfirmDialogComponent } from '../../../@shared/components/confirm-dialog/confirm-dialog.component';
import { CancelConfirmationPopupComponent } from '../../my-appointments/cancel-confirmation-popup/cancel-confirmation-popup.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, switchMap } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { PlatformService } from '@core/_services/platform.service';
import { Jitsi } from 'capacitor-jitsi-meet';
import { variables } from '@core/_services';
import { StorageService } from '@core/_services/store.service';
import { PersonModel } from '@core/_interfaces/person.model';
@Component({
    selector: 'app-telemedicine-card',
    templateUrl: './telemedicine-card.component.html',
    styleUrls: ['./telemedicine-card.component.scss'],
})
export class TelemedicineCardComponent implements OnInit, OnChanges {
    @Input() public clickable: boolean | undefined;
    @Input() public cssClass: boolean | undefined;
    @Input() public appointments!: Register<Appointment>;
    @Output() public configEvent = new EventEmitter<PaginationConfig>();
    public dateFormat = dateFormat;
    public config: PaginationConfig = {
        itemsPerPage,
        currentPage,
    };
    public isActual = true;

    private destroyed$ = new Subject<void>();
    constructor(
        private router: Router,
        public pagesService: PagesService,
        private translate: TranslateService,
        private apiListService: ApiListService,
        private dialog: MatDialog,
        private platform: PlatformService,
        private storageService: StorageService,
    ) {}

    public ngOnInit(): void {
        this.platform.resumePlatform$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            console.log('Jitsi resumePlatform$', 'onConferenceLeft');
            this.configEvent.emit(this.config);
            Jitsi.leaveConference().then(r => {
                window.removeEventListener('onConferenceLeft', () => {});
            });
        });
    }
    public ngOnChanges(changes: SimpleChanges): void {
        (this.config as PaginationConfig).totalItems = changes?.appointments?.currentValue?.totalCount;
        (this.appointments as Register<Appointment>).data = changes?.appointments?.currentValue?.data;
        if (changes?.appointments?.currentValue?.currentPage) {
            (this.config as PaginationConfig).currentPage = changes?.appointments?.currentValue?.currentPage;
        }
    }

    public changePage(): void {
        this.configEvent.emit(this.config);
        this.pagesService.scrollToTop('app-telemedicine');
    }

    public cancel(item: Appointment): void {
        this.dialog
            .open(ConfirmDialogComponent, {
                width: '500px',
                data: { title: this.translate.instant('confirmation-of-canceling-appointment') },
                autoFocus: false,
                backdropClass: 'blur-bg',
            })
            .afterClosed()
            .subscribe(async val => {
                if (val?.action) {
                    this.pagesService
                        .getSharedPayload()
                        .pipe(
                            first(),
                            switchMap(payload => {
                                return this.apiListService.cancelAppointment(
                                    item.instanceId,
                                    payload.personId,
                                    item.appointmentId,
                                );
                            }),
                        )
                        .subscribe(() => {
                            this.dialog
                                .open(CancelConfirmationPopupComponent, {
                                    width: '400px',
                                })
                                .afterClosed()
                                .pipe(
                                    switchMap(() => {
                                        return this.pagesService.getSharedPayload().pipe(
                                            switchMap(sharedPayload => {
                                                return this.apiListService.getMyAppointments({
                                                    page: currentPage,
                                                    pageSize: itemsPerPage,
                                                    personId: sharedPayload?.personId,
                                                    isActual: this.isActual,
                                                    isTelemedicineConsultation: true,
                                                });
                                            }),
                                        );
                                    }),
                                )
                                .subscribe((data: Register<Appointment>) => {
                                    this.pagesService
                                        .addImages(data.data, 'imageId')
                                        .then((val: Register<Appointment>['data']) => {
                                            this.appointments = {
                                                data: val,
                                                totalCount: data.totalCount,
                                                currentPage: data.currentPage,
                                            };
                                        });
                                });
                        });
                }
            });
    }

    public async goToChild(item: Appointment): Promise<void> {
        const params = {
            confId: item.conferenceId,
            specName: item.fullName,
        };
        if (this.clickable) {
            if (this.platform.isIosPlatform$.value || this.platform.isAndroidPlatform$?.value) {
                this.storageService
                    .get<PersonModel>(variables.currentPerson)
                    .pipe(first(), takeUntil(this.destroyed$))
                    .subscribe(async currentPerson => {
                        const displayName = `${currentPerson?.lastName || ''} ${currentPerson?.firstName || ''} ${
                            currentPerson?.middleName || ''
                        }`;
                        Jitsi.joinConference({
                            // required parameters
                            roomName: item.conferenceId, // room identifier for the conference
                            url: 'https://jitsi.e-life.com.ua', // endpoint of the Jitsi Meet video bridge
                            featureFlags: {
                                'prejoinpage.enabled': true, // go straight to the meeting and do not show the pre-join page
                                'recording.enabled': false, // disable as it requires Dropbox integration
                                'live-streaming.enabled': false, // 'sign in on Google' button not yet functional
                                'android.screensharing.enabled': false, // experimental feature, not fully production ready
                            },
                            displayName,
                            avatarURL: 'https://medportal.ua/assets/img/telemed-doctor.svg',
                            chatEnabled: true,
                            inviteEnabled: false,
                            configOverrides: { 'p2p.enabled': false }, // see list of config overrides in the official Jitsi Meet SDK documentation
                        }).then(r => {
                            console.log('Jitsi OPEN MEET RESULT', r);
                            window.addEventListener('onConferenceLeft', async () => {
                                console.log('onConferenceLeft');
                                this.configEvent.emit(this.config);
                                window.removeEventListener('onConferenceLeft', () => {});
                            });
                            window.addEventListener('onConferenceTerminated', async () => {
                                console.log('onConferenceTerminated');
                                this.configEvent.emit(this.config);
                                window.removeEventListener('onConferenceTerminated', () => {});
                            });
                        });
                    });
            } else {
                this.router.navigate(['/pages/telemedicine/', item.appointmentId, params]).then();
            }
        }
    }
}
