import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { variables } from '@core/_services';
import { select, Store } from '@ngrx/store';
import { hide, show } from '@core/_store/video/video.action';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Analyze, Appointment, Register } from '@core/_interfaces';
import { StorageService } from '@core/_services/store.service';

@Component({
    selector: 'app-telemedicine-shared',
    templateUrl: './telemedicine-shared.component.html',
    styleUrls: ['./telemedicine-shared.component.scss'],
    providers: [],
})
export class TelemedicineSharedComponent implements OnInit {
    @Input() full: boolean | undefined;
    id: any;
    @Input() waitText: string | undefined;
    dragPosition = { x: 0, y: 0 };
    half = false;
    startedCall = false;
    fullHeight = false;
    extraClass = true;
    myEl: HTMLMediaElement | undefined;
    partnerEl: HTMLMediaElement | undefined;
    wait = false;
    interval: any;
    phone: string | undefined;
    PatientName: string | undefined;
    Service: string | undefined;
    call$: Observable<string> | undefined;
    url: any;
    // specName: string | null | undefined;
    api: any;
    userName: string | null | undefined;
    userPhone: string | null | undefined;

    constructor(
        private store: Store<{ fullScreen: string; call: string }>,
        public elRef: ElementRef,
        private storageService: StorageService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private router: Router,
    ) {}

    ngOnInit() {
        this.init();
        // this.specName = this.route.snapshot.paramMap.get('specName');
        this.id = this.route.snapshot.paramMap.get('conferenceId');
        this.userName = this.route.snapshot.paramMap.get('name');
        this.userPhone = this.route.snapshot.paramMap.get('phoneNumber');
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://jitsi.e-life.com.ua/' + this.id);
        const domain = 'jitsi.e-life.com.ua';
        const options = {
            roomName: this.id,
            height: 600,
            parentNode: document.querySelector('#user2'),
            configOverwrite: {
                disableDeepLinking: true,
            },
            interfaceConfigOverwrite: {
                filmStripOnly: true,
            },
        };
        this.api = new (window as any).JitsiMeetExternalAPI(domain, options);
        this.api.executeCommand('avatarUrl', 'https://medportal.ua/assets/img/telemed-doctor.svg');
    }

    async init() {
        this.call$ = this.store.pipe(select('call'));
        this.call$.subscribe(() => {
            this.wait = false;
            if (this.interval) {
                clearInterval(this.interval);
            }
        });
        // const personID = (await this.storage.get(variables.currentPerson))?.personId || '575757575757575757575757';
        this.myEl = this.elRef.nativeElement.querySelector('#my-video-1');
        this.partnerEl = this.elRef.nativeElement.querySelector('#partner-video-1');
        this.startedCall = true;
        this.wait = true;
        if (this.full) {
            this.Service = this.route.snapshot.params.Service;
            // this.phone = this.route.snapshot.params.Phones;
            // this.PatientName = this.route.snapshot.params.PatientName;
            // this.Service = this.route.snapshot.params.Service;
            setTimeout(() => {
                this.interval = setInterval(() => this.call$, 2000);
            }, 2500);
        }
        setTimeout(() => {
            this.wait = false;
        }, 5000);
    }

    _destroy() {
        this.startedCall = false;
        this.api.dispose();
        // this.api.executeCommand('hangup');
        this.router.navigate(['/']);
    }

    enableFullHeight() {
        this.fullHeight = true;
        this.extraClass = false;
        this.store.dispatch(show());
    }

    disableFullHeight() {
        this.extraClass = true;
        this.fullHeight = false;
        this.store.dispatch(hide());
    }

    enableOneToOne() {
        this.half = !this.half;
        this.dragPosition = { x: 0, y: 0 };
    }
}
