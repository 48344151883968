import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiListService, AuthenticationService, passwordValidators, phoneMask, phoneValidators } from '@core/_services';
import { TranslateService } from '@ngx-translate/core';
import { filterNumbersFromString } from '@core/_utils/numbers-from-string';
import { PlatformService } from '@core/_services/platform.service';
import { ModalService } from '@core/_services/modal.service';
import { RegistrationModalSecondComponent } from '../registration-modal-second/registration-modal-second.component';
import { catchError, first, switchMap } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { of, throwError } from 'rxjs';
import { NgxMaskModule } from 'ngx-mask';
import { FileOpenerService } from '@core/_services/file-opener.service';

@Component({
    selector: 'app-registration-modal-first',
    templateUrl: './registration-modal-first.component.html',
    styleUrls: ['./registration-modal-first.component.scss'],
    providers: [],
    imports: [NgxMaskModule],
    // InAppBrowser
})
export class RegistrationModalFirstComponent implements OnInit {
    public isIosPlatform$ = this.platform.isIosPlatform$;
    public form: FormGroup | undefined;
    @Input() public formData: any = null;
    public submitted = false;
    public passwordsAreNotTheSame = false;
    public phoneMask = phoneMask;
    public redirectAfterLogin = false;
    public error: string | undefined;
    public visiblePassword = false;

    constructor(
        private platform: PlatformService,
        private fb: FormBuilder,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        private apiListService: ApiListService, // private iab: InAppBrowser
        private modalService: ModalService,
        private modalCtrl: ModalController,
        private fileOpenerService: FileOpenerService,
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            login: ['', phoneValidators],
            password: ['', passwordValidators],
            passwordConfirm: ['', Validators.required],
            agree: [false, Validators.pattern('true')],
        });
        if (this.formData) {
            this.form.patchValue(this.formData, { emitEvent: false });
        }
        this.form.valueChanges.subscribe(val => {
            this.passwordsAreNotTheSame = val.password !== val.passwordConfirm;
        });
    }

    submit() {
        this.submitted = true;
        if (this.form?.valid && !this.passwordsAreNotTheSame) {
            const login = filterNumbersFromString(this.form.get('login')?.value);
            const password = this.form.get('password')?.value;
            const passwordConfirm = this.form.get('passwordConfirm')?.value;
            this.apiListService
                .isLoginAvailable(login)
                .pipe(
                    catchError(err => {
                        this.authenticationService.displayError(err, this.translate).then(er => {
                            this.error = er;
                        });
                        if (!this.error) {
                            this.error = this.translate.instant('errors.phone-is-already-in-use');
                        }

                        return throwError(err);
                    }),
                    switchMap(val => {
                        if (!val) {
                            this.error = this.translate.instant('errors.phone-is-already-in-use');
                            return of({ data: false });
                        }
                        return this.modalService
                            .openModalComponent(
                                RegistrationModalSecondComponent,
                                { login, password, passwordConfirm },
                                'no-padding-popup',
                                true,
                            )
                            .pipe(first());
                    }),
                )
                .subscribe();
        }
    }

    agree(): void {
        this.fileOpenerService.downloadLocalFile(
            `/assets/agree.pdf`,
            `${this.translate.instant('agree.file-name')}.pdf`,
        );
    }

    public openLogin(value?: any, role?: string): void {
        this.authenticationService.openLogin('/pages/appointment', value).pipe(first()).subscribe();
    }

    public closeModal(value?: any, role?: string): void {
        this.modalCtrl.dismiss(value, role || 'confirm').then();
    }

    public cancel() {
        return this.modalCtrl.dismiss(null, 'cancel');
    }
}
