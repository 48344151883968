import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ToastButton } from '@ionic/core/dist/types/components/toast/toast-interface';
import { OverlayEventDetail } from '@ionic/core';

@Injectable({ providedIn: 'root' })
export class AlertService {
    public currentAlert: HTMLIonAlertElement | null = null;
    constructor(private alertController: AlertController, private translateService: TranslateService) {}

    public openAlert(
        message: string,
        header: string = '',
        subHeader: string = '',
        cssClass: string = 'ion-alert',
        buttons?: ToastButton[],
    ): Observable<any> {
        return from(
            this.alertController.create({
                cssClass,
                header: header ? this.translateService.instant(header) : '',
                subHeader: subHeader ? this.translateService.instant(subHeader) : '',
                message: message ? this.translateService.instant(message) : '',
                buttons: buttons?.length
                    ? buttons.map(button => ({
                          ...button,
                          text: button?.text ? this.translateService.instant(button.text) : '',
                      }))
                    : [],
            }),
        ).pipe(
            switchMap(alert => {
                this.dismissAlert().then();
                this.currentAlert = alert;

                return from(alert.present()).pipe(switchMap(() => from(alert.onDidDismiss())));
            }),
        );
    }
    // public openAlertComponent(
    //     component: any,
    //     componentProps?: any,
    //     cssClass?: string,
    //     backdropDismiss?: boolean,
    // ): Observable<OverlayEventDetail> {
    //     return from(this.alertController.create({ component, componentProps, cssClass, backdropDismiss })).pipe(
    //         switchMap(alert => {
    //             this.dismissAlert().then();
    //             this.currentAlert = alert;
    //
    //             return from(alert.present()).pipe(switchMap(() => from(alert.onDidDismiss())));
    //         }),
    //     );
    // }

    public async dismissAlert() {
        if (this.currentAlert) {
            await this.currentAlert.dismiss(null, 'cancel');
        }
    }
}
