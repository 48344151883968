import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/_services';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SuccessfulAuthType } from '@core/_interfaces';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-unauthorized-rating-popup',
    templateUrl: './unauthorized-rating-popup.component.html',
    styleUrls: ['./unauthorized-rating-popup.component.scss'],
})
export class UnauthorizedRatingPopupComponent implements OnInit {
    public onClose: SuccessfulAuthType | OnCloseType = { skip: true };

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private dialogRef: MatDialogRef<UnauthorizedRatingPopupComponent>,
    ) {}

    public ngOnInit() {
        this.dialogRef.beforeClosed().subscribe(() => {
            this.dialogRef.close(this.onClose);
        });
    }

    public login() {
        console.log('unauth rating login manageLogout ');
        this.authenticationService
            .manageLogout(this.router.url)
            .pipe(first())
            .subscribe((result: SuccessfulAuthType) => {
                if (result?.successfulAuth) {
                    this.onClose = { successfulAuth: true };
                    this.dialogRef.close({ successfulAuth: true });
                }
            });
    }
}

export declare type OnCloseType = {
    skip: boolean;
};
