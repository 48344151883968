import { Component, OnInit } from '@angular/core';
import {YoutubePopupComponent} from "./youtube-popup/youtube-popup.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss'],
})
export class YoutubeComponent{

  constructor(
      public dialog: MatDialog
  ) { }

  openYoutube() {
    this.dialog.open(YoutubePopupComponent, {
      width: '80vw',
      height: '75vh',
    });
  }

}
