import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppConfig } from '@core/_services';

@Component({
    selector: 'app-agreement-popup',
    templateUrl: './agreement-popup.component.html',
    styleUrls: ['./agreement-popup.component.scss'],
})
export class AgreementPopupComponent implements OnInit {
    fileURL = AppConfig?.agreement;

    constructor(public dialogRef: MatDialogRef<AgreementPopupComponent>, public dialog: MatDialog) {}

    ngOnInit() {}
}
