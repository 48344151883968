import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AccessGUIDPopupComponent } from '../../pages/access-guid-popup/access-guid-popup.component';
import { ReloadAction } from '@core/_effecs/reload/reload.actions';
import { Store } from '@ngrx/store';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { MenuController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class GUIDGuard implements CanActivate {
    private codeRef: MatDialogRef<AccessGUIDPopupComponent> | null = null;
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private dialog: MatDialog,
        private store: Store,
        private menu: MenuController,
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return combineLatest([this.authenticationService.getPerson(), this.authenticationService.getUser()]).pipe(
            switchMap(([person, user]) => {
                if (!this.codeRef && !!user?.accessToken && !person?.isVerified) {
                    if (window.innerWidth < 992) {
                        this.menu.close('mobileMenu').then();
                    }
                    this.codeRef = this.dialog.open(AccessGUIDPopupComponent, {
                        panelClass: 'no-padding-popup',
                        width: '450px',
                        backdropClass: 'blur-bg',
                    });

                    return this.codeRef.afterClosed().pipe(
                        tap(val => {
                            if (val) {
                                this.store.dispatch(new ReloadAction(this.router.url));
                            } else {
                                this.router.navigate(['/pages']).then();
                            }
                            this.codeRef = null;
                        }),
                        map(val => !!val),
                    );
                } else {
                    return of(true);
                }
            }),
        );
    }
}
