import {Action} from '@ngrx/store';

export enum SelectInstanceActions {
    PASS_ERROR = '[PASS_ERROR] Select Instance',
    RESET_INSTANCE = '[RESET] Reset Instance'
}

export class PassError implements Action {
    readonly type = SelectInstanceActions.PASS_ERROR;

    constructor(public payload: any) {
    }
}

export type SelectInstanceType = PassError;
