import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { ApiListService, AppConfig, dateFormat, newDate } from '@core/_services';
import { AvailableInMonth, IntervalModelItem } from '@core/_interfaces';
import { DatePipe } from '@angular/common';
import { FreeIntervals } from '@core/_interfaces';
import { from, Subject } from 'rxjs';
import { getMonth, getYear, isBefore, isFuture, isWithinInterval } from 'date-fns';
import {
    AppointmentDateAndTimeService,
    AppointmentPayload,
    FreeIntervalsInterface,
    PrepareIntervalInterface,
} from './appointment-date-and-time.service';
import { environment } from '@env/environment';
import { filter, first, switchMap } from 'rxjs/operators';
import { AlertService } from '@core/_services/alert.service';

@Component({
    selector: 'app-appointment-date-and-time',
    templateUrl: './appointment-date-and-time.component.html',
    styleUrls: ['./appointment-date-and-time.component.scss'],
    providers: [DatePipe, AppointmentDateAndTimeService],
})
export class AppointmentDateAndTimeComponent implements OnInit {
    @Input('loading$') loading$: Subject<boolean> | undefined;
    @Input('data') data: AppointmentPayload | undefined;
    @Output('emitAppointment') emitAppointment = new EventEmitter<FormGroup>();
    @Output('componentChange') componentChange = new EventEmitter<boolean>();
    form: FormGroup | undefined;
    @ViewChild('dp') datePicker: MatDatepicker<Date> | undefined;
    currentDate = newDate;
    dateFormat = dateFormat;
    freeIntervals: FreeIntervalsInterface = {
        morning: [],
        day: [],
        evening: [],
    };
    allIntervalsStrings: string[] = [];
    allIntervals: IntervalModelItem[] = [];
    availableDaysInMonth = [];
    allDates: any = {};
    isEmptyDay = true;
    anotherDaySlice = 5;
    anotherDateLookup = true;
    loadingIntervals = false;
    maxDateForNSZU: Date | null = null;

    dateFilter = (d: Date) => {
        const month = getMonth(d) + 1;
        const year = getYear(d);
        const key = month + '-' + year;
        if (!this.allDates[month + 2 + '-' + year]) {
            this.getAvailableInMonth(month + 2, year);
        }
        return this.allDates[key]?.includes(this.datePipe.transform(d, 'yyyy-MM-dd') + 'T00:00:00');
    };

    constructor(
        private fb: FormBuilder,
        private datePipe: DatePipe,
        private appointmentDateAndTimeService: AppointmentDateAndTimeService,
        private apiListService: ApiListService,
        private alertService: AlertService,
    ) {}

    ngOnInit() {
        this.form = this.appointmentDateAndTimeService.initFrom(this.data?.serviceId || '');
        this.form.get('dateLookup')?.valueChanges.subscribe(val => {
            this.form?.get('date')?.patchValue(this.datePipe.transform(val, 'yyyy-MM-dd') + 'T00:00:00');
            this.GetFreeIntervalsColored();
        });
        this.getAvailableInMonth(getMonth(newDate) + 1, getYear(newDate));
        this.getAvailableInMonth(getMonth(newDate) + 2, getYear(newDate));
        this.maxDateForNSZU = this.data?.isNSZUIP
            ? new Date(newDate.setMonth(getMonth(newDate) + environment.maxMonthForNSZU))
            : null;
    }

    selectInterval(time: Date): void {
        this.componentChange.emit(true);
        this.form?.get('date')?.patchValue(time);
        this.form?.get('typeOfDay')?.patchValue(null);
        this.form?.get('time')?.patchValue(null);
        this.GetFreeIntervalsColored();
    }

    setPeriodOfDate(type: 'morning' | 'day' | 'evening') {
        this.componentChange.emit(true);
        this.form?.get('typeOfDay')?.patchValue(type);
        this.form?.get('time')?.patchValue(null);
    }

    openDatePicker() {
        if (this.anotherDaySlice === 5) {
            this.anotherDaySlice = 10;
        } else {
            this.datePicker?.open();
        }
    }

    async getAvailableInMonth(month: number, year: number) {
        const yearFromNow = new Date();
        yearFromNow.setFullYear(yearFromNow.getFullYear() + 1);
        if (
            isBefore(new Date(year, month, new Date().getDate()), yearFromNow) &&
            isFuture(new Date(year, month, new Date().getDate()))
        ) {
            const key = await this.prepareDate(month, year);
            if (!this.allDates[key]) {
                this.allDates[key] = [];
                this.apiListService
                    .getAvailableInMonthReq(this.data, key, this.data?.receptionInfo?.instanceId)
                    .pipe(first())
                    .subscribe((val: AvailableInMonth) => {
                        this.allDates[key] = val;
                    });
            }
        }
    }

    async prepareDate(month: number, year: number) {
        if (month > 12) {
            const d = Math.floor(month / 12);
            year = year + d;
            month = month - 12 * d;
            return month + '-' + year;
        } else {
            return month + '-' + year;
        }
    }

    GetFreeIntervalsColored() {
        this.componentChange.emit(true);
        this.isEmptyDay = false;
        this.loadingIntervals = true;
        this.apiListService.GetFreeIntervalsColored(this.data, this.form?.value?.date).subscribe(
            (resp: FreeIntervals) => {
                this.loadingIntervals = false;
                this.allIntervals = resp?.intervals;
                if (!resp?.intervals) {
                    this.form?.get('typeOfDay')?.patchValue(null);
                    this.form?.get('time')?.patchValue(null);
                    this.isEmptyDay = true;
                } else {
                    this.isEmptyDay = false;
                    this.appointmentDateAndTimeService
                        .prepareInterval(resp.intervals, resp.intervalLen)
                        .then((payload: PrepareIntervalInterface) => {
                            this.freeIntervals = payload.freeIntervals;
                            this.allIntervalsStrings = payload.allIntervalsStrings;
                        });
                }
            },
            () => {
                this.loadingIntervals = false;
            },
        );
    }

    setTime(time: string) {
        this.componentChange.emit(true);
        const val = this.form?.get('time')?.value;
        const targetInterval = this.allIntervals[this.allIntervalsStrings.indexOf(time)];
        this.form?.get('time')?.patchValue(val === time ? null : time);
        this.form?.get('freeInterval')?.patchValue(targetInterval?.intervalNumber);
        if (this.form?.get('time')?.value === time && targetInterval?.message) {
            this.alertService
                .openAlert(targetInterval?.message, ``, '', 'ion-alert ion-alert-center', [
                    {
                        text: 'common.ok',
                        role: 'confirm',
                        cssClass: 'btn btn-outline-blue fill button--blue fixed-btn-ok',
                    },
                ])
                .pipe(first())
                .subscribe();
        }
    }

    makeAppointment() {
        this.emitAppointment.emit(this.form);
    }
}
