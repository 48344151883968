import {
    ChangeDetectorRef,
    Directive,
    Host,
    HostListener,
    OnDestroy,
    OnInit,
    Optional,
    SimpleChange,
} from '@angular/core';
import { MaskDirective } from 'ngx-mask';
import { NgControl } from '@angular/forms';
import { filter, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { directionMask } from '@core/_services';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Directive({
    selector: '[directionMask]',
})
export class DirectionMaskDirective implements OnInit, OnDestroy {
    public defaultMask = this.translate.instant('Directions.EmptyOption');
    public emptyMask = '';
    public currentMask = this.emptyMask;
    public currentValue: string | null = null;

    private destroyed$: Subject<void> = new Subject<void>();

    @HostListener('keyup', ['$event.target.value'])
    public onKeyUp(value: string) {
        console.log('KEYUP');
        if (!value.match(/[0-9A-Za-z]/g)) {
            this.currentMask = this.emptyMask;
            this.control.control?.setValue('');
            this.updateMask(this.currentMask);
        } else if (this.currentMask !== directionMask) {
            this.currentMask = directionMask;
            this.updateMask(this.currentMask);
        }
    }

    constructor(
        private cdr: ChangeDetectorRef,
        @Host() @Optional() private mask: MaskDirective,
        private control: NgControl,
        private translate: TranslateService,
    ) {}

    public ngOnInit(): void {
        this.mask.maskExpression = this.currentMask;
        this.mask.ngOnChanges({
            maskExpression: new SimpleChange('', this.currentMask, true),
        });
        this.control.valueChanges
            ?.pipe(
                startWith(''),
                pairwise(),
                filter(([prevision, current]) => prevision !== current),
                takeUntil(this.destroyed$),
            )
            .subscribe(([_, value]) => {
                if (value === this.defaultMask && this.currentMask !== this.defaultMask) {
                    this.currentMask = this.defaultMask;
                    this.updateMask(this.currentMask);
                } else if (value !== this.defaultMask && this.currentMask !== directionMask) {
                    this.currentMask = directionMask;
                    this.updateMask(this.currentMask);
                }
            });
        this.cdr.detectChanges();
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    private updateMask(mask: string): void {
        setTimeout(() => {
            this.mask.ngOnChanges({
                maskExpression: new SimpleChange(null, mask, false),
            });
            this.control.control?.updateValueAndValidity();
        }, 50);
    }
}
