import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    ApiBaseService,
    LoginPayload,
    OrganizationList,
    OrganizationServiceType,
    prefix,
    SearchDocListInterface,
    SearchOrgListInterface,
} from '@core/_services/';
import { LoginTypeEnum } from '@core/_enums';
import {
    Analyze,
    Appointment,
    AutoCompleteData,
    AvailableInMonth,
    BodyType,
    FreeIntervals,
    Immunization,
    Instances,
    MyConsultations,
    MyDoctor,
    MyResearch,
    Prescription,
    ReceptionInfo,
    Referral,
    RefreshToken,
    Register,
    UserInterface,
} from '@core/_interfaces';
import { AppointmentPayload } from '../../@shared/components/appointment-date-and-time/appointment-date-and-time.service';
import { AutoComplete } from '../../@shared/components/search/search.service';
import { HttpHeaders } from '@angular/common/http';
import { MyPersons } from '../../pages/my-profile/my-profile.component';
import { FormGroup } from '@angular/forms';
import { IntanceConfigModel } from '@core/_interfaces/instance-config.model';
import { ReceptionCancelReasonModel } from '@core/_interfaces/reception-cancel-reason.model';

@Injectable()
export class ApiListService {
    constructor(private api: ApiBaseService) {}

    public refreshToken(currentUser: string) {
        return this.api.post<RefreshToken>(
            `${prefix}Auth/RefreshToken`,
            {},
            {
                skip: 'true',
                Authorization: `Bearer ${currentUser}`,
            },
        );
    }

    public getToken(body: LoginPayload): Observable<UserInterface> {
        return this.api.post<UserInterface>(`${prefix}Auth/token`, body);
    }

    public getPerson(returnURL?: string) {
        console.log('call getPerson');
        return this.api.get<MyPersons[]>(
            `${prefix}User/GetMyPersons`,
            new HttpHeaders({
                returnURL: returnURL || '',
            }),
        );
    }

    public isLoginAvailable(login: string): Observable<boolean> {
        return this.api.get(`${prefix}User/isLoginAvailable?login=` + login);
    }

    public resetPasswordReq(login: string, loginType: LoginTypeEnum.Email | LoginTypeEnum.Phone) {
        return this.api.get(`${prefix}User/ResetPassword?login=${login}&loginType=${loginType}`);
    }

    public setNewPassword(body: any) {
        return this.api.post(`${prefix}User/SetNewPassword`, body);
    }

    public resetPasswordAndSetEmail(login: string, email: string) {
        return this.api.post(`${prefix}User/ResetPasswordAndSetEmail`, {
            login,
            email,
        });
    }

    public searchDocList(payload: AutoCompleteData | undefined | any): Observable<SearchDocListInterface> {
        return this.api.post<SearchDocListInterface>(`${prefix}Reception/SearchDocList`, payload);
    }

    public searchOrgList(payload: AutoCompleteData | undefined | any): Observable<SearchOrgListInterface> {
        return this.api.post<SearchOrgListInterface>(`${prefix}Reception/SearchOrgList`, payload);
    }

    public GetOrganizationServices(medOrgId: number, instanceId: number): Observable<OrganizationServiceType[]> {
        return this.api.get<OrganizationServiceType[]>(
            `${prefix}Reception/GetOrganizationServices?instanceId=` + instanceId + '&medOrgId=' + medOrgId,
        );
    }

    public getOrgInfo(payload: number, id: number | undefined) {
        return this.api.get<OrganizationList>(
            `${prefix}Reception/GetOrganizationInfo?instanceId=` + payload + '&medOrgId=' + id,
        );
    }

    public getAvailableInMonthReq(
        data: AppointmentPayload | undefined,
        key: string,
        instanceId: number | undefined,
    ): Observable<AvailableInMonth> {
        return this.api.get<AvailableInMonth>(
            `${prefix}Reception/GetAvailableInMonth?instanceId=${instanceId || data?.instanceID}${
                data?.specialistId ? '&specialistId=' + data?.specialistId : ''
            }&medOrgId=${data?.medOrgId}&year=${key.split('-')[1]}&month=${key.split('-')[0]}&serviceId=${
                data?.serviceId
            }`,
        );
    }

    public getMyResearch(body: BodyType): Observable<Register<MyResearch>> {
        return this.api.post<Register<MyResearch>>(`${prefix}MedicalEvents/GetMyResearches`, body);
    }

    public getMyDoctor(body: any): Observable<MyDoctor> {
        return this.api.post<MyDoctor>(`${prefix}MedicalEvents/GetMyDoctor`, body);
    }

    public getMyDoctorDeclaration(id: number, instanceId: number, personId: number): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

        return this.api.getWithOptions(
            `${prefix}MedicalEvents/GetMyDoctorDeclaration/${id}?instanceId=${instanceId}&personId=${personId}`,
            { headers, responseType: 'text' },
        );
    }

    public HtmlToPdf(body: string): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.api.postWithOptions<any>(`${prefix}Common/HtmlToPdf`, body, {
            headers,
            responseType: 'blob' as 'json',
        });
    }

    public getAccessGuidForShareDocument(id: number, instanceId: number, personId: number): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

        return this.api.getWithOptions(
            `${prefix}MedicalEvents/GetAccessGuidForShareDocument/${id}?docType=1&instanceId=${instanceId}&personId=${personId}`,
            { headers, responseType: 'text' },
        );
    }

    public getDocumentByQr(guid: string) {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

        return this.api.getWithOptions(`${prefix}MedicalEvents/GetDocumentByQr/${guid}`, {
            headers,
            responseType: 'text',
        });
    }

    public getMyUSRTemplate(id: number, personId: number) {
        return this.api.get(`${prefix}MedicalEvents/GetMyUSRTemplate/${id}?id=${id}&personId=${personId}`);
    }

    public getMyAppointments(body: any): Observable<Register<Appointment>> {
        return this.api.post<Register<Appointment>>(`${prefix}Reception/GetMyAppointments`, body);
    }

    public getReasonsForCancel(instanceId: number): Observable<Register<ReceptionCancelReasonModel>> {
        return this.api.get<Register<ReceptionCancelReasonModel>>(
            `${prefix}Reception/ReasonsForCancel?instanceId=${instanceId}`,
        );
    }

    public cancelAppointment(instanceId: number, personId: number, appointmentId: number, cancelReasonId?: number) {
        let params = `?instanceId=${instanceId}&personId=${personId}&appointmentId=${appointmentId}`;
        if (cancelReasonId) {
            params += `&cancelReasonId=${cancelReasonId}`;
        }

        return this.api.get(`${prefix}Reception/CancelAppointment${params}`);
    }

    public registerUser(option: string, body: any): Observable<any> {
        return this.api.post<any>(
            option === 'full' ? `${prefix}User/RegisterByPhone` : `${prefix}User/RegisterByMisCode`,
            body,
        );
    }

    public GetFreeIntervalsColored(data: AppointmentPayload | undefined, date: Date) {
        return this.api.get<FreeIntervals>(
            `${prefix}Reception/GetFreeIntervalsColored${
                data?.specialistId ? '?specialistId=' + data?.specialistId : '?specialistId='
            }&medOrgId=${data?.medOrgId}&instanceId=${data?.instanceID}&serviceId=${
                data?.serviceId || 0
            }&visitDate=${date}`,
        );
    }

    public getDistrictAutoComplete(text: string, currentInstance: number): Observable<AutoComplete[]> {
        return this.api.get<AutoComplete[]>(
            `${prefix}AutoCmplt/GetDistrictsAutoCmplt?instanceId=${currentInstance}&searchText=${encodeURIComponent(
                text,
            )}`,
        );
    }

    public GetSettlementsAutoComplete(
        districtId: number | null,
        text: string,
        currentInstance: number,
    ): Observable<AutoComplete[]> {
        return this.api.get<AutoComplete[]>(
            `${prefix}AutoCmplt/GetSettlementsAutoCmplt?instanceId=${currentInstance}&searchText=${encodeURIComponent(
                text,
            )}${districtId ? '&districtId=' + districtId : ''}`,
        );
    }

    public GetOrganizationsAutoComplete(
        settlementId: number | string,
        text: string,
        currentInstance: number,
    ): Observable<AutoComplete[]> {
        return this.api.get<AutoComplete[]>(
            `${prefix}AutoCmplt/GetOrganizationsAutoCmplt?instanceId=${currentInstance}&searchText=${encodeURIComponent(
                text,
            )}${settlementId ? '&settlementId=' + settlementId : ''}`,
        );
    }

    public GetSpecialitiesAutoComplete(text: string, currentInstance: number): Observable<AutoComplete[]> {
        return this.api.get<AutoComplete[]>(
            `${prefix}AutoCmplt/GetSpecialitiesAutoCmplt?instanceId=${currentInstance}&searchText=${encodeURIComponent(
                text,
            )}`,
        );
    }

    public getServicesAutoComplete(
        text: any,
        currentInstance: number,
        medicalOrgId: number | string = '',
    ): Observable<AutoComplete[]> {
        return this.api.get<AutoComplete[]>(
            `${prefix}AutoCmplt/GetServicesAutoCmplt?instanceId=${currentInstance}&searchText=${encodeURIComponent(
                text,
            )}&organizationId=${medicalOrgId}`,
        );
    }

    public getOrgAutoComplete(text: string, currentInstance: number): Observable<AutoComplete[]> {
        return this.api.get<AutoComplete[]>(
            `${prefix}AutoCmplt/GetOrganizationsAutoCmplt?instanceId=${currentInstance}&searchText=${encodeURIComponent(
                text,
            )}`,
        );
    }

    public getInstancesRequest(): Observable<Instances[]> {
        return this.api.get<Instances[]>(
            `${prefix}Common/GetInstances`,
            new HttpHeaders({
                skip: 'true',
            }),
        );
    }

    public getMyPersonsRequest(): Observable<MyPersons[]> {
        console.log('call getMyPersonsRequest');
        return this.api.get<MyPersons[]>(`${prefix}User/GetMyPersons`);
    }

    public getMyConsultations(body: BodyType): Observable<Register<MyConsultations>> {
        return this.api.post<Register<MyConsultations>>(`${prefix}MedicalEvents/GetMyConsultations`, body);
    }

    public getMyAnalysis(body: BodyType): Observable<Register<Analyze>> {
        return this.api.post<Register<Analyze>>(`${prefix}MedicalEvents/GetMyAnalysis`, body);
    }

    public getReceptionInfoBySpecialist(
        specialistId: string | undefined,
        medOrgId: string | undefined,
        instanceID: number | string | undefined,
    ): Observable<ReceptionInfo> {
        return this.api.get<ReceptionInfo>(
            `${prefix}Reception/GetReceptionInfoBySpecialist?specialistId=${specialistId}&medOrgId=${medOrgId}&instanceId=${instanceID}`,
        );
    }

    public getMyImmunizations(body: BodyType): Observable<Register<Immunization>> {
        return this.api.post<Register<Immunization>>(`${prefix}MedicalEvents/GetMyImmunizations`, body);
    }

    public getMyDirections(body: BodyType): Observable<Register<Referral>> {
        return this.api.post<Register<Referral>>(`${prefix}MedicalEvents/GetMyDirections`, body);
    }

    public getMyPrescriptions(body: BodyType): Observable<Register<Prescription>> {
        return this.api.post<Register<Prescription>>(`${prefix}MedicalEvents/GetMyPrescriptions`, body);
    }

    public assignPersonByAccessGUID(accessGUID: string, personId: number) {
        return this.api.get(`${prefix}User/AssignPersonByAccessGUID?accessGUID=${accessGUID}&personId=${personId}`);
    }

    public createPerson(form: FormGroup['value']) {
        return this.api.post(`${prefix}User/AddPerson`, form);
    }

    public editPerson(form: FormGroup['value']) {
        return this.api.put(`${prefix}User/EditPerson/${form.personId}`, form);
    }

    public deletePerson(id: number) {
        return this.api.delete(`${prefix}User/DeletePerson/` + id);
    }

    public createAppointment(payload: any) {
        return this.api.post(`${prefix}Reception/CreateAppointment`, payload);
    }

    public getReceptionInfoCovidImmunization(ehealhLegalEntityID: string, ehealthDivisionID: string) {
        return this.api.get(
            `${prefix}Reception/GetReceptionInfoCovidImmunization?ehealhLegalEntityID=${ehealhLegalEntityID}&ehealthDivisionID=${ehealthDivisionID}`,
        );
    }

    public getReceptionInfoByService(
        instanceID: string | undefined,
        serviceId: string | undefined,
        medOrgId: string | undefined,
    ) {
        return this.api.get(
            `${prefix}Reception/GetReceptionInfoByService?instanceId=${instanceID}&serviceId=${serviceId}&medOrgId=${medOrgId}`,
        );
    }

    public getInstanceConfig(instanceId: number): Observable<IntanceConfigModel> {
        return this.api.get(`${prefix}Common/GetInstanceSettings?id=${instanceId}`);
    }
}
