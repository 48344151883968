import { Component, Input, TemplateRef } from '@angular/core';
import { LegacyTooltipComponent as TooltipComponent } from '@angular/material/legacy-tooltip';

@Component({
    selector: 'app-custom-tooltip',
    templateUrl: './custom-tooltip.component.html',
    styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomToolTipComponent extends TooltipComponent {
    @Input() public text!: string;
    @Input() public contentTemplate!: TemplateRef<any>;
}
