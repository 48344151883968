import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { BehaviorSubject, combineLatest, Subject, switchMap, throwError } from 'rxjs';
import { StorageService } from './store.service';
import { catchError, first } from 'rxjs/operators';
import { DeviceInfoService } from './device-info.service';
import { Router } from '@angular/router';
import { StoreKeysMainEnum, StoreKeysSubEnum } from '@core/_enums/store-keys.enum';
import { NotificationService } from '@core/_services/notification.service';

@Injectable({
    providedIn: 'root',
})
export class FcmService {
    public notificationUrl$ = new BehaviorSubject<string>('');
    public notificationToken$ = new Subject<string | null>();
    public subscribeNotifications$ = new BehaviorSubject<Date>(new Date());
    private destroyed$ = new Subject<void>();
    constructor(
        private router: Router,
        private storageService: StorageService,
        private deviceService: DeviceInfoService,
        private notificationService: NotificationService,
    ) {}
    public initPush(isSendFbs: boolean = false) {
        console.log('noti log FcmService initPush', Capacitor.getPlatform());
        if (Capacitor.getPlatform() !== 'web') {
            PushNotifications.removeAllListeners().then(remove => {
                console.log('noti log FcmService removed all push listeners', remove);
                this.registerPush(isSendFbs);
            });
        } else {
            setTimeout(() => {
                this.notificationToken$.next(null);
                combineLatest([
                    this.storageService.set(StoreKeysMainEnum.NOTIFICATION_TOKEN, null),
                    this.storageService.setSubItem(
                        StoreKeysSubEnum.AVAILABLE_NOTIFICATION_PERMISSION,
                        false,
                        StoreKeysMainEnum.SETTINGS,
                    ),
                ])
                    .pipe(first())
                    .subscribe(); //for testing
            }, 2000);
        }
    }

    public clearListeners() {
        PushNotifications.removeAllListeners().then(remove => {
            console.log('noti log FcmService removed all push listeners', remove);
        });
    }
    private registerPush(isSendFbs: boolean = false) {
        this.destroyed$.next();
        console.warn('noti log FcmService registerPush isSendFbs', isSendFbs);
        console.log('noti log FcmService registerPush');
        let isCallRegisterFirst = true;
        PushNotifications.addListener('registration', token => {
            console.log('noti log FcmService addListener registration', token);
            console.log('noti log FcmService addListener registration isSendFbs', isSendFbs);
            if (isCallRegisterFirst) {
                isCallRegisterFirst = false;
                this.storageService
                    .getSubItem<boolean>(StoreKeysMainEnum.SETTINGS, StoreKeysSubEnum.NEED_SEND_TOKEN)
                    .pipe(first())
                    .subscribe(isNeedSend => {
                        console.log('noti log FcmService addListener isNeedSend', isNeedSend);
                        console.log('noti log FcmService addListener isSendFbs ', isSendFbs);
                        if (isSendFbs || isNeedSend) {
                            this.notificationService
                                .deviceRegistration(token.value)
                                .pipe(
                                    switchMap(() =>
                                        combineLatest([
                                            this.storageService.set(StoreKeysMainEnum.NOTIFICATION_TOKEN, token.value),
                                            this.storageService.setSubItem(
                                                StoreKeysSubEnum.AVAILABLE_NOTIFICATION_PERMISSION,
                                                !!token.value,
                                                StoreKeysMainEnum.SETTINGS,
                                            ),
                                        ]),
                                    ),
                                    catchError(err => {
                                        if (
                                            err.error.errorCode &&
                                            err.error.errorCode[0] === 'UserDeviceAlreadyExists'
                                        ) {
                                            return combineLatest([
                                                this.storageService.set(
                                                    StoreKeysMainEnum.NOTIFICATION_TOKEN,
                                                    token.value,
                                                ),
                                                this.storageService.setSubItem(
                                                    StoreKeysSubEnum.AVAILABLE_NOTIFICATION_PERMISSION,
                                                    !!token.value,
                                                    StoreKeysMainEnum.SETTINGS,
                                                ),
                                            ]);
                                        }
                                        this.notificationToken$.next(token.value);

                                        return throwError(err);
                                    }),
                                )
                                .subscribe(() => this.notificationToken$.next(token.value));
                        } else {
                            combineLatest([
                                this.storageService.set(StoreKeysMainEnum.NOTIFICATION_TOKEN, token.value),
                                this.storageService.setSubItem(
                                    StoreKeysSubEnum.AVAILABLE_NOTIFICATION_PERMISSION,
                                    !!token.value,
                                    StoreKeysMainEnum.SETTINGS,
                                ),
                            ])
                                .pipe(first())
                                .subscribe(() => this.notificationToken$.next(token.value));
                        }
                    });
            }

            this.subscribeNotifications$.next(new Date());
        });

        PushNotifications.addListener('registrationError', err => {
            console.log('noti log FcmService PUSH registrationError err', err);
            this.notificationToken$.next(null);
        });

        PushNotifications.requestPermissions()
            .then(permission => {
                console.log('noti log FcmService registerPush permission', permission);
                if (permission.receive === 'granted') {
                    console.log('noti log FcmService registerPush run');
                    PushNotifications.register()
                        .then(val => {
                            console.log('noti log FcmService registerPush run then new build', val);
                        })
                        .catch(err => {
                            console.log('noti log FcmService registerPush run catch new build', err);
                        });
                } else {
                    this.notificationToken$.next(null);
                    combineLatest([
                        this.storageService.set(StoreKeysMainEnum.NOTIFICATION_TOKEN, null),
                        this.storageService.setSubItem(
                            StoreKeysSubEnum.AVAILABLE_NOTIFICATION_PERMISSION,
                            false,
                            StoreKeysMainEnum.SETTINGS,
                        ),
                    ])
                        .pipe(first())
                        .subscribe(); //for testing
                }
            })
            .catch(() => {
                this.notificationToken$.next(null);
                combineLatest([
                    this.storageService.set(StoreKeysMainEnum.NOTIFICATION_TOKEN, null),
                    this.storageService.setSubItem(
                        StoreKeysSubEnum.AVAILABLE_NOTIFICATION_PERMISSION,
                        false,
                        StoreKeysMainEnum.SETTINGS,
                    ),
                ])
                    .pipe(first())
                    .subscribe(); //for testing
            });
    }
}
