import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { Download, dateFormat } from '@core/_services';
import { currentPage, itemsPerPage, PaginationConfig, Register, MyConsultations } from '@core/_interfaces';
import { PagesService } from '../../../pages/pages.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-visit-card',
    templateUrl: './visit-card.component.html',
    styleUrls: ['./visit-card.component.scss'],
    providers: [Download],
    encapsulation: ViewEncapsulation.None,
})
export class VisitCardComponent implements OnInit, OnChanges {
    @Input() public clickable: boolean | undefined;
    @Input() public cssClass: boolean | undefined;
    @Input() public data!: Register<MyConsultations>;
    @Input() public searchForm$: Subject<string> | undefined;
    delay = 500;
    dateFormat = dateFormat;
    config: PaginationConfig = {
        itemsPerPage,
        currentPage,
    };
    @Output() configEvent = new EventEmitter<PaginationConfig>();

    constructor(public download: Download, private router: Router, private pagesService: PagesService) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        (this.config as PaginationConfig).totalItems = changes?.data?.currentValue?.totalCount;
        (this.data as Register<MyConsultations>).data = changes?.data?.currentValue?.data;
        if (changes?.data?.currentValue?.currentPage) {
            (this.config as PaginationConfig).currentPage = changes?.data?.currentValue?.currentPage;
        }
    }

    goToChild(id: number) {
        if (this.clickable) {
            this.router.navigate(['/pages/visit/', id]);
        }
    }

    changePage() {
        console.log('this.config');
        console.log(this.config);
        this.configEvent.emit(this.config);
        this.pagesService.scrollToTop('app-visits');
    }
}
