import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    ApiListService,
    AuthenticationService,
    emailValidator,
    emailValidators,
    passwordValidators,
    phoneMask,
    variables,
} from '@core/_services';
import { LoginTypeEnum } from '@core/_enums';
import { HttpErrorResponse } from '@angular/common/http';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { filterNumbersFromString } from '@core/_utils/numbers-from-string';
import { MaskPipe } from 'ngx-mask';
import { StorageService } from '@core/_services/store.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    providers: [MaskPipe],
})
export class ResetPasswordComponent implements OnInit {
    resetMode = false;
    form: FormGroup | undefined;
    extraEmailForm: FormGroup | undefined;
    formSetNewPassword: FormGroup | undefined;
    phoneMask = phoneMask;
    submitted = false;
    submittedNewPassword = false;
    phone: string | null | undefined;
    error = '';
    firstPart = true;
    visiblePassword = false;
    loginType: number | undefined;
    countDownTimer: number | null = null;
    callForExtraReset$ = new BehaviorSubject(false);
    public prefix = '(0';

    constructor(
        private fb: FormBuilder,
        private storageService: StorageService,
        public dialogRef: MatDialogRef<ResetPasswordComponent>,
        private translate: TranslateService,
        private authenticationService: AuthenticationService,
        private apiListService: ApiListService,
        private mask: MaskPipe,
    ) {}

    async ngOnInit() {
        this.storageService
            .get<any>(variables.login)
            .pipe(first())
            .subscribe(phone => {
                this.phone = phone;
            });
        this.form = this.fb.group({
            phone: ['', Validators.required],
        });
    }

    subscribeToForm() {
        this.form?.valueChanges.subscribe(() => {
            this.error = '';
        });
    }

    toggle(state: boolean) {
        this.extraEmailForm = undefined;
        this.submitted = false;
        this.error = '';
        if (this.resetMode !== state) {
            this.resetMode = !this.resetMode;
            if (this.resetMode) {
                this.form = this.fb.group({
                    // phone: [this.phone || '0974491132', Validators.required]
                    email: ['', emailValidators()],
                });
                this.subscribeToForm();
            } else {
                this.form = this.fb.group({
                    phone: ['', Validators.required],
                });
                this.subscribeToForm();
            }
        }
    }

    __newPasswordSharedControls() {
        return {
            password: ['', passwordValidators],
            code: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])],
        };
    }

    submit() {
        this.submitted = true;
        if (this.extraEmailForm) {
            if (this.form?.valid && this.extraEmailForm.valid) {
                setTimeout(() => {
                    this.callForExtraReset$.next(true);
                }, 700);
                this.apiListService
                    .resetPasswordAndSetEmail(
                        filterNumbersFromString(this.form?.get('phone')?.value),
                        this.extraEmailForm.get('email')?.value,
                    )
                    .subscribe(
                        () => {
                            this.callForExtraReset$.next(false);
                            this.firstPart = false;
                            this.formSetNewPassword = this.fb.group({
                                phone: [
                                    filterNumbersFromString(this.form?.get('phone')?.value) || '',
                                    Validators.required,
                                ],
                                ...this.__newPasswordSharedControls(),
                            });
                        },
                        async (err: HttpErrorResponse) => {
                            this.dialogRef.disableClose = false;
                            this.error = await this.authenticationService.displayError(err, this.translate);
                        },
                    );
            }
        } else {
            console.log(this.form);
            if (this.form?.valid) {
                const login = filterNumbersFromString(this.form?.get('phone')?.value) || this.form?.get('email')?.value;
                this.loginType = this.resetMode ? LoginTypeEnum.Email : LoginTypeEnum.Phone;
                if (this.loginType === LoginTypeEnum.Phone) {
                    this.formSetNewPassword = this.fb.group({
                        phone: [
                            login ? this.prefix + this.mask.transform(login.slice(1), phoneMask) : '',
                            Validators.required,
                        ],
                        ...this.__newPasswordSharedControls(),
                    });
                } else {
                    this.formSetNewPassword = this.fb.group({
                        email: [login || '', emailValidators()],
                        ...this.__newPasswordSharedControls(),
                    });
                }
                this.apiListService.resetPasswordReq(login, this.loginType).subscribe(
                    () => {
                        this.firstPart = false;
                    },
                    (err: HttpErrorResponse) => {
                        if (err?.error?.errorCode[0] === 'UserNotFound') {
                            this.error = this.translate.instant('errors.user-not-found');
                        } else if (err?.error?.errorCode[0] === 'WaitForNextAttempt') {
                            this.countDownTimer = +err?.error?.errorText[0];
                        } else if (err?.error?.errorCode[0] === 'NoEmailForUser') {
                            console.log('I am here');
                            this.extraEmailForm = this.fb.group({
                                email: ['', emailValidators()],
                            });
                            this.form?.get('phone')?.valueChanges.subscribe(() => {
                                this.extraEmailForm = undefined;
                            });
                        }
                    },
                );
            }
        }
    }

    submitNewPassword() {
        this.submittedNewPassword = true;
        console.log(this.formSetNewPassword);
        if (this.formSetNewPassword?.valid) {
            const phoneValue = filterNumbersFromString(this.formSetNewPassword?.get('phone')?.value);
            this.apiListService
                .setNewPassword({
                    loginType: this.loginType || LoginTypeEnum.Phone,
                    newPassword: this.formSetNewPassword.get('password')?.value,
                    resetCode: this.formSetNewPassword.get('code')?.value,
                    login:
                        this.loginType === LoginTypeEnum.Phone
                            ? phoneValue
                            : this.formSetNewPassword?.get('email')?.value,
                })
                .subscribe(
                    () => {
                        this.dialogRef.close();
                    },
                    async err => {
                        this.error = await this.authenticationService.displayError(err, this.translate);
                    },
                );
        }
    }

    handleStop(ev: any) {
        if (ev?.action === 'done') {
            this.countDownTimer = null;
        }
    }
}
