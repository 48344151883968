import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { dateFormat, emailValidator, emailValidators } from './index';
import { DatePipe } from '@angular/common';
import { MyPersons } from '../../pages/my-profile/my-profile.component';

@Injectable()
export class FormsService {
    constructor(private fb: FormBuilder, private datePipe: DatePipe) {}

    async profileForm(profile: MyPersons | undefined) {
        return this.fb.group({
            lastName: [profile?.lastName || '', Validators.required],
            firstName: [profile?.firstName || '', Validators.required],
            middleName: [profile?.middleName || '', Validators.required],
            gender: [profile?.sex || ''],
            personId: [profile?.personId || ''],
            birthday: [profile?.birthday ? this.datePipe.transform(profile.birthday, dateFormat) : ''],
            phone: [profile?.phoneNumber || '', Validators.required],
            email: [profile?.email || '', emailValidators()],
        });
    }
}
