import { Component, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, Input, OnDestroy } from '@angular/core';
import { AuthenticationService, variables } from '@core/_services';
import { Router, UrlSegment } from '@angular/router';
import { dynamicSort, PagesService } from '../../../pages/pages.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, Subject, Subscription, switchMap } from 'rxjs';
import { FilterPipe } from 'ngx-filter-pipe';
import { ReloadAction } from '@core/_effecs/reload/reload.actions';
import { ThemeService } from '../../../@theme/theme.service';
import { Instances } from '@core/_interfaces';
import { filter, first, take, takeUntil } from 'rxjs/operators';
import { StorageService } from '@core/_services/store.service';

@Component({
    selector: 'app-region-lookup',
    templateUrl: './region-lookup.component.html',
    styleUrls: ['./region-lookup.component.scss'],
})
export class RegionLookupComponent implements OnInit, OnChanges, OnDestroy {
    @Input() fromPage: string | undefined;
    @Input() extraCSS?: boolean | undefined;
    @Input() fullWidth?: boolean | undefined;
    currentInstance: Instances | undefined | null;
    instances: Instances[] | undefined;
    @Output() changedInstance = new EventEmitter<void>();
    form: FormGroup | undefined;
    reload$: Subscription | undefined;
    selectInstance$: Subscription | undefined;
    instancesAutoComplete$: Instances[] | undefined;
    shakeMe = false;
    shakeMeSubscription$: Subscription | undefined;
    private destroyed$ = new Subject<void>();

    constructor(
        private storageService: StorageService,
        private router: Router,
        private pagesService: PagesService,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private store: Store<{ page: string; selectInstance: string | null }>,
        private filter: FilterPipe,
        private themeService: ThemeService,
        private authenticationService: AuthenticationService,
    ) {}

    ngOnInit() {
        this.activatedRoute.url.subscribe((url: UrlSegment[]) => {
            (() => {
                combineLatest([
                    this.storageService.get<Instances>(variables.currentInstance),
                    this.pagesService.getInstances(),
                ])
                    .pipe(first(), takeUntil(this.destroyed$))
                    .subscribe(([currentInstance, instances]) => {
                        console.warn('instances', instances);
                        this.currentInstance = currentInstance;
                        this.instances = instances;
                        this.instancesAutoComplete$ = this.instances?.slice(0);
                        this.form = this.fb.group({
                            name: [
                                this?.currentInstance?.name || '',
                                Validators.compose([
                                    this.pagesService.valueSelected(this.instances),
                                    Validators.required,
                                ]),
                            ],
                        });
                        this.instances?.sort(dynamicSort('name'));
                        this.form.valueChanges.subscribe(async () => {
                            if (this.form?.valid) {
                                const name = this.form?.get('name')?.value;
                                if (name) {
                                    const instance = this.instances?.find((i: Instances) => {
                                        return i.name === this.form?.get('name')?.value;
                                    }) as Instances;
                                    this.selectInstance(instance);
                                } else {
                                    this.storageService
                                        .remove(variables.currentInstance)
                                        .pipe(first(), takeUntil(this.destroyed$));
                                }
                            } else {
                                this.storageService
                                    .remove(variables.currentInstance)
                                    .pipe(first(), takeUntil(this.destroyed$));
                            }
                        });
                    });
            })();
        });
        this.reload$ = this.store
            .select(store => store.page)
            .subscribe(async () => {
                this.storageService
                    .get<Instances>(variables.currentInstance)
                    .pipe(first(), takeUntil(this.destroyed$))
                    .subscribe(currentInstance => {
                        this.currentInstance = currentInstance;
                        this.form = this.fb.group({
                            name: [
                                this?.currentInstance?.name || '',
                                Validators.compose([this.pagesService.valueSelected(this.instances)]),
                            ],
                        });
                        this.__formSubscription();
                    });
            });

        this.shakeMeSubscription$ = this.store
            .select(store => store.selectInstance)
            .pipe(filter(n => !!n))
            .subscribe(async val => {
                this.storageService
                    .get<Instances>(variables.currentInstance)
                    .pipe(first(), takeUntil(this.destroyed$))
                    .subscribe(instance => {
                        if (!instance) {
                            this.shakeMe = true;
                            setTimeout(() => (this.shakeMe = false), 5000);
                        }
                    });
            });
    }

    ngOnDestroy(): void {
        this.shakeMeSubscription$?.unsubscribe();
        this.reload$?.unsubscribe();
    }

    __formSubscription() {
        this.form?.get('name')?.valueChanges.subscribe((name: string) => {
            this.instancesAutoComplete$ = this.filter.transform(this.instances as Instances[], { name });
            if (this.instancesAutoComplete$?.length === 1) {
                this.reload$?.unsubscribe();
                this.form?.get('name')?.patchValue(this.instancesAutoComplete$[0]?.name, { emitEvent: false });
                this.selectInstance(this.instancesAutoComplete$[0]);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {}

    selectInstance(instance: Instances) {
        this.authenticationService
            .manageCurrentInstance(instance)
            .pipe(
                first(),
                switchMap(() => this.storageService.set(variables.currentInstance, instance)),
                takeUntil(this.destroyed$),
            )
            .subscribe(() => {
                this.currentInstance = instance;
                this.themeService.manageMisCode(instance.misCode); // Theme
                this.instances?.sort(dynamicSort('name'));
                this.changedInstance.emit();
                this.store.dispatch(new ReloadAction(''));
            });
    }
}
