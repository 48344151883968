import {Action, createReducer, on} from '@ngrx/store';
import {changePerson, resetPerson} from './person.action';

export const initialState = '';

const _changePersonReducer = createReducer(initialState,
    on(changePerson, state => 'changePerson'),
    on(resetPerson, state => '')
);

export function changePersonReducer(state: string | undefined, action: Action) {
    return _changePersonReducer(state, action);
}
