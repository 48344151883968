import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { variables } from '@core/_services/index';
import { environment } from '@env/environment';
import { StorageService } from '@core/_services/store.service';
import { first, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PlatformService } from '@core/_services/platform.service';

@Injectable({ providedIn: 'root' })
export class AddHeaderInterceptor implements HttpInterceptor {
    constructor(private pl: PlatformService, private storageService: StorageService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.handle(req, next);
    }

    handle(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.storageService.get<any>(variables.currentInstance).pipe(
            first(),
            switchMap(instance => {
                if (
                    (this.pl.isIosPlatform$.value || this.pl.isAndroidPlatform$.value) &&
                    !this.pl.isMobileWebPlatform$.value &&
                    instance
                ) {
                    const clonedRequest = req.clone({
                        setHeaders: {
                            instanceCode: instance && instance.misCode ? instance.misCode : '',
                            applicationVersion: `${environment.applicationVersion}`,
                        },
                    });

                    return next.handle(clonedRequest);
                } else if (
                    (this.pl.isIosPlatform$.value || this.pl.isAndroidPlatform$.value) &&
                    !this.pl.isMobileWebPlatform$.value &&
                    !instance
                ) {
                    const clonedRequest = req.clone({
                        setHeaders: {
                            applicationVersion: `${environment.applicationVersion}`,
                        },
                    });

                    return next.handle(clonedRequest);
                }

                return next.handle(req);
            }),
        );
    }
}
