import { Injectable, Injector } from '@angular/core';
import {
    variables,
    AppConfig,
    datePreparation,
    dateFormat,
    prefix,
    ApiBaseService,
    emailValidators,
} from '@core/_services';
import { BehaviorSubject, combineLatest, forkJoin, from, Observable, of, Subject, throwError } from 'rxjs';
import { saveAs } from 'file-saver';
import { ToastController } from '@ionic/angular';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Instances, SharedPayload } from '@core/_interfaces';
import { Router } from '@angular/router';
import { MyPersons } from './my-profile/my-profile.component';
import { AutoComplete } from '../@shared/components/search/search.service';
import { DatePipe } from '@angular/common';
import { catchError, filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { StorageService } from '@core/_services/store.service';
import { Filesystem } from '@capacitor/filesystem';
import { AlertService } from '@core/_services/alert.service';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { FileOpenerService } from '@core/_services/file-opener.service';
import { Capacitor } from '@capacitor/core';
import { FileMimeTypesEnum } from '../@core/_enums/file-mime-types.enum';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class PagesService {
    public loadingFile$ = new BehaviorSubject<boolean>(false);

    private destroyedDownload$ = new Subject<void>();
    constructor(
        private injector: Injector,
        private storageService: StorageService,
        private router: Router,
        private fb: FormBuilder,
        private toastController: ToastController,
        private datePipe: DatePipe,
        private api: ApiBaseService,
        private openNativeSettings: OpenNativeSettings,
    ) {}

    getSharedPayload(): Observable<SharedPayload> {
        return combineLatest([
            this.storageService.get<any>(variables.currentPerson).pipe(map(currentPerson => currentPerson?.personId)),
            this.storageService.get<any>(variables.currentInstance).pipe(map(currentInstance => currentInstance?.id)),
        ]).pipe(map(([personId, instanceId]) => ({ personId, instanceId })));
    }

    getURL() {
        return of(this.router.url);
    }

    valueSelected(myArray: Location[] | AutoComplete[] | undefined): ValidatorFn | null {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            const selectedValue = c.value;
            if (!selectedValue) {
                return null;
            }
            const pickedOrNot = (myArray as Array<Location | AutoComplete>)?.filter(
                (alias: any) => alias.name === selectedValue,
            );
            if (pickedOrNot?.length > 0) {
                return null;
            } else {
                return { match: true };
            }
        };
    }

    async addImages(arrInput: any[], field: string): Promise<any> {
        // !
        return new Promise(resolve => {
            const arr = arrInput?.filter((n: any) => {
                return n[field];
            });
            if (!arr?.length) {
                resolve(arrInput);
            }
            forkJoin(arr.map(item => this.getImage(item[field], item?.instanceId)))
                .pipe(
                    first(),
                    catchError(error => of(error)),
                )
                .subscribe((val: any[]) => {
                    let counter = 0;
                    arrInput.forEach((item, index) => {
                        if (item[field]) {
                            arrInput[index].image = "url('data:image/png;base64, " + val[counter] + "')";
                            ++counter;
                        }
                    });
                    resolve(arrInput);
                });
        });
    }

    getImage(id: number | null, instanceId: number | undefined): Observable<any> {
        if (!id) {
            return of();
        }

        return this.getSharedPayload().pipe(
            switchMap(data =>
                this.api.get(
                    `${prefix}Common/GetImage?imageId=${id}&instanceId=${instanceId ? instanceId : data.instanceId}`,
                ),
            ),
        );
    }

    scrollToTop(selector: string) {
        document.querySelector(selector)?.querySelector('ion-content')?.scrollToTop();
    }

    prepareFileToDownLoad() {}

    downloadBlobFile(data: Blob | string, fileName: string) {
        if (Capacitor.getPlatform() !== 'web') {
            if (typeof data === 'string') {
                this.injector.get(FileOpenerService).writeFileData(data, fileName);
            } else {
                this.injector.get(FileOpenerService).writeFile(data, fileName);
            }
        } else {
            if (typeof data === 'string') {
                saveAs(this.dataURItoBlob(data, fileName), fileName);
            } else {
                saveAs(data, fileName);
            }
        }
    }

    getFile(id: number, fileName: string, instanceId: number, skipRequest?: string | undefined) {
        console.log('get file', id, fileName);
        this.destroyedDownload$.next();
        (Capacitor.getPlatform() !== 'web' ? from(Filesystem.checkPermissions()) : of({ publicStorage: 'granted' }))
            .pipe(
                first(),
                switchMap(permissionFile => {
                    if (permissionFile.publicStorage === 'denied') {
                        return this.injector
                            .get(AlertService)
                            .openAlert('file.empty-permission', ``, '', 'ion-alert ion-alert-center', [
                                {
                                    text: 'common.yes',
                                    role: 'confirm',
                                    cssClass: 'btn btn-outline-blue fill',
                                },
                                {
                                    text: 'common.no',
                                    role: 'cancel',
                                    cssClass: 'btn btn-outline-blue',
                                },
                            ])
                            .pipe(
                                filter(({ role }) => role === 'confirm'),
                                switchMap(() => from(this.openNativeSettings.open('application_details'))),
                            );
                    }

                    return of(true);
                }),
                filter(result => !!result),
                switchMap(() => {
                    this.loadingFile$.next(true);
                    return skipRequest
                        ? of(skipRequest)
                        : this.getSharedPayload().pipe(
                              switchMap(({ personId }) => {
                                  return this.getFileRequest(id, instanceId, personId).pipe(
                                      first(),
                                      catchError(err => {
                                          this.loadingFile$.next(false);
                                          return throwError(err);
                                      }),
                                      takeUntil(this.destroyedDownload$),
                                  );
                              }),
                          );
                }),
                takeUntil(this.destroyedDownload$),
            )
            .subscribe((response: any) => {
                this.loadingFile$.next(false);
                console.warn('FILE DOWNLOAD RESPONSE', response);
                if (Capacitor.getPlatform() !== 'web') {
                    if (typeof response === 'string') {
                        this.injector.get(FileOpenerService).writeFileData(response, fileName);
                    } else {
                        this.injector.get(FileOpenerService).writeFile(response, fileName);
                    }
                } else {
                    if (typeof response === 'string') {
                        saveAs(this.dataURItoBlob(response, fileName), fileName);
                    } else if (response?.body) {
                        saveAs(response?.body, fileName);
                    } else {
                        saveAs(response, fileName);
                    }
                }
            });
    }
    dataURItoBlob(dataURI: string, fileName: string): Blob {
        let byteString = atob(dataURI);

        let mimeString: any = fileName.slice(fileName.lastIndexOf('.') + 1);
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        let type = mimeString && FileMimeTypesEnum[mimeString] ? FileMimeTypesEnum[mimeString] : FileMimeTypesEnum.pdf;

        let blob = new Blob([ab], {
            type: type as string,
        });
        return blob;
    }
    getFileRequest(id: number, instanceId: number, personId: number) {
        return this.api.get(
            `${prefix}MedicalEvents/GetFile/${id}?id=${id}&instanceId=${instanceId}&personId=${personId}`,
        );
    }

    async __sharedToast(message: string) {
        const toast = await this.toastController.create({
            message,
            duration: 2 * 1000,
        });
        await toast.present();
    }

    personForm(person: MyPersons | null | undefined): FormGroup {
        return this.fb.group({
            lastName: [person?.lastName || '', Validators.required],
            firstName: [person?.firstName || '', Validators.required],
            middleName: [person?.middleName || '', Validators.required],
            sex: [person?.sex || null, Validators.required],
            relationDegree: [person?.relationDegree || null, Validators.required],
            instances: [person?.instances?.join(', ') || ''],
            birthday: [person?.birthday ? this.datePipe.transform(new Date(person.birthday), dateFormat) : ''],
            dateSubscription: [new BehaviorSubject('')],
            submitted: [false],
            submittedGUID: [false],
            personId: [person?.personId || null],
            email: [person?.email || null, emailValidators()],
            phoneNumber: [{ value: person?.phoneNumber || null, disabled: true }],
            accessGUID: [
                '',
                Validators.compose([
                    Validators.maxLength(variables.codeFromMisLength),
                    Validators.minLength(variables.codeFromMisLength),
                ]),
            ],
            allGUIDs: [{ value: person?.instances?.length ? person.instances.join(', ') : '', disabled: true }],
        });
    }

    getInstances(instancesReq?: Instances[]): Observable<Instances[]> {
        console.log('instancesReq', instancesReq);
        return instancesReq
            ? of(instancesReq).pipe(
                  map(instances => {
                      if (instances.length > 1) {
                          const hidden = AppConfig?.hiddenInstances?.map((x: string) => x.toUpperCase());
                          return instances?.filter(el => {
                              return !hidden.includes(el.misCode.toUpperCase());
                          });
                      }

                      return instances;
                  }),
              )
            : this.storageService.get<Instances[]>(variables.instance).pipe(
                  map(instances => {
                      if (instances.length > 1) {
                          const hidden = AppConfig?.hiddenInstances?.map((x: string) => x.toUpperCase());
                          return instances?.filter(el => {
                              return !hidden.includes(el.misCode.toUpperCase());
                          });
                      }

                      return instances;
                  }),
              );
    }
}

export declare type PrepareSearchFormType = {
    filterText: string;
    dateFrom?: string;
    dateTo?: string | null;
};

export async function prepareSearchForm(form: FormGroup): Promise<{}> {
    const { date, search } = form.value;
    if (date) {
        const d = date?.split(' - ');
        const dateFrom = datePreparation(d[0]);
        let dateTo: string;
        if (d[1]) {
            dateTo = datePreparation(d[1]);
        } else {
            dateTo = dateFrom;
        }

        return { filterText: search, dateFrom, dateTo };
    } else {
        return { filterText: search };
    }
}

export const GUIDValidator = Validators.compose([
    Validators.maxLength(variables.codeFromMisLength),
    Validators.minLength(variables.codeFromMisLength),
    Validators.required,
]);

export function dynamicSort(property: string) {
    let sortOrder = 1;
    if (property[0] === '') {
        sortOrder = -1;
        property = property.substr(1);
    }

    return (a: any, b: any) => {
        const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;

        return result * sortOrder;
    };
}

export const CustomViewForPrivateClinic = ['SER'];
