import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogModel } from './confirm-dialog.model';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
    public listControl: FormControl = new FormControl('');
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel,
    ) {
        if (data?.list) {
            this.listControl.setValidators(Validators.required);
            this.listControl.updateValueAndValidity();
        }
    }
}
