import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { AuthenticationService } from '../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesService } from '../../pages/pages.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { VersionExpiredPopupComponent } from '../../@shared/components/version-expired-popup/version-expired-popup.component';
import { PlatformService } from '@core/_services/platform.service';
import { ToastService } from '@core/_services/toast.service';

export const WhiteListErrorsCodes: string[] = [
    'ExternalError',
    'UserDeviceAlreadyExists',
    'WrongPassword',
    'UserExistsEmail',
    'PhoneValidationError',
    'WaitForNextAttempt',
];

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
    translate: any;

    constructor(
        private authenticationService: AuthenticationService,
        private activatedRoute: ActivatedRoute,
        private pagesService: PagesService,
        private router: Router,
        private store: Store,
        private toastService: ToastService,
        private injector: Injector,
        private dialog: MatDialog,
        private pl: PlatformService,
    ) {
        setTimeout(() => {
            this.translate = this.injector.get(TranslateService);
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return !req.headers.get('skip')
            ? next.handle(req).pipe(
                  catchError(err => {
                      if (err.status === 401) {
                          const returnURL = req.headers.get('returnurl');
                          if (!this.authenticationService.loginRef) {
                              this.authenticationService
                                  .manageLogout(returnURL || (this.activatedRoute.snapshot as any)?._routerState?.url)
                                  .pipe(
                                      first(),
                                      catchError(err => {
                                          return throwError(err);
                                      }),
                                  )
                                  .subscribe();
                          }
                      } else if (err?.error && err?.error?.errorCode && err?.error?.errorCode[0] === 'InvalidVersion') {
                          if (
                              (this.pl.isIosPlatform$.value || this.pl.isAndroidPlatform$.value) &&
                              !this.pl.isMobileWebPlatform$.value
                          ) {
                              this.dialog.open(VersionExpiredPopupComponent, {
                                  width: '450px',
                                  autoFocus: false,
                              });
                          }
                      } else {
                          if (!this.dialog.openDialogs.length) {
                              (async () => {
                                  if (
                                      err?.error?.errorCode &&
                                      err?.error?.errorCode[0] &&
                                      !WhiteListErrorsCodes.includes(err?.error?.errorCode[0])
                                  ) {
                                      console.log('err call Code');
                                      const english = /^[A-Za-z0-9]*$/;
                                      if (english.test('errors.' + err?.error?.errorCode[0])) {
                                          this.toastService
                                              .openToast(JSON.stringify(err), 'danger')
                                              .pipe(first())
                                              .subscribe();
                                      } else {
                                          console.log('err call 2');
                                          const message = await this.authenticationService.displayError(
                                              err,
                                              this.translate,
                                          );
                                          this.toastService.openToast(message, 'danger').pipe(first()).subscribe();
                                      }
                                  } else if (!err?.error?.errorCode && err?.error[Object.keys(err?.error)[0]]) {
                                      console.log('err call 1');
                                      this.toastService
                                          .openToast(err?.error[Object.keys(err?.error)[0]][0], 'danger')
                                          .pipe(first())
                                          .subscribe();
                                  }
                              })();
                          }
                      }

                      return throwError(err);
                  }),
              )
            : next.handle(req).pipe(
                  catchError(err => {
                      return throwError(err);
                  }),
              );
    }
}
