import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    BodyType,
    currentPage,
    IonicRefresherType,
    itemsPerPage,
    PaginationConfig,
    Referral,
    Register,
} from '@core/_interfaces';
import { BehaviorSubject, Subscription, switchMap, throwError } from 'rxjs';
import { PagesService } from '../pages.service';
import { Store } from '@ngrx/store';
import { ApiListService } from '@core/_services';
import { catchError, first } from 'rxjs/operators';

@Component({
    selector: 'app-referral',
    templateUrl: './referral.component.html',
    styleUrls: ['./referral.component.scss'],
})
export class ReferralComponent implements OnInit, OnDestroy {
    public config: PaginationConfig = {
        pageSize: itemsPerPage,
        page: currentPage,
    };
    public data: Register<Referral> | undefined;
    public reload$: Subscription | undefined;
    public searchForm$ = new BehaviorSubject<any>(null);
    public configEvent$ = new BehaviorSubject<PaginationConfig | null>({
        pageSize: itemsPerPage,
        currentPage,
    });

    public loading$ = new BehaviorSubject<boolean>(true);

    constructor(
        private pagesService: PagesService,
        private store: Store<{ page: string }>,
        private apiListService: ApiListService,
    ) {}

    public ngOnInit() {
        this.reload$ = this.store
            .select(store => {
                console.log(store.page);

                return store.page;
            })
            .subscribe(() => {
                console.log('I am here');
                this.getData();
            });
        this.searchForm$.subscribe(searchFormValue => {
            if (searchFormValue?.fromButton) {
                this.configEvent$.next({ currentPage });
                this.getData();
            }
            if (searchFormValue?.reset) {
                this.configEvent$.next({ currentPage });
                this.getData();
            }
        });
    }

    public doRefresh(event: IonicRefresherType) {
        this.getData(event);
    }

    public ngOnDestroy() {
        this.reload$?.unsubscribe();
        this.searchForm$.unsubscribe();
        this.configEvent$.unsubscribe();
    }

    public async getData(event?: IonicRefresherType) {
        if (!event) {
            this.loading$.next(true);
        }
        console.log(this.configEvent$.getValue()?.currentPage);
        const searchPayload = this.searchForm$.getValue();
        this.pagesService
            .getSharedPayload()
            .pipe(
                first(),
                switchMap(sharedPayload => {
                    return this.apiListService.getMyDirections({
                        page: this.configEvent$.getValue()?.currentPage,
                        pageSize: itemsPerPage,
                        personId: sharedPayload?.personId,
                        ...searchPayload,
                    } as BodyType);
                }),
                catchError(err => {
                    if (event) {
                        event?.target?.complete();
                    } else {
                        this.loading$.next(false);
                    }

                    return throwError(err);
                }),
            )
            .subscribe((data: Register<Referral>) => {
                this.data = data;
                this.config.totalItems = data?.totalCount;
                if (event) {
                    event?.target?.complete();
                } else {
                    this.loading$.next(false);
                }
            });
    }

    public configEvent(ev: PaginationConfig) {
        console.log(ev);
        this.configEvent$.next(ev);
        this.getData();
    }
}
