import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialog as MatDialog,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { FormGroup } from '@angular/forms';
import { ConfirmDialogComponent } from '../../../@shared/components/confirm-dialog/confirm-dialog.component';
import { BehaviorSubject, switchMap, throwError } from 'rxjs';
import { catchError, filter, first } from 'rxjs/operators';
import { ApiListService, AuthenticationService, datePreparation } from '@core/_services';
import { TranslateService } from '@ngx-translate/core';
import { PagesService } from '../../pages.service';
import { SexEnum } from '@core/_enums';
import { ToastController } from '@ionic/angular';
import { ReloadAction } from '@core/_effecs/reload/reload.actions';
import { Store } from '@ngrx/store';
import { PersonSavePayload } from '@core/_interfaces';
import { MyPersons } from '../my-profile.component';
import { ToastService } from '@core/_services/toast.service';

@Component({
    selector: 'app-person-popup',
    templateUrl: './person-popup.component.html',
    styleUrls: ['./person-popup.component.scss'],
})
export class PersonPopupComponent implements OnInit {
    @Input() mainUser?: FormGroup;
    form: FormGroup | undefined;
    sexEnum = SexEnum;
    personSaveText: string | boolean = false;
    public loading$ = new BehaviorSubject<boolean>(false);
    public submittedGUIDError$ = new BehaviorSubject(false);

    constructor(
        private dialog: MatDialog,
        @Optional() private dialogRef: MatDialogRef<PersonPopupComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: FormGroup | null,
        private translate: TranslateService,
        public pagesService: PagesService,
        private authenticationService: AuthenticationService,
        private toastController: ToastController,
        private store: Store,
        private apiListService: ApiListService,
        private toastService: ToastService,
    ) {}

    ngOnInit() {
        this.form = this.data || this.mainUser || this.pagesService.personForm(null);
        this.__afterFormInit();
    }

    __afterFormInit() {
        if (this.mainUser) {
            this.form?.removeControl('relationDegree');
        } else {
            this.form?.removeControl('phoneNumber');
            this.form?.removeControl('email');
        }
        this.form?.get('accessGUID')?.valueChanges.subscribe(() => {
            this.submittedGUIDError$.next(false);
        });
    }

    deletePerson(person: FormGroup) {
        const id = person.get('personId')?.value;
        if (id) {
            this.dialog
                .open(ConfirmDialogComponent, {
                    width: '500px',
                    data: { title: this.translate.instant('confirmation-person-delete') },
                    autoFocus: false,
                    backdropClass: 'blur-bg',
                })
                .afterClosed()
                .subscribe(async val => {
                    if (val?.action) {
                        this.apiListService
                            .deletePerson(person.value.personId)
                            .pipe(
                                catchError(err => {
                                    this.authenticationService.displayError(err, this.translate).then(error => {
                                        this.chooseTextAfterAction(error);
                                    });

                                    return throwError(err);
                                }),
                            )
                            .subscribe(() => {
                                this.dialog.closeAll();
                                this.pagesService.__sharedToast(`Пов'язана особа успішно видалена`);
                            });
                    }
                });
        } else {
            this.dialog.closeAll();
        }
    }

    preSubmit(person: FormGroup) {
        person.get('submitted')?.patchValue(true);
        person.get('dateSubscription')?.value.next({ submitted: true });
        if (person.valid) {
            (person.get('dateSubscription')?.value as BehaviorSubject<any>)
                .pipe(
                    filter(i => i instanceof FormGroup),
                    first(),
                )
                .subscribe(f => {
                    if (f.valid) {
                        const personId = person.get('personId')?.value;
                        const payload = {
                            birthday: datePreparation(f.value?.birthday),
                            firstName: person.get('firstName')?.value,
                            lastName: person.get('lastName')?.value,
                            email: person.get('email')?.value,
                            middleName: person.get('middleName')?.value,
                            relationDegree: person.get('relationDegree')?.value,
                            sex: person.get('sex')?.value,
                            personId,
                        };
                        if (personId) {
                            this.__editPerson(payload);
                        } else {
                            this.__createPerson(payload);
                        }
                    }
                });
        }
    }

    __createPerson(payload: PersonSavePayload) {
        this.apiListService.createPerson(payload).subscribe((personId: any) => {
            this.form?.get('personId')?.patchValue(personId);
            this.chooseTextAfterAction(`Успішно збережено`);
            this.store.dispatch(new ReloadAction('ReloadMyUsersView'));
        });
    }

    __editPerson(payload: PersonSavePayload) {
        this.apiListService.editPerson(payload).subscribe(() => {
            this.chooseTextAfterAction(`Успішно збережено`);
            this.store.dispatch(new ReloadAction('ReloadMyUsersView'));
        });
    }

    chooseTextAfterAction(text: string) {
        this.personSaveText = text;
        setTimeout(() => {
            this.personSaveText = false;
        }, 5 * 1000);
    }

    submitPersonGUID(person: FormGroup) {
        person.patchValue({ submittedGUID: true });
        if (person.get('accessGUID')?.valid && person.get('accessGUID')?.value?.length) {
            const { accessGUID, personId } = person.value;
            this.loading$.next(true);
            this.apiListService
                .assignPersonByAccessGUID(accessGUID, personId)
                .pipe(
                    first(),
                    catchError(err => {
                        this.submittedGUIDError$.next(true);
                        this.loading$.next(false);

                        return throwError(err);
                    }),
                    switchMap(resp => {
                        return this.toastService
                            .openToast(
                                `${this.translate.instant('code-has-been-added')}<br>${this.translate.instant(
                                    'please-wait-for-and-hour',
                                )}`,
                            )
                            .pipe(switchMap(() => this.apiListService.getMyPersonsRequest()));
                    }),
                )
                .subscribe(resp => {
                    this.loading$.next(false);
                    const p = resp.find((i: MyPersons) => i.personId === person.get('personId')?.value);
                    this.form = this.pagesService.personForm(p);
                    this.__afterFormInit();
                });
        }
    }
}
