import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { currentPage, itemsPerPage, MyResearch, PaginationConfig, Register } from '@core/_interfaces';
import { PagesService } from '../../../pages/pages.service';
import { ApiListService, dateFormat } from '@core/_services';
import { BehaviorSubject, Subject, switchMap, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

@Component({
    selector: 'app-instrumental-research-card',
    templateUrl: './instrumental-research-card.component.html',
    styleUrls: ['./instrumental-research-card.component.scss'],
})
export class InstrumentalResearchCardComponent implements OnChanges {
    @Input() public clickable: boolean | undefined;
    @Input() public cssClass: boolean | undefined;
    @Input() public data: Register<MyResearch> | undefined;
    @Output() public configEvent = new EventEmitter<PaginationConfig>();
    @Input() public searchForm$: Subject<string> | undefined;
    public dateFormat = dateFormat;
    public config: PaginationConfig = {
        itemsPerPage,
        currentPage,
    };

    public loading$ = new BehaviorSubject<boolean>(false);

    constructor(public pagesService: PagesService, private apiListService: ApiListService) {}

    public ngOnChanges(changes: SimpleChanges) {
        (this.config as PaginationConfig).totalItems = changes?.data?.currentValue?.totalCount;
        if (this.data) {
            (this.data as Register<MyResearch>).data = changes?.data?.currentValue?.data;
        }

        if (changes?.data?.currentValue?.currentPage) {
            (this.config as PaginationConfig).currentPage = changes?.data?.currentValue?.currentPage;
        }
    }

    public changePage() {
        this.configEvent.emit(this.config);
        this.pagesService.scrollToTop('app-instrumental-research');
    }

    public getFileResearch(item: MyResearch, event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.loading$.next(true);
        this.pagesService
            .getSharedPayload()
            .pipe(
                first(),
                switchMap(sharedPayload => {
                    return this.apiListService.getMyUSRTemplate(item.researchId, sharedPayload?.personId);
                }),
                catchError(err => {
                    this.loading$.next(false);

                    return throwError(err);
                }),
            )
            .subscribe((va: any) => {
                console.log(va);
                this.loading$.next(false);
                const lastDotIndex = item.resultFileName.lastIndexOf('.');
                const fileName =
                    lastDotIndex >= 0 ? item.resultFileName.slice(0, lastDotIndex) : item.resultFileName + '.pdf';
                this.pagesService.getFile(
                    0,
                    fileName?.length > 0 ? fileName + '.pdf' : item.resultFileName,
                    item.instanceId,
                    va as string,
                );
            });
    }
}
