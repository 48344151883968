import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from '@core/_services';
import { StorageService } from '@core/_services/store.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-download-app',
    templateUrl: './download-app.component.html',
    styleUrls: ['./download-app.component.scss'],
})
export class DownloadAppComponent implements OnInit {
    androidApp = AppConfig?.androidApp;
    iosApp = AppConfig?.iosApp;

    constructor(
        private alertController: AlertController,
        private translate: TranslateService,
        private storageService: StorageService,
    ) {}

    async ngOnInit() {
        this.storageService
            .get<any>('instanceConfig')
            .pipe(first())
            .subscribe(instanceConfig => {
                this.androidApp = instanceConfig?.appStoreLinks.android
                    ? instanceConfig.appStoreLinks.android
                    : this.androidApp;

                this.iosApp = instanceConfig?.appStoreLinks.ios ? instanceConfig.appStoreLinks.ios : this.iosApp;
            });
    }

    async presentProgress() {
        const alert = await this.alertController.create({
            subHeader: this.translate.instant('app-under-development'),
            buttons: [this.translate.instant('clearly')],
        });

        await alert.present();
    }

    openIosApp() {
        window.open(this.iosApp, '_blank');
    }

    openGoogleApp() {
        window.open(this.androidApp, '_blank');
    }
}
