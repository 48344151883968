import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    currentPage,
    IonicRefresherType,
    itemsPerPage,
    MyConsultations,
    PaginationConfig,
    Register,
} from '@core/_interfaces';
import { PagesService } from '../pages.service';
import { BehaviorSubject, Subject, Subscription, switchMap, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiListService } from '@core/_services';
import { catchError, first, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-visits',
    templateUrl: './visits.component.html',
    styleUrls: ['./visits.component.scss'],
})
export class VisitsComponent implements OnInit, OnDestroy {
    public config: PaginationConfig | undefined;
    public data: Register<MyConsultations> | undefined;
    public reload$: Subscription | undefined;
    public searchForm$ = new BehaviorSubject<any>(null);
    public loading$ = new BehaviorSubject<boolean>(true);
    public destroyed$ = new Subject<void>();
    public configEvent$ = new BehaviorSubject<PaginationConfig | null>({
        pageSize: itemsPerPage,
        currentPage,
    });

    constructor(
        private pagesService: PagesService,
        private store: Store<{ page: string }>,
        private apiListService: ApiListService,
    ) {}

    public ngOnInit(): void {
        this.reload$ = this.store
            .select(store => store.page)
            .subscribe(() => {
                this.getMyConsultations();
            });
        this.searchForm$.subscribe(searchFormValue => {
            if (searchFormValue?.fromButton) {
                this.configEvent$.next({ currentPage });
                this.getMyConsultations();
            }
            if (searchFormValue?.reset) {
                this.configEvent$.next({ currentPage });
                this.getMyConsultations();
            }
        });
    }

    public doRefresh(event: IonicRefresherType): void {
        this.getMyConsultations(event);
    }

    public ngOnDestroy(): void {
        this.reload$?.unsubscribe();
        this.searchForm$.unsubscribe();
        this.configEvent$.unsubscribe();
    }

    public async getMyConsultations(event?: IonicRefresherType): Promise<void> {
        if (!event) {
            this.loading$.next(true);
        }
        const searchPayload = this.searchForm$.getValue();
        this.pagesService
            .getSharedPayload()
            .pipe(
                first(),
                switchMap(sharedPayload => {
                    return this.apiListService.getMyConsultations({
                        page: this.configEvent$.getValue()?.currentPage,
                        pageSize: itemsPerPage,
                        personId: sharedPayload?.personId,
                        ...searchPayload,
                    });
                }),
                catchError(err => {
                    if (event) {
                        event?.target?.complete();
                    } else {
                        this.loading$.next(false);
                    }

                    return throwError(err);
                }),
                takeUntil(this.destroyed$),
            )
            .subscribe((data: Register<MyConsultations>) => {
                this.pagesService
                    .addImages(data.data, 'specialistImageId')
                    .then((resp: Register<MyConsultations>['data']) => {
                        this.data = {
                            data: resp,
                            totalCount: data?.totalCount,
                            currentPage: data.currentPage,
                        };
                        console.log(data.totalPages);
                        if (event) {
                            event?.target?.complete();
                        } else {
                            this.loading$.next(false);
                        }
                    })
                    .catch(() => {
                        if (event) {
                            event?.target?.complete();
                        } else {
                            this.loading$.next(false);
                        }
                    });
            });
    }

    public configEvent(ev: PaginationConfig): void {
        this.configEvent$.next(ev);
        this.getMyConsultations();
    }
}
