import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { catchError, filter, first, switchMap, tap } from 'rxjs/operators';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { ToastService } from './toast.service';
import { AlertService } from './alert.service';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { HttpClient } from '@angular/common/http';
import { FileMimeTypesEnum } from '@core/_enums/file-mime-types.enum';

@Injectable({
    providedIn: 'root',
})
export class FileOpenerService {
    public loadingFile$ = new BehaviorSubject<boolean>(false);
    constructor(
        private http: HttpClient,
        private fileOpener: FileOpener,
        private toastService: ToastService,
        private alertService: AlertService,
        private openNativeSettings: OpenNativeSettings,
    ) {}

    public checkPermissions(): Observable<boolean> {
        return from(Filesystem.checkPermissions()).pipe(
            switchMap(permissionFile => {
                if (permissionFile.publicStorage === 'denied') {
                    return this.alertService
                        .openAlert('file.empty-permission', ``, '', 'ion-alert ion-alert-center', [
                            {
                                text: 'common.yes',
                                role: 'confirm',
                                cssClass: 'btn btn-outline-blue fill',
                            },
                            {
                                text: 'common.no',
                                role: 'cancel',
                                cssClass: 'btn btn-outline-blue',
                            },
                        ])
                        .pipe(
                            filter(({ role }) => role === 'confirm'),
                            switchMap(() => from(this.openNativeSettings.open('application_details'))),
                        );
                }

                return of(true);
            }),
        );
    }
    public downloadLocalFile(src: string, fileName: string): void {
        this.checkPermissions()
            .pipe(
                first(),
                filter(result => !!result),
                tap(() => this.loadingFile$.next(true)),
                switchMap(() =>
                    this.http.get(src, { responseType: 'blob', observe: 'response' }).pipe(
                        catchError(err => {
                            this.loadingFile$.next(false);

                            return throwError(err);
                        }),
                    ),
                ),
            )
            .subscribe(data => {
                this.loadingFile$.next(false);
                this.writeFile(data, fileName);
            });
    }
    public writeFile(response: any, fileName: string, dir: Directory = Directory.Data) {
        if (response instanceof Blob) {
            const reader = new FileReader();
            reader.readAsDataURL(response);
            reader.onloadend = () => {
                const base64data = reader.result;
                this.writeFileData(base64data as string, fileName, dir);
            };
        } else {
            const keyName = 'filename=';
            fileName = fileName || 'file.pdf';
            const headerContentDisposition = response.headers.get('content-disposition') as string;
            if (headerContentDisposition) {
                const startPosition = headerContentDisposition.indexOf(keyName);
                fileName = headerContentDisposition.substring(
                    startPosition + keyName.length,
                    headerContentDisposition.indexOf(';', startPosition),
                );
            }
            const reader = new FileReader();
            reader.readAsDataURL(response.body);
            reader.onloadend = () => {
                const base64data = reader.result;
                this.writeFileData(base64data as string, fileName, dir);
            };
        }
    }

    public writeFileData(data: string, fileName: string, dir: Directory = Directory.Data, postFix?: number) {
        fileName = postFix ? fileName.split('.')[0] + postFix + '.' + fileName.split('.')[1] : fileName;
        try {
            Filesystem.writeFile({
                path: fileName,
                data,
                directory: dir,
            })
                .then(writeFileResult => {
                    console.log('openFileData writeFileResult', writeFileResult);

                    return Filesystem.getUri({
                        directory: dir,
                        path: fileName,
                    });
                })
                .then(
                    getUriResult => {
                        console.log('openFileData getUriResult', getUriResult);
                        const path = getUriResult.uri;
                        const fileType: any = fileName.split('.')[1];

                        return this.fileOpener.open(path, FileMimeTypesEnum[fileType]);
                    },
                    error => {
                        console.log('openFileData error getUriResult', error);
                        if (!!postFix && postFix < 5) {
                            this.writeFileData(data, fileName, dir, postFix ? ++postFix : 1);
                        }
                    },
                )
                .then(() => {
                    console.log('openFileData File is opened');
                })
                .catch((error: any) => {
                    console.log('openFileData Error openening file', error);
                    this.toastService.openToast('errors.open-file', 'danger').pipe(first()).subscribe();
                });
        } catch (error) {
            console.error('openFileData Unable to write file', error);
            this.toastService.openToast('errors.write-file', 'danger').pipe(first()).subscribe();
        }
    }
}
