export enum NotificationTypeEnum {
    Analysis = 'Analysis',
    Researches = 'Researches',
    Immunizations = 'Immunizations',
    Consultations = 'Consultations',
    Organizations = 'Organizations',
    Appointments = 'Appointments',
    Directions = 'Directions',
    Prescriptions = 'Prescriptions',
    Info = 'Info',
    Advertisement = 'Advertisement',
}
