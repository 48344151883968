import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-map-popup',
    templateUrl: './map-popup.component.html',
    styleUrls: ['./map-popup.component.scss'],
})
export class MapPopupComponent implements OnInit {
    // private map: ol.Map | undefined;

    constructor() {}

    ngOnInit() {
        // this.map = new ol.Map({
        //     layers: [
        //         new ol.layer.Tile({source: new ol.source.OSM()}),
        //     ],
        //     target: document.getElementById('map') as HTMLElement,
        //     view: new ol.View({
        //         center: ol.proj.transform([-0.12755, 51.507222], 'EPSG:4326', 'EPSG:3857'),
        //         zoom: 3
        //     })
        // });
    }
}
