import { Injectable } from '@angular/core';
import { AvailableResult, BiometryType, Credentials, NativeBiometric } from 'capacitor-native-biometric';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, of } from 'rxjs';
import { catchError, map, tap, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BiometricService {
    constructor(private translate: TranslateService) {}

    public getCredential(server: string = environment.apiUrl): Observable<Credentials | boolean> {
        return this.biometricIsAvailable().pipe(
            catchError(() => of(false)),
            switchMap(isAvailable => {
                return isAvailable
                    ? from(
                          NativeBiometric.getCredentials({
                              server,
                          }),
                      ).pipe(catchError(() => of(false)))
                    : of(false);
            }),
        );
    }

    public openBiometric(
        reason: string = 'auth.biometric.reason-ios',
        title: string = 'auth.biometric.title-android',
        subtitle: string = 'auth.biometric.subtitle-android',
        description: string = 'auth.biometric.description-android',
        negativeButtonText: string = 'auth.biometric.negative-btn-android',
    ): Observable<any> {
        return this.biometricIsAvailable().pipe(
            catchError(() => of(false)),
            switchMap(isAvailable => {
                return isAvailable
                    ? from(
                          NativeBiometric.verifyIdentity({
                              reason: this.translate.instant(reason),
                              title: this.translate.instant(title),
                              subtitle: this.translate.instant(subtitle),
                              description: this.translate.instant(description),
                              negativeButtonText: this.translate.instant(negativeButtonText),
                          }),
                      ).pipe(catchError(() => of(false)))
                    : of(false);
            }),
        );
    }

    public biometricIsAvailable(): Observable<AvailableResult | boolean> {
        return from(NativeBiometric.isAvailable()).pipe(
            map(({ isAvailable }) => !!isAvailable),
            catchError(() => of(false)),
        );
    }

    public biometricIsAvailableAllData(): Observable<AvailableResult> {
        // @ts-ignore
        return from(NativeBiometric.isAvailable()).pipe(
            catchError(() =>
                of({
                    isAvailable: false,
                    biometryType: BiometryType.NONE,
                    errorCode: 1,
                }),
            ),
        );
    }

    public deleteCredential(): Observable<any> {
        return this.biometricIsAvailable().pipe(
            switchMap(isAvailable => {
                return isAvailable
                    ? from(
                          NativeBiometric.deleteCredentials({
                              server: environment.apiUrl,
                          }),
                      )
                    : of(false);
            }),
        );
    }

    public setCredential(username: string, password: string, server: string = environment.apiUrl): Observable<any> {
        return from(
            NativeBiometric.setCredentials({
                username,
                password,
                server,
            }),
        ).pipe(catchError(() => of(null)));
    }
}
