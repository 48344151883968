import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { ApiListService, AppConfig, variables } from '@core/_services';
import { Subject } from 'rxjs';
import { catchError, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { StorageService } from '@core/_services/store.service';
import { PlatformService } from '@core/_services/platform.service';

@Component({
    selector: 'app-download-app-popup',
    templateUrl: './download-app-popup.component.html',
    styleUrls: ['./download-app-popup.component.scss'],
})
export class DownloadAppPopupComponent implements OnInit, OnDestroy {
    public androidApp = AppConfig?.androidApp;
    public iosApp = AppConfig?.iosApp;

    private destroyed$ = new Subject<void>();

    constructor(
        private translate: TranslateService,
        private platform: PlatformService,
        private storageService: StorageService,
        private apiListService: ApiListService,
    ) {}

    public ngOnInit(): void {
        this.storageService
            .get(variables.currentInstance)
            .pipe(
                switchMap((currentInstance: any) =>
                    currentInstance?.id
                        ? this.apiListService.getInstanceConfig(currentInstance?.id).pipe(
                              tap(instanceConfig =>
                                  this.storageService
                                      .set(variables.instanceConfig, instanceConfig)
                                      .pipe(first())
                                      .subscribe(),
                              ),
                              catchError(() => this.storageService.get(variables.instanceConfig)),
                          )
                        : this.storageService.get(variables.instanceConfig),
                ),
                first(),
                takeUntil(this.destroyed$),
            )
            .subscribe((instanceConfig: any) => {
                this.androidApp = instanceConfig?.appStoreLinks.android
                    ? instanceConfig.appStoreLinks.android
                    : this.androidApp;

                this.iosApp = instanceConfig?.appStoreLinks.ios ? instanceConfig.appStoreLinks.ios : this.iosApp;

                if (this.platform.isIosPlatform$.value) {
                    const androidBtn = document.querySelector('.android-btn') as HTMLElement;
                    androidBtn.remove();
                } else if (this.platform.isAndroidPlatform$.value) {
                    const iosBtn = document.querySelector('.ios-btn') as HTMLElement;
                    iosBtn.remove();
                }
            });
    }

    public openIosApp(): void {
        window.open(this.iosApp, '_blank');
    }

    public openGoogleApp(): void {
        window.open(this.androidApp, '_blank');
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
