import {Component, OnInit} from '@angular/core';
import {year} from 'src/app/@core/_services';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    year = year;

    constructor() {
    }

    ngOnInit() {
    }

}
