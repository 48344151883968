import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService, variables } from '@core/_services';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Instances } from '@core/_interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { StorageService } from '@core/_services/store.service';

@Component({
    selector: 'app-auth-entery',
    templateUrl: './auth-entery.component.html',
    styleUrls: ['./auth-entery.component.scss'],
})
export class AuthEnteryComponent implements OnInit, OnDestroy {
    public currentInstance: Instances | undefined | null;
    public reload$: Subscription | undefined;
    public loading$ = new BehaviorSubject<boolean>(true);
    public customTheme = false;

    private destroyed$ = new Subject<void>();
    constructor(
        private storageService: StorageService,
        private store: Store<{ page: string }>,
        private route: Router,
        private router: ActivatedRoute,
        private authenticationService: AuthenticationService,
    ) {}

    public async ngOnInit(): Promise<void> {
        if (this.router.snapshot.queryParams?.guid) {
            this.authenticationService
                .openAuthPopupByLink(this.router.snapshot.queryParams)
                .pipe(
                    first(),
                    tap(() => {
                        const queryParams: { misCode?: string } = {};
                        if (this.router.snapshot.queryParams.misCode) {
                            queryParams.misCode = this.router.snapshot.queryParams.misCode;
                        }
                        this.route.navigate([], { queryParams, replaceUrl: true, relativeTo: this.router });
                    }),
                    filter(value => !!value),
                    switchMap(value => {
                        return this.authenticationService.openLogin('/pages/appointment', value);
                    }),
                    takeUntil(this.destroyed$),
                )
                .subscribe();
        }

        this.reload$ = this.store
            .select(store => store.page)
            .subscribe(async data => {
                this.loading$.next(true);
                this.storageService
                    .get<any>(variables.currentInstance)
                    .pipe(first())
                    .subscribe(currentInstance => {
                        this.currentInstance = currentInstance;
                        this.afterLoading(this.currentInstance);
                    });
            });
    }

    public afterLoading(currentInstance: Instances | null | undefined): void {
        this.loading$.next(false);
        // this.customTheme = this.customViewForPrivateClinic.includes(currentInstance?.misCode);
    }

    public ngOnDestroy(): void {
        this.reload$?.unsubscribe();
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
