import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {dateFormat} from '@core/_services';


export interface AppointmentPopupInterface {
    header: string;
    fullName?: string;
    time?: string;
    date?: string;
}

@Component({
    selector: 'app-appointment-popup',
    templateUrl: './appointment-popup.component.html',
    styleUrls: ['./appointment-popup.component.scss'],
})

export class AppointmentPopupComponent implements OnInit {
    dateFormat = dateFormat;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: AppointmentPopupInterface,
        public dialogRef: MatDialogRef<AppointmentPopupComponent>
    ) {
    }

    ngOnInit() {
    }

}
