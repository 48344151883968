import {Action, createReducer, on} from '@ngrx/store';
import { wantMISCode} from './assign-person.action';


export const initialState = false;

const waitMISCode = createReducer(initialState,
    on(wantMISCode, state => true),
);

export function waitMISCodeReducer(state: any, action: Action) {
    return waitMISCode(state, action);
}
