import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { PagesRoutingModule } from './pages-routing.module';
import { PageComponent } from './page.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ThemeModule } from '../@theme/theme.module';
import { AppointmentComponent } from './appointment/appointment.component';
import { SharedModule } from '../@shared/shared.module';
import { QRCodeModule } from 'angularx-qrcode';
import { MyAppointmentsComponent } from './my-appointments/my-appointments.component';
import { MyDoctorComponent } from './my-doctor/my-doctor.component';
import { RenderDeclarationComponent } from './my-doctor/render-declaration/render-declaration.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MakeAppointmentComponent } from './appointment/make-appointment/make-appointment.component';
import { VisitsComponent } from './visits/visits.component';
import { VisitDetailsComponent } from './visits/visit-details/visit-details.component';
import { ImmunizationComponent } from './immunization/immunization.component';
import { ImmunizationDetailsComponent } from './immunization/immunization-details/immunization-details.component';
import { PrescriptionComponent } from './prescription/prescription.component';
import { PrescriptionDetailsComponent } from './prescription/prescription-details/prescription-details.component';
import { AnalyzesComponent } from './analyzes/analyzes.component';
import { AnalyzesDetailsComponent } from './analyzes/analyzes-details/analyzes-details.component';
import { InstrumentalResearchComponent } from './instrumental-research/instrumental-research.component';
import { InstrumentalResearchDetailsComponent } from './instrumental-research/instrumental-research-details/instrumental-research-details.component';
import { ReferralComponent } from './referral/referral.component';
import { ReferralDetailsComponent } from './referral/referral-details/referral-details.component';
import { TelemedicineComponent } from './telemedicine/telemedicine.component';
import { TelemedicineCardComponent } from './telemedicine/telemedicine-card/telemedicine-card.component';
import { TelemedicineDetailsComponent } from './telemedicine/telemedicine-details/telemedicine-details.component';
import { EventsComponent } from './events/events.component';
import { TelemedicinePatientCallComponent } from './telemedicine/telemedicine-patient-call/telemedicine-patient-call.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { PagesService } from './pages.service';
import { OrganizationOpenPageComponent } from './organization-open-page/organization-open-page.component';
import { MakeAppointmentServiceComponent } from './appointment/make-appointment-service/make-appointment-service.component';
import { CancelConfirmationPopupComponent } from './my-appointments/cancel-confirmation-popup/cancel-confirmation-popup.component';
import { ChatComponent } from './chat/chat.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { AccessGUIDPopupComponent } from './access-guid-popup/access-guid-popup.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ContactsComponent } from './chat/contacts/contacts.component';
import { VisitCardComponent } from './visits/visit-card/visit-card.component';
import { ImmunizationCardComponent } from './immunization/immunization-card/immunization-card.component';
import { PrescriptionCardComponent } from './prescription/prescription-card/prescription-card.component';
import { AnalyzeCardComponent } from './analyzes/analyze-card/analyze-card.component';
import { InstrumentalResearchCardComponent } from './instrumental-research/instrumental-research-card/instrumental-research-card.component';
import { ReferralCardComponent } from './referral/referral-card/referral-card.component';
import { PersonPopupComponent } from './my-profile/person-popup/person-popup.component';
import { ApiListService } from '@core/_services';
import { ClosedRenderDeclarationComponent } from './my-doctor/closed-render-declaration/closed-render-declaration.component';
import { AuthModule } from '../auth/auth.module';
import { DirectionMaskDirective } from './appointment/appointment-mask.directive';
import { SettingsComponent } from './settings/settings.component';
import { NotificationsSettingsComponent } from './settings/notifications/notifications.component';
import { NotificationItemComponent } from './notifications/notification-item/notification-item.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationListItemComponent } from './notifications/notification-list-item/notification-list-item.component';
import { SecurityPage } from './settings/security/security.page';
import { AutocompleteModalComponent } from '../@shared/components/autocomplete-modal/autocomplete-modal.component';

@NgModule({
    declarations: [
        VisitCardComponent,
        ImmunizationCardComponent,
        PrescriptionCardComponent,
        AnalyzeCardComponent,
        InstrumentalResearchCardComponent,
        ReferralCardComponent,
        ContactsComponent,
        AccessGUIDPopupComponent,
        ChatComponent,
        CancelConfirmationPopupComponent,
        MakeAppointmentServiceComponent,
        OrganizationOpenPageComponent,
        PageComponent,
        AppointmentComponent,
        MyDoctorComponent,
        RenderDeclarationComponent,
        MyAppointmentsComponent,
        MakeAppointmentComponent,
        AutocompleteModalComponent,
        DirectionMaskDirective,
        VisitsComponent,
        VisitDetailsComponent,
        ImmunizationComponent,
        ImmunizationDetailsComponent,
        PrescriptionComponent,
        PrescriptionDetailsComponent,
        AnalyzesComponent,
        AnalyzesDetailsComponent,
        InstrumentalResearchComponent,
        InstrumentalResearchDetailsComponent,
        ReferralComponent,
        ReferralDetailsComponent,
        TelemedicineComponent,
        TelemedicineCardComponent,
        TelemedicineDetailsComponent,
        EventsComponent,
        TelemedicinePatientCallComponent,
        MyProfileComponent,
        PersonPopupComponent,
        ClosedRenderDeclarationComponent,
        SettingsComponent,
        NotificationsSettingsComponent,
        SecurityPage,
        NotificationsComponent,
        NotificationsComponent,
        NotificationListItemComponent,
        NotificationItemComponent,
    ],
    imports: [
        PagesRoutingModule,
        MatSidenavModule,
        ThemeModule,
        SharedModule,
        QRCodeModule,
        MatTooltipModule,
        AngularEditorModule,
        MatCardModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        AuthModule,
    ],
    providers: [DatePipe, ApiListService],
    exports: [DirectionMaskDirective],
})
export class PagesModule {}
