import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '@core/_services';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Instances } from '@core/_interfaces';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    currentInstance: Instances | undefined | null;
    instances: Instances[] | undefined;
    @Output() resetFilter = new EventEmitter<void>();

    constructor(
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        private router: Router,
    ) {}

    ngOnInit() {}

    openLoginPopup() {
        const url = `/pages/appointment`;
        this.authenticationService
            .hearthBeat(url)
            .pipe(first())
            .subscribe(() => {
                console.log('subscribe navigate');
                this.router.navigate([url]).then();
            });
    }
}
