import {Injectable} from '@angular/core';
import {AutoCompleteData} from '@core/_interfaces';

export interface AutoComplete {
    id: number;
    name: string;
}

export interface RegionModel {
    Text: string;
}

export interface DistrictModel extends RegionModel {
    RegionID: number;
}

export declare type Location = {
    id: number;
    name: string;
};

export declare type Organization = {
    id: number;
    shortName: string;
};

export interface AutoCompleteDataOutputType {
    type: 'search' | 'reset';
    invalid: boolean;
    option: 'doctor' | 'lz' | null;
    req?: AutoCompleteData;
}

@Injectable()
export class SearchService {
    constructor() {}
}
