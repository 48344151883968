import {Directive, ElementRef, HostListener} from '@angular/core';

const DISABLE_TIME = 900;

@Directive({
    selector: '[appPreventDoubleClick]'
})
export class PreventDoubleClickDirective {
    constructor(private elementRef: ElementRef) {
    }

    @HostListener('click', ['$event'])
    clickEvent() {
        this.elementRef.nativeElement.setAttribute('disabled', 'true');
        setTimeout(() => this.elementRef.nativeElement.removeAttribute('disabled'), DISABLE_TIME);
    }
}
