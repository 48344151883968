import { Injectable, Injector } from '@angular/core';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PredefinedColors } from '@ionic/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
    constructor(private toastController: ToastController, private readonly injector: Injector) {}

    public openToast(
        message: string,
        color: PredefinedColors = 'success',
        cssClass: string = 'center bottom',
        duration: number = 5000,
        position: 'top' | 'middle' | 'bottom' = 'bottom',
    ): Observable<any> {
        return from(
            this.toastController.create({
                message: this.injector.get(TranslateService).instant(message),
                duration,
                color,
                cssClass,
                position,
            }),
        ).pipe(switchMap(toast => from(toast.present()).pipe(switchMap(() => from(toast.onDidDismiss())))));
    }
}
