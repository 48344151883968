import { Component, Input, OnInit } from '@angular/core';
import { NotificationModel } from '@core/_interfaces/notification.model';

@Component({
    selector: 'app-notification-item',
    templateUrl: './notification-item.component.html',
    styleUrls: ['./notification-item.component.scss'],
    providers: [],
})
export class NotificationItemComponent implements OnInit {
    @Input() public event: NotificationModel | null = null;

    public ngOnInit(): void {
        if (this.event && this.event.shortText) {
            this.event.shortText = this.event.shortText.replaceAll('\n', '<br>');
        }
    }
}
