import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
    ApiListService,
    AuthenticationService,
    passwordValidators,
    phoneMask,
    phoneValidators,
    variables,
} from '@core/_services';
import { Router } from '@angular/router';
import { RegistrationPopupFirstComponent } from '../registration/registration-popup-first/registration-popup-first.component';
import { TranslateService } from '@ngx-translate/core';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { combineLatest, of, Subject, switchMap, throwError } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { ReloadAction } from '@core/_effecs/reload/reload.actions';
import { ThemeService } from '../../@theme/theme.service';
import { catchError, debounceTime, filter, first, takeUntil, tap } from 'rxjs/operators';
import { Instances } from '@core/_interfaces';
import { filterNumbersFromString } from '@core/_utils/numbers-from-string';
import { MaskPipe } from 'ngx-mask';
import { NotificationService } from '@core/_services/notification.service';
import { FcmService } from '@core/_services/fcm.service';
import { StorageService } from '@core/_services/store.service';
import { PlatformService } from '@core/_services/platform.service';

@Component({
    selector: 'app-login-popup',
    templateUrl: './login-popup.component.html',
    styleUrls: ['./login-popup.component.scss'],
    providers: [AsyncPipe, MaskPipe],
})
export class LoginPopupComponent implements OnInit, OnDestroy {
    public form: FormGroup | undefined;
    public visiblePassword = false;
    public submitted = false;
    public phoneMask = phoneMask;
    public redirectAfterClose = false;
    public error = '';
    public loading$ = new Subject<boolean>();
    public mobilePreventClose = false;
    public afterRegistration = '';
    public prefix = '(0';

    private destroyed$: Subject<void> = new Subject<void>();

    constructor(
        private fb: FormBuilder,
        @Optional() public dialogRef: MatDialogRef<LoginPopupComponent>,
        private authenticationService: AuthenticationService,
        private storageService: StorageService,
        @Optional()
        @Inject(MAT_DIALOG_DATA)
        private data: {
            url: string;
            registerData: { callInRegisterLink: boolean; successfulAuth: boolean; login: string };
        },
        private router: Router,
        public dialog: MatDialog,
        private translate: TranslateService,
        private pl: PlatformService,
        private store: Store<{ page: string }>,
        private themeService: ThemeService,
        private apiListService: ApiListService,
        private mask: MaskPipe,
        private notificationService: NotificationService,
        private fcmService: FcmService,
        private cdr: ChangeDetectorRef,
    ) {}

    public ngOnInit() {
        this.initData();
    }
    public ngOnDestroy() {
        console.log('login destroy');
        this.destroyed$.next();
        this.destroyed$.complete();
        this.cdr.detectChanges();
    }

    public initData() {
        this.afterRegistration = '';
        this.mobilePreventClose =
            (this.pl.isIosPlatform$.value || this.pl.isAndroidPlatform$.value) && !this.pl.isMobileWebPlatform$.value;
        this.mobilePreventClose = this.themeService.getActiveTheme()?.name === 'jeremiah';
        this.storageService
            .get<string>(variables.login)
            .pipe(first(), takeUntil(this.destroyed$))
            .subscribe(storageLogin => {
                const login =
                    this.data?.registerData && this.data.registerData?.login
                        ? this.data.registerData.login
                        : storageLogin;

                this.form = this.fb.group({
                    login: [login ? this.prefix + this.mask.transform(login.slice(1), phoneMask) : '', phoneValidators],
                    password: ['', passwordValidators],
                });
            });
    }

    public async submit() {
        this.submitted = true;
        if (this.form?.valid) {
            this.loading$.next(true);
            const login = filterNumbersFromString(this.form.get('login')?.value);
            this.storageService.set(variables.login, login).pipe(first(), takeUntil(this.destroyed$)).subscribe();
            const loginPayload = {
                login,
                password: this.form.get('password')?.value,
            };
            combineLatest([
                this.storageService.remove(variables.currentUser),
                this.storageService.remove(variables.currentPerson),
            ])
                .pipe(
                    switchMap(() =>
                        this.authenticationService.login(loginPayload).pipe(
                            first(),
                            catchError(async err => {
                                this.loading$.next(false);
                                this.error = await this.authenticationService.displayError(err, this.translate);

                                return null;
                            }),
                            filter(user => !!user),
                            switchMap((user: any) => {
                                this.loading$.next(false);
                                return this.authenticationService.setUser(user);
                            }),
                            switchMap(() => {
                                return this.storageService.get(variables.currentInstance).pipe(
                                    switchMap(currentInstance => {
                                        if (!currentInstance) {
                                            return this.storageService.get<any[]>(variables.instance).pipe(
                                                switchMap(instances => {
                                                    return this.apiListService.getMyPersonsRequest().pipe(
                                                        debounceTime(2000),
                                                        switchMap(persons => {
                                                            const currentInstanceName = persons
                                                                ? persons[0]?.instances[0]
                                                                : null;
                                                            if (currentInstanceName) {
                                                                const currentInstance = instances.find(
                                                                    (instance: Instances) =>
                                                                        instance.name === currentInstanceName,
                                                                );
                                                                if (currentInstance) {
                                                                    return this.authenticationService.manageCurrentInstance(
                                                                        currentInstance,
                                                                    );
                                                                }
                                                            }
                                                            return of(null);
                                                        }),
                                                    );
                                                }),
                                            );
                                        }

                                        return of(null);
                                    }),
                                );
                            }),
                            takeUntil(this.destroyed$),
                        ),
                    ),
                )
                .subscribe(() => {
                    if (
                        (this.pl.isIosPlatform$.value || this.pl.isAndroidPlatform$.value) &&
                        !this.pl.isMobileWebPlatform$.value
                    ) {
                        this.fcmService.initPush(true);
                        this.fcmService.notificationToken$.pipe(first()).subscribe(token => {
                            console.log('token', token);
                            this.afterAuthAction();
                        });
                    } else {
                        this.afterAuthAction();
                    }
                });
        }
    }

    private afterAuthAction(): void {
        if (this.data?.url === '/') {
            this.data.url = '/pages';
        }
        console.log('manageLogout login popup this.data?.url', this.data?.url);
        console.log('manageLogout login popup this.router?.url', this.router.url);
        if (this.fcmService.notificationUrl$.value || this.data?.url) {
            console.log('manageLogout login popup go to this.data?.url 1', this.data?.url);
            this.router.navigate([this.fcmService.notificationUrl$.value || this.data?.url]).then(() => {
                this.fcmService.notificationUrl$.next('');
                if (this.dialogRef) {
                    console.log('manageLogout login popup closed 1');
                    this.dialogRef.close({ successfulAuth: true });
                    setTimeout(() => {
                        if (this.authenticationService.loginRef) {
                            console.log(
                                'timeout this.authenticationService.loginRef 1',
                                this.authenticationService.loginRef,
                            );
                            this.authenticationService.loginRef.close({ successfulAuth: true });
                            this.cdr.detectChanges();
                        }
                    }, 500);
                }
                this.store.dispatch(new ReloadAction(this.router.url));
            });
        } else {
            console.log('manageLogout login popup go to this.data?.url 2', variables.pages);
            this.router.navigate([`/${variables.pages}`]).then(() => {
                this.fcmService.notificationUrl$.next('');
                if (this.dialogRef) {
                    console.log('manageLogout login popup closed 2');
                    this.dialogRef.close({ successfulAuth: true });
                    setTimeout(() => {
                        this.cdr.detectChanges();
                        if (this.authenticationService.loginRef) {
                            console.log(
                                'timeout this.authenticationService.loginRef 2',
                                this.authenticationService.loginRef,
                            );
                            this.authenticationService.loginRef.close({ successfulAuth: true });
                            this.cdr.detectChanges();
                        }
                    }, 500);
                }
                this.store.dispatch(new ReloadAction(this.router.url));
            });
        }
    }

    resetPassword() {
        this.dialog.open(ResetPasswordComponent, {
            panelClass: 'no-padding-popup',
            width: '520px',
            maxWidth: 'calc(100% - 10px)',
            backdropClass: 'blur-bg',
            disableClose: true,
        });
    }

    reg() {
        this.dialog
            .open(RegistrationPopupFirstComponent, {
                panelClass: 'no-padding-popup',
                width: '420px',
                maxWidth: 'calc(100% - 10px)',
                backdropClass: 'blur-bg',
                disableClose: true,
            })
            .afterClosed()
            .subscribe(async data => {
                if (data) {
                    this.initData();
                    this.redirectAfterClose = true;
                    if (data.option === 'mis') {
                        this.afterRegistration = `${this.translate.instant(
                            'greetings-with-registration',
                        )} <br> ${this.translate.instant('please-wait-for-and-hour')}`;
                    } else {
                        this.afterRegistration = this.translate.instant('greetings-with-registration');
                    }
                    setTimeout(() => {
                        this.afterRegistration = '';
                    }, 10 * 1000);
                }
            });
    }

    public pasteLogin(event: any) {
        event.preventDefault();

        const paste = event.clipboardData.getData('text');
        let pastePhone = paste || '';
        if (pastePhone?.length) {
            pastePhone = pastePhone.replace(/[^0-9]/g, '');
            if (pastePhone.length > 9) {
                pastePhone = pastePhone.substring(pastePhone.length - 9);
            }
        }
        this.form?.controls.login.setValue(this.prefix + this.mask.transform(pastePhone, phoneMask));
    }
}
