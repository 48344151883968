import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrganizationAuthComponent } from './organization-auth/organization-auth.component';
import { MobileAuthGuard } from '@core/_helpers/mobile-auth.guard';
import { AuthEnteryComponent } from './auth-entery.component';

const routes: Routes = [
    {
        path: '',
        component: AuthEnteryComponent,
        canActivate: [MobileAuthGuard],
    },
    {
        path: 'auth/organization/:id',
        component: OrganizationAuthComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {}
