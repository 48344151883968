import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit {
    doctors = [
        {
            name: 'Левосевич Марина Григорівна',
            speciality: 'Гострий синусит верхньої щелепи',
            date: '30.12.2020',
            icon: '1',
        },
        {
            name: 'Левосевич Марина Григорівна',
            speciality: 'Гострий синусит верхньої щелепи',
            date: '30.12.2020',
            icon: '2',
        },
        {
            name: 'Левосевич Марина Григорівна',
            speciality: 'Гострий синусит верхньої щелепи',
            date: '30.12.2020',
            icon: '3',
        }
    ]

    constructor() {
    }

    ngOnInit() {
    }

}
