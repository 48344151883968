import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagesService } from '../../pages.service';
import { currentPage, IonicRefresherType, Register, MyConsultations } from '@core/_interfaces';
import { BehaviorSubject, Subscription, switchMap, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiListService } from '@core/_services';
import { catchError, first } from 'rxjs/operators';

@Component({
    selector: 'app-visit-details',
    templateUrl: './visit-details.component.html',
    styleUrls: ['./visit-details.component.scss'],
})
export class VisitDetailsComponent implements OnInit, OnDestroy {
    public data: Register<MyConsultations> | undefined;
    public id: number | undefined;
    public reload$: Subscription | undefined;
    public loading$ = new BehaviorSubject<boolean>(true);
    constructor(
        private route: ActivatedRoute,
        private pagesService: PagesService,
        private store: Store<{ page: string }>,
        private apiListService: ApiListService,
    ) {}

    public async ngOnInit() {
        this.id = this.route.snapshot.params.id;
        // this.reload$ = this.store.select(store => store.page)
        //     .subscribe((reload) => {
        //     });
        this.getData();
    }

    public ngOnDestroy() {
        this.reload$?.unsubscribe();
    }

    public doRefresh(event: IonicRefresherType) {
        this.getData(event);
    }

    public async getData(event?: IonicRefresherType) {
        if (!event) {
            this.loading$.next(true);
        }
        this.pagesService
            .getSharedPayload()
            .pipe(
                first(),
                switchMap(sharedPayload => {
                    return this.apiListService.getMyConsultations({
                        ...sharedPayload,
                        page: currentPage,
                        pageSize: 1,
                        id: this.id,
                    });
                }),
                catchError(err => {
                    if (event) {
                        event?.target?.complete();
                    } else {
                        this.loading$.next(false);
                    }

                    return throwError(err);
                }),
            )
            .subscribe((r: Register<MyConsultations>) => {
                this.pagesService
                    .addImages(r.data, 'specialistImageId')
                    .then((resp: Register<MyConsultations>['data']) => {
                        this.data = {
                            data: resp,
                            totalCount: r.totalCount,
                        };
                        console.log(this.data);
                        if (event) {
                            event?.target?.complete();
                        } else {
                            this.loading$.next(false);
                        }
                    });
            });
    }
}
