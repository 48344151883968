import { Component } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    providers: [],
})
export class SettingsComponent {
    public refresh$ = new Subject<any>();

    public doRefresh(event: any) {
        console.log('doRefresh app-settings');
        this.refresh$.next(event);
    }
}
