import {Directive, OnInit, OnDestroy, ElementRef} from '@angular/core';
import {ThemeService} from './theme.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Theme} from './symbols';

@Directive({
    selector: '[theme]'
})
export class ThemeDirective implements OnInit, OnDestroy {

    private destroy$ = new Subject<void>();

    constructor(
        private _elementRef: ElementRef,
        private _themeService: ThemeService
    ) {
    }

    ngOnInit() {
        const active = this._themeService.getActiveTheme();
        if (active) {
            this.updateTheme(active);
        }

        this._themeService.themeChange
            .pipe(takeUntil(this.destroy$))
            .subscribe((theme: Theme) => this.updateTheme(theme));
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    updateTheme(theme: Theme) {
        // project properties onto the element
        for (const key in theme.properties) {
            document.querySelector('body')?.style.setProperty(key, theme.properties[key]);
        }

        // remove old theme
        for (const name of this._themeService.theme) {
            document.querySelector('body')?.classList.remove(`${name}-theme`);
        }

        // alias element with theme name
        document.querySelector('body')?.classList.add(`${theme.name}-theme`);
    }

}
