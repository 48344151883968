import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-organization-auth',
    templateUrl: './organization-auth.component.html',
    styleUrls: ['./organization-auth.component.scss'],
})
export class OrganizationAuthComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
