import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services';
import { StorageService } from '@core/_services/store.service';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, debounceTime, map } from 'rxjs/operators';
import { UserInterface } from '@core/_interfaces';
import { PlatformService } from '@core/_services/platform.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private pl: PlatformService,
        private storageService: StorageService,
        private authenticationService: AuthenticationService,
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        console.log('canActivate run');
        return this.storageService.get<UserInterface>('currentUser').pipe(
            switchMap((val: UserInterface) => {
                console.log('canActivate currentUser', val);
                if (val) {
                    return of(true);
                } else if (!this.authenticationService.loginRef) {
                    console.log('canActivate return manageLogout map 1', this.authenticationService.loginRef);
                    return this.authenticationService.manageLogout((route as any)?._routerState?.url).pipe(
                        switchMap(({ successfulAuth }) => {
                            console.log('canActivate return manageLogout map 2', successfulAuth);
                            if (this.pl.isMobileWebPlatform$.value && !successfulAuth) {
                                this.router.navigate(['/']).then();
                            }
                            return of(successfulAuth);
                        }),
                        catchError(err => {
                            console.log('canActivate return manageLogout catchError', err);
                            if (this.pl.isMobileWebPlatform$.value) {
                                this.router.navigate(['/']).then();
                            }
                            return of(false);
                        }),
                    );
                } else {
                    console.log('canActivate return ', false);
                    if (this.pl.isMobileWebPlatform$.value) {
                        this.router.navigate(['/']).then();
                    }
                    return of(false);
                }
            }),
            catchError(err => {
                if (!this.authenticationService.loginRef) {
                    console.log('canActivate catchError manageLogout');
                    return this.authenticationService.manageLogout((route as any)?._routerState?.url).pipe(
                        map(({ successfulAuth }) => {
                            return successfulAuth as boolean;
                        }),
                    );
                }

                return of(false);
            }),
        );
    }
}
