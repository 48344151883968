import {Action, createReducer, on} from '@ngrx/store';
import {doctorHasBeenConnected, initConnection, patientHasBeenConnected} from './call.action';

export const initialState = '';

const _callReducer = createReducer(initialState,
    on(doctorHasBeenConnected, state => 'doctorHasBeenConnected'),
    on(patientHasBeenConnected, state => 'patientHasBeenConnected'),
    on(initConnection, state => 'initConnection')
);

export function callReducer(state: string | undefined, action: Action) {
    return _callReducer(state, action);
}
