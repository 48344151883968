import {Action} from '@ngrx/store';

export enum PagesActionTypes {
    RELOAD = '[RELOAD] Reload Page'
}

export class ReloadAction implements Action {
    readonly type = PagesActionTypes.RELOAD;

    constructor(public payload: any) {
    }
}

export type PageActions = ReloadAction;
