export enum FileMimeTypesEnum {
    json = <any>'application/json',
    pdf = <any>'application/pdf',
    zip = <any>'application/zip',
    xls = <any>'application/vnd.ms-excel',
    xlsx = <any>'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    odt = <any>'application/vnd.oasis.opendocument.text',
    doc = <any>'application/msword',
    docx = <any>'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ppt = <any>'application/vnd.ms-powerpoint',
    pptx = <any>'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    xml = <any>'application/xml',
    png = <any>'image/png',
    jpeg = <any>'image/jpeg',
    jpg = <any>'image/jpg',
    gif = <any>'image/gif',
    bmp = <any>'image/bmp',
    txt = <any>'text/plain',
    csv = <any>'text/csv',
    html = <any>'text/html',
}
