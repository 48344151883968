import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-telemedicine-patient-call',
    templateUrl: './telemedicine-patient-call.component.html',
    styleUrls: ['./telemedicine-patient-call.component.scss'],
    providers: [],
})
export class TelemedicinePatientCallComponent implements OnInit {
    showFrame = false;
    id: any;

    constructor(private route: ActivatedRoute) {}

    ionViewWillEnter() {}

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
    }
}
