import { Component } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { NotificationService } from '@core/_services/notification.service';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { NotificationModel } from '@core/_interfaces/notification.model';
import { variables } from '@core/_services';
import { KeyValue } from '@angular/common';
import { PlatformService } from '@core/_services/platform.service';
import { StorageService } from '@core/_services/store.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    providers: [],
})
export class NotificationsComponent {
    public eventDays = new Map<string, { date: string; events: NotificationModel[] }>();
    public isLoading$ = new Subject<boolean | null>();
    public isIos = this.platformService.isIosPlatform$.value;
    public isAndroid = this.platformService.isAndroidPlatform$.value;
    public isMobileView = this.platformService.isMobileWebPlatform$.value;
    public notificationToken = '';
    private destroyed$ = new Subject<void>();

    constructor(
        private notificationService: NotificationService,
        private storageService: StorageService,
        private platformService: PlatformService,
    ) {}

    public async ngOnInit() {
        this.storageService
            .get<string>(variables.notificationToken)
            .pipe(first())
            .subscribe(notificationToken => {
                this.notificationToken = notificationToken;
                if (this.notificationToken) {
                    this.getHistoryData(this.notificationToken);
                }
            });

        this.platformService.resumePlatform$.pipe(takeUntil(this.destroyed$)).subscribe(async () => {
            this.storageService
                .get<string>(variables.notificationToken)
                .pipe(first())
                .subscribe(notificationToken => {
                    this.notificationToken = notificationToken;
                });
        });
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    private getHistoryData(notificationToken: string, event?: any): void {
        this.notificationService
            .getNotificationsHistory(notificationToken)
            .pipe(
                first(),
                catchError(err => {
                    if (event) {
                        event.target.complete();
                    } else {
                        this.isLoading$.next(false);
                    }

                    return throwError(err);
                }),
                takeUntil(this.destroyed$),
            )
            .subscribe((notifications: NotificationModel[]) => {
                this.eventDays.clear();
                notifications.forEach((item: NotificationModel) => {
                    const itemKey = item.sendDate ? item.sendDate.split('T')[0] : '';
                    if (!!itemKey && this.eventDays.has(itemKey)) {
                        const d = this.eventDays.get(itemKey);
                        if (d) {
                            d.events.push(item);
                        }
                    } else if (itemKey) {
                        this.eventDays.set(itemKey, { date: item.sendDate || '', events: [{ ...item }] });
                    }
                });
                if (event) {
                    event.target.complete();
                    this.isLoading$.next(false);
                } else {
                    this.isLoading$.next(false);
                }
            });
    }

    public compareMessage(a: KeyValue<any, any>, b: KeyValue<any, any>) {
        return a.key > b.key ? -1 : b.key > a.key ? 1 : 0;
    }

    public doRefresh(event: any) {
        if (this.notificationToken) {
            this.getHistoryData(this.notificationToken, event);
        } else {
            event.target.complete();
        }
    }
}
