import {Theme} from './symbols'

export const jeremiahTheme: Theme = {
    name: 'jeremiah',
    properties: {
        '--orange': '#FE9100',
        '--dark-blue': '#324056',
        '--dark-gray': '#636363',
        '--dark-black': '#000000',
        '--light-white': '#ebeff2',
        '--light-blue': '#8597a5',
        '--blue': '#72b52e',
        '--ocean': '#92ea3e',
        '--dark-orange': '#f1991b',
        '--l-white': '#c4c4c4',
        '--m-black': '#1d1d1d',
        '--transparent-gray': '#838383',
        '--l-orange': '#fa9100',
        '--error': '#f44336',
        '--dodger-blue': '#de8c24',
        '--light-grey': '#fafafa',
        '--white': '#ffffff',
        '--deep-blue': '#92ea3e',
        '--l-blue': '#d5e0e6',
        '--border-blue': '#dce5ef',
        '--border-grey': '#cecece',
        '--d-blue': '#72b52e',
        '--bt-blue': '#f9fafc',
        '--blue-green': '#92ea3e',
        '--btn-positive-blue': '#92ea3e',
        '--gainsboro-color': '#dcdcdc',
        '--btn-background': '#72b52e',
        '--alice-blue': '#ebeff2',
        '--snow-color': '#f9f9f9',
        '--gray': '#ccc',
        '--bright-gray': '#eaeaea',
        '--silver': '#bababa',
        '--btn-hover-color': '#72b52e',
        '--l-grey': '#d5d5d5',
        '--lynx-white': '#f7f7f7',
        '--grey': '#848484',
        '--l-black': '#19191d',
        '--strong-orange': '#D88211',
        '--zambezi-grey': '#585858',
        '--message-blue': '#115b91',
        '--bermuda-blue': '#7cc7d9',
        '--list-blue': 'rgba(114,181,46,0.92)',
        '--profession-blue': '#b9f1ff',
        '--white-smoke': '#f6f6f6',
        '--roman-silver': '#838b90',
        '--malachite': '#14d941',
    }
}
