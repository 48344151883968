import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Platform } from '@ionic/angular';
import { AppConfig } from './index';
import { HttpClient } from '@angular/common/http';
import { PlatformService } from '@core/_services/platform.service';

@Injectable()
export class Download {
    constructor(private pl: PlatformService, private http: HttpClient) {}

    generatePdf(name: string, path: string) {
        // this.http.post(AppConfig.pdfGeneratorURL, {
        //     url: `${AppConfig.webURL}/#/pages/${path}/1?pdf=true`
        // }).subscribe((data: any) => {
        //     this.downloadFile(data.body, `${name}.pdf`);
        // });
    }

    downloadFile(base64: string, name: string) {
        const type = { type: 'application/pdf' };
        this.base64ToBlob(base64, type.type).then((blob: any) => {
            if (
                (this.pl.isAndroidPlatform$.value || this.pl.isIosPlatform$.value) &&
                !this.pl.isMobileWebPlatform$.value
            ) {
            } else {
                saveAs(blob, name);
            }
        });
    }

    base64ToBlob(b64Data: string, contentType = '', sliceSize = 512) {
        return new Promise(resolve => {
            b64Data = b64Data.replace(/\s/g, '');
            const byteCharacters = atob(b64Data);
            const byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            resolve(new Blob(byteArrays, { type: contentType }));
        });
    }
}
