import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {icons} from '../star.service';
import {RatingInterface} from "@core/_services";

export interface RatingPopupInterface {
    star: number;
    ratingStrings: RatingInterface;
}

@Component({
    selector: 'app-rating-popup',
    templateUrl: './rating-popup.component.html',
    styleUrls: ['./rating-popup.component.scss'],
})
export class RatingPopupComponent implements OnInit {
    icons = icons;
    hoverPositive = false;
    hoverNegative = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<RatingPopupInterface>
    ) {
    }

    ngOnInit() {
    }

    ratingOutput(count: number) {
        this.data = count;
    }

    close() {
        this.dialogRef.close(this.data);
    }

}
