import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagesService } from '../../pages.service';
import { currentPage, Analyze, IonicRefresherType, Register } from '@core/_interfaces';
import { BehaviorSubject, Subject, Subscription, switchMap, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiListService } from '@core/_services';
import { catchError, filter, first, takeUntil } from 'rxjs/operators';
import { ReloadAction } from '@core/_effecs/reload/reload.actions';

@Component({
    selector: 'app-analyzes-details',
    templateUrl: './analyzes-details.component.html',
    styleUrls: ['./analyzes-details.component.scss'],
})
export class AnalyzesDetailsComponent implements OnInit, OnDestroy {
    @Input() data: Register<Analyze> | undefined;
    public id: number | undefined;
    public reload$: Subscription | undefined;
    public loading$ = new BehaviorSubject<boolean>(false);

    private destroyed$ = new Subject<void>();
    constructor(
        private route: ActivatedRoute,
        private pagesService: PagesService,
        private store: Store<{ page: string }>,
        private apiListService: ApiListService,
        private cdr: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        this.id = +this.route.snapshot.params.id;
        this.route.params.pipe(takeUntil(this.destroyed$)).subscribe(data => {
            if (data?.id) {
                this.id = +this.route.snapshot.params.id;
                this.getData().then();
            }
        });

        this.store
            .select(store => store.page)
            .pipe(
                filter(
                    (reload: any) =>
                        !!reload &&
                        reload?.payload &&
                        typeof reload?.payload === 'string' &&
                        reload?.payload.includes('/pages/analyzes/'),
                ),
            )
            .subscribe(reload => {
                console.warn('reload store', reload);
                this.id = +this.route.snapshot.params.id;
                this.getData().then();
            });
        this.getData().then();
    }

    public doRefresh(event: IonicRefresherType): void {
        this.getData(event).then();
    }

    public async getData(event?: IonicRefresherType): Promise<void> {
        if (!event) {
            this.loading$.next(true);
        }

        this.pagesService
            .getSharedPayload()
            .pipe(
                first(),
                switchMap(sharedPayload => {
                    return this.apiListService.getMyAnalysis({ ...sharedPayload, id: this.id, page: 1, pageSize: 1 });
                }),
                catchError(err => {
                    if (event) {
                        event?.target?.complete();
                    } else {
                        this.loading$.next(false);
                    }

                    return throwError(err);
                }),
                takeUntil(this.destroyed$),
            )
            .subscribe((data: Register<Analyze>) => {
                this.data = data;
                if (event) {
                    event?.target?.complete();
                } else {
                    this.loading$.next(false);
                }

                this.cdr.detectChanges();
            });
    }

    public ngOnDestroy(): void {
        this.reload$?.unsubscribe();
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
