import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { AppConfig, AuthenticationService, year } from '@core/_services';
import { NavigationEnd, Router } from '@angular/router';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { resetPerson } from '@core/_store/person/person.action';
import { MenuController } from '@ionic/angular';
import { ShowingPagesInterface } from '@core/_interfaces';
import { Location } from '@angular/common';
import { navLinks } from '@core/_utils/nav';
import { StorageService } from '@core/_services/store.service';
import { PlatformService } from '@core/_services/platform.service';

export interface NavInterface {
    icon?: string;
    name: string;
    link: string;
    linkTab: string[];
    id: keyof ShowingPagesInterface;
    notification?: number;
    active?: boolean;
}

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit, AfterViewInit, OnDestroy {
    public mouseEnterMenu = false;
    public mouseEnterContainer = false;
    @ViewChild('drawer') public drawer: MatDrawer | undefined;
    public showFullNav: boolean | undefined;
    public year = year;
    public nav$: Observable<NavInterface[] | void[]> = this.authenticationService.instanceConfig$.pipe(
        map(config => {
            const showPages = !config || !config.showPages ? this.defaultShowingPages : config.showPages;
            showPages.notifications =
                (this.platform.isIosPlatform$.value || this.platform.isAndroidPlatform$.value) &&
                !this.platform.isMobileWebPlatform$.value;
            console.log(
                'NAVIGATION RETURN',
                config,
                this.nav.filter(item => showPages[item.id as keyof ShowingPagesInterface] as boolean),
            );
            return this.nav.filter(item => showPages[item.id as keyof ShowingPagesInterface] as boolean);
        }),
    );
    public isMobileSideNav = false;

    private nav: NavInterface[] = navLinks;
    private openFullWidthVideo$: Observable<boolean> = this.store.pipe(select('fullScreen'));
    private openFullVideo$: Subscription | undefined;
    private changePerson$: Observable<string> | undefined;
    private subscriptionToChangePerson$: Subscription | undefined;
    private mobileMenuState = false;
    private defaultShowingPages = AppConfig.showingPages;
    private destroyed$ = new Subject<void>();

    constructor(
        public router: Router,
        public platform: PlatformService,
        public authenticationService: AuthenticationService,
        private http: HttpClient,
        private store: Store<{ fullScreen: boolean; changePerson: string; instance: string }>,
        private menu: MenuController,
        private location: Location,
        private storageService: StorageService,
    ) {}

    public async ngOnInit(): Promise<void> {
        this.isMobileSideNav = window.innerWidth < 992;
        if (this.isMobileSideNav) {
            this.menu.enable(true, 'mobileMenu');
        }

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            if (this.isMobileSideNav) {
                this.menu.close('mobileMenu');
                this.mobileMenuState = false;
            }
        });
        this.storageService
            .get<boolean>('showFullNav')
            .pipe(first())
            .subscribe(showFullNav => {
                this.showFullNav = showFullNav;
            });
        this.openFullVideo$ = this.openFullWidthVideo$.subscribe((state: boolean) => {
            if (state) {
                this.drawer?.close();
            } else {
                this.drawer?.open();
            }
        });
        this.subscriptionToChangePerson$ = this.changePerson$?.subscribe((state: string) => {
            if (state) {
                this.redirectTo(this.router.url);
            }
        });
    }

    public toggleMobileMenu(): void {
        this.menu.toggle('mobileMenu');
    }

    public goBack(): void {
        this.location.back();
    }

    public ngOnDestroy(): void {
        this.openFullVideo$?.unsubscribe();
        this.subscriptionToChangePerson$?.unsubscribe();
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public goForward(): void {
        this.location.forward();
    }

    private redirectTo(uri: string): void {
        this.store.dispatch(resetPerson());
        window.location.reload();
    }

    public ngAfterViewInit(): void {
        setTimeout(() => this.drawer?.toggle());
    }

    public toggleSideBar(): void {
        this.showFullNav = !this.showFullNav;
        this.storageService.set('showFullNav', this.showFullNav).pipe(first(), takeUntil(this.destroyed$)).subscribe();
    }

    public enterContainer(state: boolean): void {
        this.mouseEnterContainer = state;
    }

    public enterMenu(state: boolean): void {
        this.mouseEnterMenu = state;
    }

    public menuState(state: boolean): void {
        this.mobileMenuState = state;
    }
}
